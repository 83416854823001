import React from 'react'

function FormSwitch({onChange, status, label, value, disabled}) {
  return (
    <div className="custom-control custom-switch">
        <input 
            type="checkbox" 
            className="custom-control-input" 
            onChange={onChange} 
            disabled={disabled}
            checked={status}
        />
        <label className="custom-control-label" htmlFor="customSwitches">{label}</label>
    </div>
  )
}

export default FormSwitch