import {React, useState, useRef, useCallback, useEffect}  from 'react';
import { IndustryCatalog, IndustryYears } from './IndustryCatalog';
import axios from 'axios';
import config from '../../../config';

function IndustryFilters(props) {
    const [name, setName] = useState('');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    
    const type = useRef('');
    const year = useRef('');
    const country = useRef('');
    const state = useRef('');
    const city = useRef('');

    const selectForm = useRef(null);    
    const queryObject = useRef({});

    /**Cada que se escribe en el texto de búsqueda se invoca esta función */
    function handleChange(e) {        
        setName(e.target.value);
    }
    
    /**Cuando se elige una categoría se invoca esta función */
    function handleType(e) {
        type.current = e.target.value;
        handleQuery();
    }
    
    /**Cuando se elige un año se invoca esta función */
    function handleYear(e) {
        year.current = e.target.value;
        handleQuery();
    }

    /**Cuando se hace un submit */
    const handleSubmit = (e) => {
        e.preventDefault();
        handleQuery();
    }

    /**Cuando se elige un país se invoca esta función */
    async function updateStates (event){
        if(event.target.value === 'cualquier'){
            country.current = '';
            state.current = '';
            city.current = '';
            delete queryObject.current.country;
            delete queryObject.current.state;
            delete queryObject.current.city;
        }
        else{
            country.current = event.target.value;
            delete queryObject.current.state;
            state.current = '';
            delete queryObject.current.city;
            city.current = '';
        }
        
        handleQuery();

        const url = `${process.env.REACT_APP_API_LOCATION}/location/getStates`;
        const response = await axios.post(url,{
            "country": event.target.value
        });       
        setStates(response.data);
        setCities([]);
        
    };

    /**Cuando se elige un estado se invoca esta función */
    async function handleState(e) {
        if(e.target.value === 'cualquier'){            
            state.current = '';
            city.current = '';
            delete queryObject.current.state;
            delete queryObject.current.city;
            
        }
        else{
            state.current = e.target.value;
            delete queryObject.current.city;
            city.current = '';
        }
                
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCities`;
        const response = await axios.post(url,{
            "state": e.target.value
        });
        setCities(response.data);
        handleQuery();
    }

    /**Cuando se elige una ciudad se invoca esta función */
    async function handleCity(e) {
        if(e.target.value === 'cualquier'){
            city.current = '';
            delete queryObject.current.city;
        }
        else{
            city.current = e.target.value;
        }
        
        handleQuery();  
    }

    function handleQuery(){
        
        if(name!==''){
            queryObject.current.text = name;
        }
        else{   
            delete queryObject.current.text;
        }

        if(type.current!=='' && type.current!=='cualquier'){
            queryObject.current.company_type = type.current;
        }
        else{
            delete queryObject.current.company_type;
        }

        if(year.current!=='' && year.current!=='cualquier'){
            queryObject.current.company_year = year.current;
        }
        else{
            delete queryObject.current.company_year;
        }

        if(country.current!==''){
            queryObject.current.country = country.current;
        }
        else{
            delete queryObject.current.country;
            delete queryObject.current.state;
            delete queryObject.current.city;
        }

        if(state.current!==''){
            queryObject.current.state = state.current;
        }
        else{
            delete queryObject.current.state;            
            delete queryObject.current.city;            
        }

        if(city.current!==''){
            queryObject.current.city = city.current;
        }
        else{  
            delete queryObject.current.city;
        }

        props.onSubmitCallback(queryObject.current);
    }
    
    const loadCountries = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCountries`;
        const response = await axios.post(url);
        setCountries(response.data);
    }, []);

    useEffect(() => {
        loadCountries();
    }, [loadCountries]);

    return (
    <div>
        <div className="sticky-top background-verylightgray rounded  p-3">
            <form ref={selectForm} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col"><span className="mdi mdi-tune"></span>&nbsp;Filtrar búsqueda<span className="mdi mdi-plus btn-fade" id="btn-fade"></span><span className="mdi mdi-minus btn-fade" id="btn-hide"></span></div>
            </div>
		    <div className="sec-fade" id="sec-fade">
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col agreements"><strong>Nombre</strong></div>
                </div>
                <div className="row">
                    <div className="col agreements">
                        <input 
                            type="text" 
                            className="form-control" 
                            name="text" 
                            placeholder="Título, descripción, palabras clave"
                            value={name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="oc" className="agreements"><strong>Tipo de empresa</strong> </label>
                        <select 
                            name="type" 
                            className="form-control agreements" 
                            onChange={(e) => handleType(e)}
                        >
                            <option value="cualquier"> Cualquiera </option>
                            {IndustryCatalog.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col agreements"><strong>País</strong></div>
                </div>
                <div className="row">
                    <div className="col">
                        <select 
                            name="country" 
                            className="form-control agreements" 
                            onChange={(e) => updateStates(e)}
                        >
                            <option value="cualquier"> Cualquier país </option>
                            {countries.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col agreements"><strong>Estado</strong></div>
                </div>
                <div className="row">
                    <div className="col">
                        <select 
                            name="state" 
                            className="form-control agreements"
                            onChange={(e) => handleState(e)}
                        >
                            <option value="cualquier"> Cualquier estado </option>
                            {states.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col agreements"><strong>Ciudad</strong></div>
                </div>
                <div className="row">
                    <div className="col">
                        <select 
                            name="city" 
                            className="form-control agreements"
                            onChange={(e) => handleCity(e)}
                        >
                            <option value="cualquier"> Cualquier ciudad </option>
                            {cities.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="oc" className="agreements"><strong>Año de fundación</strong> </label>
                        <select 
                            name="year" 
                            className="form-control agreements" 
                            onChange={(e) => handleYear(e)}
                        >
                            <option value="cualquier"> Cualquiera </option>
                            {IndustryYears.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>                
            </div>
            </form>
          
        </div>
    </div>
  );
}

export default IndustryFilters;