import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Hand } from "../../static/images/hand.svg";
//import { EditText } from 'react-edit-text';
import { UserContext } from "../../context";
import jwtDecode from "jwt-decode";
import cn from "classnames";
import axios from "axios";
import "./styles.scss";
import 'react-edit-text/dist/index.css';
import formatMetadata from "../../handlers/formatProject";
import ModalForMetadata from "./modals/ModalForMetadata";
import ModalForSocialMedia from "./modals/ModalForSocialMedia";
import {formatearURL} from "../common/FormatFunctions";


const particleList = Array.from(Array(10));

function ProjectMeta() {
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate();
    const user = useContext(UserContext);

    const [currentUser] = useState();
    const [liked, setLiked] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [likes , setLikes] = useState(0);
    const [views, setViews] = useState(0);

    //const navigate = useNavigate();
    //let decoded = null;

    const location = useLocation();
    let projectId = null;

    if(location) {
        projectId = location.pathname.slice(location.pathname.lastIndexOf("/")+1 , location.pathname.length) ;
    }

    //const [project, setProject] = useState(null);
    const [projectData, setProjectData] = useState(null);
    //const [authorData, setAuthorData] = useState(null);

    function getMetadata() {
        return formatMetadata(projectData);
    }

    async function handleLikes (action){
        console.log(action);
        const userId = jwtDecode(localStorage.getItem("token"))._id;
        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/project/${action}Project`;
            axios.put(url, {
                userId: userId,
                projectId: projectData._id
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });

        } catch (error) {
            console.log(error); 
        }
    }    

    async function likeUnlike (e) {
        e.preventDefault();
        setClicked(true);
        if(liked){
            handleLikes("unlike");
        }
        else{
            handleLikes("like");
        }
        getLikes();
        setLiked(!liked);
    }

    async function getLikes(){
        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/project/getViewsAndLikes/${projectData._id}`;
            const {data: res} = await axios.get(url);
            setLikes(res.likes);
        } catch (error) {
            console.log(error);
        }
    }
/*
    const handleSaveName = ({ name, value, previousValue }) => {
        //alert(name + ' saved as: ' + value + ' (prev: ' + previousValue + ')');
        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/project/updateName`;
            axios.put(url, {
                projectId: projectData._id,
                project_title: value
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });
        } catch (error) {
            console.log(error);
        }            
    };*/

    

      useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if(projectId){
            axios.get(`${process.env.REACT_APP_API_LOCATION}/project/getProjectMeta/${projectId}`,{
                cancelToken: cancelToken.token
            })
            .then((response) => {
                setProjectData(response.data);
                setViews(response.data.views);
                setLikes(response.data.likes);
                if(user && user.likesProject && user.likesProject.includes(projectId)){
                    setLiked(true);
                }
                else{
                    setLiked(false);
                }
            })
            .catch((error) => {
                if(error.response.request.status===404){
                    navigate('/notfound');
                }
            });
        }

        return () => {
            cancelToken.cancel();
        }

      }, [showBoundary, projectId, navigate, user]);

   
    if (!projectData) {
        return <div>Cargando...</div>;
    }

    // Obtener las propiedades del objeto usando el operador de encadenamiento opcional
    
    const photo = projectData.photo ?? '';
    const authorName = projectData.author ?? '';
    const title = projectData.project_title ?? '';    
    const facebook = projectData.project_facebook_page ?? '#';
    const instagram = projectData.project_instagram_page ?? '#';
    const twitter = projectData.project_twitter_page ?? '#';
    const youtube = projectData.project_youtube_page ?? '#';
    const vimeo = projectData.project_vimeo_page ?? '#';
    const imdb = projectData.project_imdb_page ?? '#';
    
    let status = projectData.project_status ?? 'N/A';

    if(projectData.project_status !== "Terminado"){
        status = 'En ' + projectData.project_status;
    }

    let web = projectData.project_web_page ?? '';

    if(web !== ''){
        web = formatearURL(web);
    }

    return (
        <div>
            <div className="container">
                <div className="spacer60"></div>
                <div className="row">
                    
                    <div className="col-lg-8 col-md-8 col-12">
                        <div className="row">
                            <div className="smallSizeContainer">
                                <a href={"/crew/"+projectData.userId} className="text-black">
                                    <div className="image-container-100">
                                        <img src={photo} border="0" className="image" alt="Profile"/>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-10 col-12">
                                <div className="row">
                                    <div className="col h3-title">
                                        {title}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                    Por: <a href={"/crew/"+projectData.userId} className="text-black">{authorName}</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 uppercase">
                                        {getMetadata()}
                                    </div>
                                    
                                   
                                </div>
                                <div className="row">
                                    <div className="col">
                                    {status}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                    <a href={web} target="_blank" rel="noopener noreferrer" className="text-black"><strong className="text-black"><small>{web}</small></strong></a>
                                    </div>
                                </div>
                                {/**Aqui se validaba que el usuario tenga sesión
                                 * y que no sea el mismo que el autor del proyecto
                                */}
                                {currentUser && currentUser._id !== projectData.userId &&
                                <div className="row">
                                    <div className="col">
                                        {/**Inicia componente Botón */}
                                        <div>
                                            <button
                                                type="button"
                                                onClick={likeUnlike}
                                                onAnimationEnd={() => setClicked(false)}
                                                className={cn("like-button-wrapper", {
                                                liked,
                                                clicked,
                                                })}      
                                            >
                                                
                                                {liked && (
                                                <div className="particles">
                                                    {particleList.map((_, index) => (
                                                    <div
                                                        key={index}
                                                        className="particle-rotate"
                                                        style={{
                                                        transform: `rotate(${
                                                            (360 / particleList.length) * index + 1
                                                        }deg)`,
                                                        }}
                                                    >
                                                        <div className="particle-tick" />
                                                    </div>
                                                    ))}
                                                </div>
                                                )}
                                                
                                                <div className="like-button">
                                                <Hand />
                                                <span>ME GUSTA</span>
                                                <span className={cn("suffix", { liked })}>BA</span>
                                                </div>
                                            </button>
                                        </div>
                                        {/**Termina componente Botón */}
                                    </div>
                                </div>
                                }
                                {/**Fin de validación */}
                            
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 border-left">
                        <div className="row">
                            <div className="col-md-12"><span className="mdi mdi-eye"></span>&nbsp;<span className="agreements">Vistas {views}</span></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12"><span className="mdi mdi-thumb-up"></span>&nbsp;<span className="agreements">Valoraciones {likes}</span></div>        
                        </div>
                    </div>
                    <div className="col-lg-2 col-12 border-left">
                        <div className="spacer30 d-lg-none"></div>
                        <div className="row">
                            <div className="col-lg-4 col-2">
                            {facebook && 
                                <a href={facebook} target="_blank" rel="noopener noreferrer"> <span className="mdi mdi-facebook text-black"></span></a>
                            }
                            {!facebook &&
                                <span className="mdi mdi-facebook text-light"></span>
                            }
                            </div>
                            <div className="col-lg-4 col-2">
                            {instagram &&
                                <a href={instagram} target="_blank" rel="noopener noreferrer"> <span className="mdi mdi-instagram text-black"></span></a>
                            }
                            {!instagram &&
                                <span className="mdi mdi-instagram text-light"></span>
                            }
                            </div>
                            <div className="col-lg-4 col-2">
                            {twitter &&
                                <a href={twitter} target="_blank" rel="noopener noreferrer"> <span className="mdi mdi-twitter text-black"></span></a>
                            }
                            {!twitter &&
                                <span className="mdi mdi-twitter text-light"></span>
                            }
                            </div>
                    
                            <div className="col-lg-4 col-2">
                            {youtube &&
                                <a href={youtube} target="_blank" rel="noopener noreferrer"> <span className="mdi mdi-youtube text-black"></span></a>
                            }
                            {!youtube &&
                                <span className="mdi mdi-youtube text-light"></span>
                            }
                            </div>
                            <div className="col-lg-4 col-2">
                            {vimeo &&
                                <a href={vimeo} target="_blank" rel="noopener noreferrer"> <img src="/vimeo.png" className="icon-size" alt="vimeo icon"/></a>
                            }
                            {!vimeo &&
                                <img src="/vimeo-gray.png" className="icon-size" alt="vimeo icon"/>
                            }
                            </div>
                            <div className="col-lg-4 col-2">
                            {imdb &&
                                <a href={imdb} target="_blank" rel="noopener noreferrer"> <img src="/imdb.png" className="icon-size" alt="imdb icon"/> </a>
                            }
                            {!imdb &&
                                <img src="/imdb-gray.png" className="icon-size" alt="imdb icon"/>
                            }
                            </div>
                        </div>
                        <div className="spacer10"></div>
                        <div className="row">
                            <div className="col-md-9 col-sm-12 text-center">
                                <a href={web} target="_blank" rel="noopener noreferrer" className="text-black"><strong className="text-black"><small>{projectData.project_web_page}</small></strong></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer40"></div>
                
            <ModalForMetadata projectId={projectData._id} project={projectData}/>            
            <ModalForSocialMedia projectId={projectData._id} project={projectData}/>

        </div>
    )
}

export default ProjectMeta;