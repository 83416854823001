import React from 'react';

function CastPersonalInfo(props) {

    const { person } = props

    if(!person){
        return <div>Cargando ... </div>
    }

    let sex = "";
    if(person && person.cast && person.cast.sex){
        sex = person.cast.sex;
    }

    let ageRange = "";
    if(person && person.cast && person.cast.ageRange){
        ageRange = person.cast.ageRange;
    }

    let height = "";
    if(person && person.cast && person.cast.height){
        height = person.cast.height;
    }

    let physical = "";
    if(person && person.cast && person.cast.physical){
        physical = person.cast.physical;
    }

    let ethnicity = "";
    if(person && person.cast && person.cast.ethnicity){
        ethnicity = person.cast.ethnicity;
    }

    let eyes = "";
    if(person && person.cast && person.cast.eyes){
        eyes = person.cast.eyes;
    }

    let hair = "";
    if(person && person.cast && person.cast.hair){
        hair = person.cast.hair;
    }

    let hairType = "";
    if(person && person.cast && person.cast.hairType){
        hairType = person.cast.hairType;
    }

    let beard = "";
    if(person && person.cast && person.cast.beard){
        beard = person.cast.beard;
    }

    let languages = "";
    if(person && person.languages){
        languages = person.languages;
    }

    let peculiarities = "";
    if(person && person.cast && person.cast.peculiarities){
        peculiarities = person.cast.peculiarities;
    }

    let skills = "";
    if(person && person.cast && person.cast.skills){
        skills = person.cast.skills;
    }
    


    return (
        <div>
            <div className="spacer40"></div>
            <div className="row">
                <div className="col ">
                    <strong className="uppercase">Información personal</strong>
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {sex &&
                    <p className="about padding-bottom-5"><strong>Sexo:</strong> {sex}</p>
                    }
                    {ageRange &&
                    <p className="about padding-bottom-5"><strong>Rango de edad:</strong> {ageRange}</p>
                    }
                    {height &&
                    <p className="about padding-bottom-5"><strong>Altura:</strong> {height}</p>
                    }
                    {physical &&
                    <p className="about padding-bottom-5"><strong>Complexión:</strong> {physical}</p>
                    }
                    {ethnicity &&
                    <p className="about padding-bottom-5"><strong>Etnicidad:</strong> {ethnicity}</p>
                    }
                    {eyes &&
                    <p className="about padding-bottom-5"><strong>Ojos:</strong> {eyes}</p>
                    }
                    {hair &&
                    <p className="about padding-bottom-5"><strong>Cabello:</strong> {hair}</p>
                    }
                    {hairType &&
                    <p className="about padding-bottom-5"><strong>Tipo de cabello:</strong> {hairType}</p>
                    }
                    {beard &&
                    <p className="about padding-bottom-5"><strong>Barba o bigote:</strong> {beard}</p>
                    }
                    {languages &&
                    <p className="about padding-bottom-5"><strong>Idiomas:</strong> {languages}</p>
                    }
                    {peculiarities &&
                    <p className="about padding-bottom-5"><strong>Señas particulares:</strong> {peculiarities}</p>
                    }
                    {!peculiarities &&
                    <p className="about padding-bottom-5"><strong>Señas particulares:</strong> Desconocidas</p>
                    }
                    {skills &&
                    <p className="about padding-bottom-5"><strong>Habilidades:</strong> {skills}</p>
                    }
                    {!skills &&
                    <p className="about padding-bottom-5"><strong>Habilidades:</strong> Desconocidas</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default CastPersonalInfo;