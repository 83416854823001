import React, {useState} from 'react'; 

function Checkbox(props) { 
  
  const [checked, setChecked] = useState(false); 
  const checkedText = props.onText; 
  const togglePreference = props.togglePreference; 
  const profession = props.profession; 
  
  const handleChange = () => { 
    setChecked(!checked); 
    togglePreference(profession); 
  }; 
  
  return ( 
    <div className="col-12">
        <label className="agreements">
            <input type="checkbox" onChange={handleChange}/>  
            &nbsp;{checkedText}                  
        </label>
    </div>
  ); 
}; 

export {Checkbox};