import {React, useEffect, useState} from 'react';
import { useErrorBoundary } from "react-error-boundary";
import axios from 'axios';
import CrewProjects from './CrewProjects';


function CrewBody(props) {
    const { showBoundary } = useErrorBoundary();
    

    const [project, setProject] = useState({});
    const {personId, demo, name} = props;

    
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if(personId){

            axios.get(`${process.env.REACT_APP_API_LOCATION}/crew/getMainProject/${personId}`, {
                cancelToken: cancelToken.token,
            })
            .then((response) => {            
                setProject(response.data);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    //console.error('Error al obtener los datos del proyecto:', error);
                }
            });
        }

        //Cleanup function
        return () => {
            cancelToken.cancel();
        }

    }, [personId, showBoundary]);

    if(!project){
        return <div>Cargando...</div>;
    }

    


    return (
        <>
        <div>
            {project && project._id &&
            <>
            <div className="row">
                <div className="col ">
                    <strong className="uppercase">Proyecto destacado de {name}</strong>
                    <hr/>
                    <div className="spacer20"></div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <a href={"/project/"+project._id} className="card-img-top"><img className="thumbnail-gallery img-fluid" src={project.cover.url} alt="Main project cover"/></a>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col project-type">
                            {project.project_type}
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col uppercase project-title">
                            {project.project_title}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col border-left about"><span className="mdi mdi-eye"></span>&nbsp;Vistas {project.views}</div>
                    </div>
                    <div className="row">
                        <div className="col border-left about"><span className="mdi mdi-thumb-up"></span>&nbsp;Valoraciones {project.likes}</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col uppercase">
                            {project.roleString}
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col about">
                            {project.project_desc}
                        </div>
                    </div>
                    <div className="spacer40"></div>
                </div>
            </div>
            
            </>
            }

            {demo &&
            <>
                <div className="row">
                    <div className="col-md-12 col-sm-8 text-left">
                        <strong className="uppercase">DEMO REEL</strong>
                        <hr className="hr-crew"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <hr/>
                    </div>
                </div>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src={demo} title={project.project_title} className="embed-responsive-item"></iframe>
                </div>
            </>
            }        
        </div>
        <CrewProjects personId={personId} name={name}/>
        </>
    )
}

export default CrewBody;