import React from "react";

import {formatearURL} from "../common/FormatFunctions";

function CrewAbout(props) {
  const {person} = props;

    let web = "";
    person.crew && person.crew.webpage ? web = person.crew.webpage: web = "";

    let resume = "";
    person.resume ? resume = person.resume: resume = "";

    if(web && web !== ''){
        web = formatearURL(web);
    }

    let webText = web;
    const webLength = 30;
    if(webText.length > webLength){
        webText = webText.slice(0,webLength) + "...";
    }

  return (
    <>
    {resume &&
    <div>
        <div className="row">
            <div className="col">
                <div className="spacer40  d-lg-none"></div>
                <strong className="uppercase">Acerca de {person.fullname}</strong>
                <hr/>
            </div>
        </div>
        <div className="spacer20"></div>
        <div className="row">
            <div className="col about">
                {resume}
            </div>
        </div>
        <div className="spacer10"></div>
        <div className="row">
            <div className="col">
                <a href={web} target="_blank" rel="noreferrer" className="text-gray"><strong className="text-gray"><small>{webText}</small></strong></a>
            </div>
        </div>
    </div>
    }
    </>
  );
}

export default CrewAbout;