import {React, useEffect, useCallback, useState, useRef} from 'react';
import axios from 'axios';


import '../styles.scss';

function ProjectResults(props) {    

    const pagination = process.env.REACT_APP_PAGINATION_LIMIT

    const [data, setData] = useState([]);
    //const [page, setPage] = useState(1);
    const page = useRef(1);
    const totalPages = useRef(0);
    const limit = useRef(pagination);
    const blockSize = useRef(pagination);
    const startPage = useRef(1);
    const endPage = useRef(pagination);
    const sortBy = useRef('project_title');
    const order = useRef('asc');
    //const [totalPages, setTotalPages] = useState(0);
    const totalResults = useRef(0);
    //const [totalResults, setTotalResults] = useState(0);
    //const [limit, setLimit] = useState(pagination);
    /*
    const [blockSize, setBlockSize] = useState(pagination);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(pagination);
    const [sortBy, setSortBy] = useState('project_title');
    const [order, setOrder] = useState('asc');*/

    const PREFIX = process.env.REACT_APP_PREFIX_FOR_IMAGES;
    
    
    let {searchQuery} = props;

    function handleNextPage(){
        if(page.current===endPage.current){
            page.current = page.current+1;
            endPage.current = endPage.current+blockSize.current;
        }
        page.current = page.current+1;
    };

    function handleLastPage(){
        if(page.current===startPage.current){
            startPage.current = startPage.current-blockSize.current;
            //setStartPage(page-blockSize);
            endPage.current = endPage.current-blockSize.current;
            //setEndPage(page-1);
        }
        page.current = page.current-1;
        //setPage(page-1);
    };

    function handleNumberOfPage(num){
        page.current = num;
        //setPage(num);
    };

    const renderPages = () => {
        let pages = [];
        for (let i = startPage.current; i <= totalPages.current && i <= endPage.current; i++) {
            if(i !== page.current){
                pages.push(<li className="page-item" key={i}><button className="page-link" onClick={(event)=>handleNumberOfPage(i)}>{i}</button></li>);
            }
            else{
                pages.push(<li className="page-item active" key={i}><button className="page-link" onClick={(event)=>handleNumberOfPage(i)}>{i}</button></li>);
            }
        }
        return pages;
    };

    const changeSort = (e) => {
        sortBy.current = e.target.value;
        //setSortBy(e.target.value);
    }

    const changeOrder = (e) => {
        order.current = e.target.value;
        //setOrder(e.target.value);
    }
    
    /*

    const getTotal = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/project/countProjects`;
        const newQuery = {...searchQuery};

        console.log(newQuery);

        await axios.post(url,
            newQuery
        )
        .then(response => {
            const count = response.data[0].total;
            if(count){
                console.log(parseInt(count));
                console.log("limit",limit.current);
                const totalP = parseInt(count) / limit.current;
                const totalR = parseInt(count);
                totalPages.current = totalP;
                totalResults.current = totalR;

                //setTotalPages(totalP);
                //setTotalResults(totalR);

                console.log("totalPages",totalP);
                console.log("totalResults",totalR);
            }
            
        })
        .catch(error => {
            console.log(error);
        });

    }, [searchQuery]);
*/
    const getResults = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/project/findProject`;
        const newQuery = {...searchQuery};

        console.log(newQuery);

        newQuery.page = page.current;
        newQuery.limit = limit.current;
        newQuery.sort = sortBy.current;
        newQuery.order = order.current;

        const response = await axios.post(url,
            newQuery
        );

        setData(response.data);
        const count = response.data.length;
        console.log("count",count);
        if(count){
            console.log(parseInt(count));
            console.log("limit",limit.current);
            const totalP = parseInt(count) / limit.current;
            const totalR = parseInt(count);
            totalPages.current = totalP;
            totalResults.current = count;
            console.log("totalPages",totalP);
            console.log("totalResults",totalR);
        }
        
    }, [searchQuery]);

    useEffect(() => {
        limit.current = pagination;
        //setLimit(pagination);
        //getTotal();
        getResults();
        blockSize.current = pagination;
        //setBlockSize(pagination);
    }, [getResults, pagination]);


    if(!data){
        return <div>Cargando...</div>
    }

    return (
    
        <div>
            {data && data.length > 0 &&
            <div className="container-fluid">
			    <div className="spacer30 d-md-none"></div>
                <div className="row">
                    <div className="col agreements">Resultados: {totalResults.current} </div>
                </div>
                <div className="row">
                    <div className="col-md-12"><hr/></div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4 col-sm-12 agreements">
                                Ordenar por:
                                <select 
                                    name="order" 
                                    className="form-control agreements" 
                                    onChange={(e) => changeSort(e)}
                                >
                                    <option value="project_title">Título</option>
                                    <option value="project_genre">Género</option>
                                    <option value="project_type">Categoría</option>
                                    <option value="project_status">Estatus</option>
                                    <option value="project_year">Año</option>
                                    <option value="views">Vistas</option>
                                    <option value="likes">Valoraciones</option>
                                </select>
                            </div>
                            <div className="col-md-4 col-sm-12 agreements">
                                Dirección:
                                <select 
                                    name="order" 
                                    className="form-control agreements" 
                                    onChange={(e) => changeOrder(e)}
                                >
                                    <option value="asc">Ascendente</option>
                                    <option value="desc">Descendente</option>  
                                    
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="spacer20"></div>
                <div className="row">
                    <div className="col-md-12"><hr/></div>
                </div>
                <div className="spacer20"></div>

                {/**Aqui empieza el ciclo */}

                {data.map((item, index) => (
                <div key={index}>

                    <div className="row h-100">
                        <div className="col-lg-3 col-12">
                            <a href={"/project/"+item._id}>
                                {item.cover && item.cover.url &&
                                <img src={PREFIX +"/" + item.userId + "/" + item.cover.url} className="media-thumb industry-img media-grid-res" alt='Cover'/>
                                }
                                {(!item.cover || !item.cover.url) &&
                                <img src="/background-blue.jpg" className="media-thumb industry-img media-grid-res" alt='Cover'/>
                                }
                            </a>
                        </div>
                        <div className="col-lg-9 col-sm-12">
                            <div className="container-fluid">
                                <div className="spacer20 d-lg-none"></div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                    <a href={"/project/"+item._id}><h5 className="h5-name">{item.project_title}</h5></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="container-fluid">
                                            <div className="row">
                                            <div className="col subtitle-underline"><strong>Publicado por:</strong></div>
                                            </div>
                                            <div className="row">
                                                <div className="col subtitle-underline"><a href={"/crew/"+item.userId} className="text-black">{item.user[0].fullname}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4 col-md-6 col-12 border-left">
                                        <div className="container-fluid">
                                            <div className="spacer20 d-md-none"></div>
                                            <div className="row"><div className="col subtitle-underline"><strong>Categoría:</strong> {item.project_type}</div></div>
                                            {item.status !=="Terminado" &&
                                            <div className="row"><div className="col subtitle-underline"><strong>Estatus:</strong> {item.project_status} </div></div>
                                            }
                                            <div className="row"><div className="col subtitle-underline"><strong>Género:</strong> {item.project_genre}</div></div>
                                            <div className="row"><div className="col subtitle-underline"><strong>Año:</strong> {item.project_year}</div></div>
                                            <div className="d-lg-none">
                                                <div className="spacer20"></div>
                                                <div className="row"><div className="col subtitle-underline"><span className="mdi mdi-eye"></span> Vistas: {item.views}</div></div>
                                                <div className="row"><div className="col subtitle-underline"><span className="mdi mdi-thumb-up"></span> Valoraciones: {item.likes}</div></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 border-left d-none d-lg-flex">
                                        <div className="container-fluid">
                                            <div className="spacer20"></div>
                                            <div className="row"><div className="col subtitle-underline"><span className="mdi mdi-eye"></span> Vistas: {item.views}</div></div>
                                            <div className="row"><div className="col subtitle-underline"><span className="mdi mdi-thumb-up"></span> Valoraciones: {item.likes}</div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>                    
                    <div className="spacer20"></div>
                    <hr/>
                    <div className="spacer20"></div>
                </div>
                ))}
                {/**Aqui termina el ciclo */}

                {totalResults.current > limit.current &&

                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="Pagination">
                                <ul className="pagination">
                                    {page.current > 1 &&
                                    <li className="page-item ">
                                        <button className="page-link btn-pagination"  onClick={handleLastPage}>Anterior</button>
                                    </li>
                                    }
                                    {page.current === 1 &&
                                    <li className="page-item disabled">
                                        <button className="page-link btn-pagination">Anterior</button>
                                    </li>
                                    }

                                    {renderPages()}
                                        
                                    {page.current < totalPages.current &&
                                    <li className="page-item">
                                        <button className="page-link btn-pagination"  onClick={handleNextPage}>Siguiente</button>
                                    </li>
                                    }
                                    {page.current >= totalPages.current &&
                                    <li className="page-item disabled">
                                        <button className="page-link btn-pagination">Siguiente</button>
                                    </li>
                                    }
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                }

            </div>
            }
            {(data.length === 0) &&
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="h5-name">No se encontraron resultados</h5>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default ProjectResults;