import {React, useEffect, useState} from "react";
import axios from "axios";
import config from "../../../config";
import Select from "react-select";
import ErrorHandler from "../../common/ErrorHandler";

function ModalForMetadata(props){ 
    
    const {projectId} = props;
    const {project} = props;

    const [hasError, setHasError] = useState({code:0, message:""});
    
    //const [data, setData] = useState({});
    const [value, setValue] = useState(null);
    const [genre, setGenre] = useState(null);
    const [year, setYear] = useState(null);
    const [statulist, setStatus] = useState(null);
/*
    const handleChange = ({currentTarget: input}) => { 
        setData({...data, [input.name]: input.value});
    };    */
    

    function updateMetadata(e){
        //try {
            const url = `${process.env.REACT_APP_API_LOCATION}/project/updateMetadata`;
            axios.put(url, {
                projectId: projectId,
                project_genre: genre.value,
                project_type: value.value,
                project_year: year.value,
                project_status: statulist.value
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            }).then((response) => {
                //console.log(response);
                window.location.reload();
            }).catch((error) => {
                //console.log(error.response);
                setHasError({code: error.response.status, message: error.response.data.error});  
                window.location.href = "/error?code=" 
                    + error.response.status 
                    + "&message=" + error.response.data.error;
            });


            

            //window.location.reload();
/*
        } catch (error) {
            console.log(error.response);
            setHasError({code: error.response.status, message: error.response.data.error});
            console.log(hasError);
        }*/   
    }

    useEffect(() => {
        if(project){
            let newValue = {value:project.project_type, label:project.project_type};
            setValue(newValue);
            let newGenre = {value:project.project_genre, label:project.project_genre};
            setGenre(newGenre);
            let newYear = {value:project.project_year, label:project.project_year};
            setYear(newYear);
            let newStatus = {value:project.project_status, label:project.project_status};
            setStatus(newStatus);
        }
    }, [project]);


    const options = [
        {value:"Cortometraje", label:"Cortometraje"},
        {value:"Largometraje", label:"Largometraje"},
        {value:"Comercial", label:"Comercial"},
        {value:"Fashion film", label:"Fashion film"},
        {value:"Serie/programa televison", label:"Serie/programa televison"},
        {value:"Serie web", label:"Serie web"},
        {value:"Videoclip", label:"Videoclip"},
    ];

    const genres = [
        {value:"Animacion", label:"Animacion"},
        {value:"Comedia", label:"Comedia"},
        {value:"Documental", label:"Documental"},
        {value:"Drama", label:"Drama"},
        {value:"Ensayo", label:"Ensayo"},
        {value:"Experimental", label:"Experimental"},
        {value:"Fantastico", label:"Fantastico"},
        {value:"Hibrido", label:"Hibrido"},
        {value:"Horror/gore", label:"Horror/gore"},
        {value:"Infantil", label:"Infantil"},
        {value:"LGBT", label:"LGBT"},
        {value:"Musical", label:"Musical"},
        {value:"Sc-fi", label:"Sc-fi"},
        {value:"Suspenso/ misterio", label:"Suspenso/ misterio"},
    ];

    const years = [
        {value:"2025", label:"2025"},
        {value:"2024", label:"2024"},
        {value:"2023", label:"2023"},
        {value:"2022", label:"2022"},
        {value:"2021", label:"2021"},
        {value:"2020", label:"2020"},
        {value:"2019", label:"2019"},
        {value:"2018", label:"2018"},
        {value:"2017", label:"2017"},
        {value:"2016", label:"2016"},
        {value:"2015", label:"2015"},
        {value:"2014", label:"2014"},
        {value:"2013", label:"2013"},
        {value:"2012", label:"2012"},
        {value:"2011", label:"2011"},
        {value:"2010", label:"2010"},
        {value:"2009", label:"2009"},
        {value:"2008", label:"2008"},
        {value:"2007", label:"2007"},
        {value:"2006", label:"2006"},
        {value:"2005", label:"2005"},
        {value:"2004", label:"2004"},
        {value:"2003", label:"2003"},
        {value:"2002", label:"2002"},
        {value:"2001", label:"2001"},
        {value:"2000", label:"2000"},
        {value:"1999", label:"1999"},
        {value:"1998", label:"1998"},
        {value:"1997", label:"1997"},
        {value:"1996", label:"1996"},
        {value:"1995", label:"1995"},
        {value:"1994", label:"1994"},
        {value:"1993", label:"1993"},
        {value:"1992", label:"1992"},
        {value:"1991", label:"1991"},
        {value:"1990", label:"1990"},
        {value:"1989", label:"1989"},
        {value:"1988", label:"1988"},
        {value:"1987", label:"1987"},
        {value:"1986", label:"1986"},
        {value:"1985", label:"1985"},
        {value:"1984", label:"1984"},
        {value:"1983", label:"1983"},
        {value:"1982", label:"1982"},
        {value:"1981", label:"1981"},
        {value:"1980", label:"1980"},
        {value:"1979", label:"1979"},
        {value:"1978", label:"1978"},
        {value:"1977", label:"1977"},
        {value:"1976", label:"1976"},
        {value:"1975", label:"1975"},
    ];

    const statuslist = [
        {value:"Desarrollo", label:"Desarrollo"},
        {value:"Pre-producción", label:"Pre-producción"},        
        {value:"Producción", label:"Producción"},
        {value:"Post-producción", label:"Post-producción"},
        {value:"Distribución/exhibición", label:"Distribución/exhibición"},
        {value:"Terminado", label:"Terminado"},
    ];

    const onDropdownChange = (e) => {
        setValue(e);
    }

    const onDropdownGenreChange = (e) => {
        setGenre(e);
    }

    const onDropdownYearChange = (e) => {
        setYear(e);
    }

    const onDropdownStatusChange = (e) => {
        setStatus(e);
    }

    
    if (hasError.code > 0) return <ErrorHandler code={hasError.code} title={hasError.message}/>

    return(
        <div className="modal fade" id="modalForMetadata" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <div style={{width:'80%'}}>

                                <label htmlFor="country">Tipo <span className="text-purple">(Requerido)</span></label>
                                <Select 
                                    options={options}
                                    value={value}
                                    onChange={onDropdownChange}
                                />
                            </div>
                            <div className="spacer20"></div>
                    
                            <div style={{width:'80%'}}>
                                <label htmlFor="country">Género <span className="text-purple">(Requerido)</span></label>
                                <Select 
                                    options={genres}
                                    value={genre}
                                    onChange={onDropdownGenreChange}
                                />
                            </div>
                            
                            <div className="spacer20"></div>
                            <div style={{width:'80%'}}>
                                <label htmlFor="year">Año <span className="text-purple">(Requerido)</span></label>
                                <Select 
                                    options={years}
                                    value={year}
                                    onChange={onDropdownYearChange}
                                />
                        
                                </div>                            
                            <div className="spacer20"></div>
                            <div style={{width:'80%'}}>
                                <label htmlFor="status">Estatus <span className="text-purple">(Requerido)</span></label>
                                <Select 
                                    options={statuslist}
                                    value={statulist}
                                    onChange={onDropdownStatusChange}
                                />
                            </div>
                        </div>
                           
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="row">
                            <div className="col-6">
                                <div className="col-lg-4"><button type="button" className="btn btn-purple btn-rounded" data-dismiss="modal">Cerrar</button></div>
                            </div>
                            <div className="col-6">
                                <div className="col-lg-4"><button type="button" className="btn btn-purple btn-rounded" data-dismiss="modal" onClick={updateMetadata}>Guardar</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalForMetadata;