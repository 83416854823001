const verifyValidSite = (url, site) => {
    let valid = true;
    
    const siteRegex = new RegExp(`^(?:https?://)?(?:www\\.)?${site}\\.com`);
    const isSite = siteRegex.test(url);
    isSite ? valid = true : valid = false;
    return valid;

}

/**verificar si una url es válida */
const verifyValidURL = (url) => {
    let valid = true;
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    const isURL = urlRegex.test(url);
    isURL ? valid = true : valid = false;
    return valid;

}

const verifyVideoURL = (videoURL) => {
    let normalizedURL = videoURL;
    
    // eslint-disable-next-line no-useless-escape                
    const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=\w+)(?:\S+)?$/;
    // eslint-disable-next-line no-useless-escape
    const vimeoRegex = /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

    const isYouTube = youtubeRegex.test(normalizedURL);
    const isVimeo = vimeoRegex.test(normalizedURL);

    let videoId, embeddedUrl;

    if (!isYouTube && !isVimeo) {
        embeddedUrl = null;
    }
    else{
        /*Transforma la url del demo para que se guarde de tal forma que se pueda ver en un iframe */
        if (isYouTube) {
            videoId = normalizedURL.match(/v=([\w-]+)/)[1];
            embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
        } 
        else {
            videoId = normalizedURL.match(vimeoRegex)[1];
            embeddedUrl = `https://player.vimeo.com/video/${videoId}`;
        }
    }
    
    return embeddedUrl;
}

export { verifyValidSite, verifyVideoURL, verifyValidURL };