import React from 'react'
import {Route, Routes} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Signup from './components/signup/Signup';
import Login from './components/login/Login';
import Register from './components/register/Register';
import Home from './components/home/Home';
import ProjectPage from './components/project/ProjectPage';
import IndustryPage from './components/industry/IndustryPage';
import CrewPage from './components/crew/CrewPage';
import CastPage from './components/cast/CastPage';
import CrewSearch from './components/crew/CrewSearch';
import CastSearch from './components/cast/CastSearch';
import ProjectSearch from './components/project/ProjectSearch';
import IndustrySearch from './components/industry/IndustrySearch';
import ProfileEdit from './components/user/ProfileEdit';
import MediaPage from './pages/media';
import AddIndustry from './components/edition/industry/AddIndustry';
import ProjecList from './components/project/edtion/ProjectList';
import IndustryList from './components/industry/edition/IndustryList';
import ProjectAdd from './components/project/ProjectAdd';
import ProjectForm from './components/project/ProjectForm';
import IndustryForm from './components/industry/IndustryForm';
import MediaLibrary from './components/media/MediaLibrary'; 
import Subscription from './pages/subscription';
import Success from './pages/success';
import Cancel from './pages/cancel';
import { ProtectedRoute } from './routes/ProtectedRoute';
import ErrorPage from './ErrorPage';
import NotFound from './NotFound';


const App = () =>  {
    return (        
      <>
        <Routes>
          <Route path="/" element={<Home/>} /> 
          <Route exact path="/signup" element={<Signup/>}/>
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/register" element={<Register/>}/>
          <Route exact path="/project/:id" element={<ProjectPage/>}/>
          <Route exact path="/industry/:id" element={<IndustryPage/>}/>
          <Route exact path="/crew/:id" element={<CrewPage/>}/>
          <Route exact path="/cast/:id" element={<CastPage/>}/>
          <Route exact path="/searchCrew" element={<CrewSearch/>}/>
          <Route exact path="/searchCast" element={<CastSearch/>}/>
          <Route exact path="/searchProject" element={<ProjectSearch/>}/>
          <Route exact path="/searchIndustry" element={<IndustrySearch/>}/>

          
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/editProfile" element={<ProfileEdit/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/industyList" element={<IndustryList/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/addIndustry" element={<AddIndustry/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/projectList" element={<ProjecList/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/ProjectAdd" element={<ProjectAdd/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/ProjectForm/:id" element={<ProjectForm/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/IndustryForm/:id" element={<IndustryForm/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/Subscription" element={<Subscription/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/media" element={<MediaPage/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/mediaLibrary" element={<MediaLibrary/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/success" element={<Success/>}/>
          </Route>
          <Route path="" element={<ProtectedRoute/>}>
            <Route exact path="/cancel" element={<Cancel/>}/>
          </Route>

          <Route exact path="/error" element={<ErrorPage/>}/>          
          <Route exact path="/notFound" element={<NotFound/>}/>

        </Routes>
        <ToastContainer />
      </>
    

    );

}

export default App;


