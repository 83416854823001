import React from 'react'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

const Title = styled.h1`
    font-family: 'Avenir-Black';
    font-size: 24px;
    margin-top: 3em;
    text-align: center;
    margin-bottom: 2em;
`

const TextBlock = styled.div`
    font-family: 'Avenir-Book';
    font-size: 18px;
    margin-top: 20px;
`

function success() {

const gotoBack = () => {
    window.location.href = '/subscription'
}

  return (
    <>
    <Header/>   
        <Container>
            <Title>Lamentablemente, parece que ha habido un error durante el proceso de compra.</Title>
            <TextBlock>
                Nos disculpamos por cualquier inconveniente que esto pueda haber causado. 
                Por favor, verifica que todos los detalles ingresados sean correctos e intenta nuevamente. 
                Si el problema persiste, no dudes en ponerte en contacto con nuestro equipo de soporte para 
                que podamos ayudarte a resolverlo lo más pronto posible.
            </TextBlock>
            <TextBlock>
                Tu satisfacción es nuestra prioridad número uno y estamos aquí para asegurarnos de que tengas 
                una experiencia de compra sin problemas. Gracias por tu comprensión y paciencia mientras 
                trabajamos para solucionar este problema.
            </TextBlock>            
            <div className='spacer20'></div>
            <div className='text-center'>
                <button className="btn-purlpe" onClick={gotoBack}>Regresar</button>
            </div>
            
            <div className='spacer40'></div>
        </Container>
    <Footer/>
    </>
  )
}

export default success