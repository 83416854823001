const formatMetadata = (project) => {
    let metadata = "";
    if(project && project.project_family && project.project_family!=="M"){
        if(project && project.project_type && project.project_type!==null && project.project_type!==""){
            metadata = project.project_type + " | ";
        }
        if(project && project.project_genre && project.project_genre!==null && project.project_genre!==""){
            metadata = metadata + project.project_genre + " | ";
        }
    }
    if(project && project.project_year){
        metadata = metadata + project.project_year;
    }
    if(metadata!== "" && metadata.length>0){
        if(metadata.substring(metadata.length-3, metadata.length)===" | "){
            metadata = metadata.substring(0,metadata.length-3);
        }
        return metadata;
    }
    else{
        return null;
    }
}

export default formatMetadata;