import {React, useEffect, useState} from 'react'
import { useErrorBoundary } from "react-error-boundary";
import axios from "axios";

function FormSelect({data, name, onChange, className, disabled, selected}) {
    const { showBoundary } = useErrorBoundary();
    const [catalog, setCatalog] = useState();

    
    //console.log(selected);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        axios.get(`${process.env.REACT_APP_API_LOCATION}/catalog/get/${data}`,{
            cancelToken: cancelToken.token,
        })
        .then((response) => {
            setCatalog(response.data);
        })
        .catch((error) => {
            if(axios.isCancel(error)){
                console.log("Request cancelled");
            }
            else{
                console.error(`Error al obtener el catálogo ${data}`, error);
                showBoundary(error);
            }
        });

        return () => {
            console.log("cleanup");
        }

    }, [showBoundary,data])


    if(!catalog){
        return <div>Cargando...</div>
    }

  return (
        <select 
            name={name}
            className={className} 
            disabled={disabled}
            value={selected}
            onChange={(e) => onChange(e)}>
            <option value="cualquier">Seleccionar</option>
            {catalog && catalog.map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
            ))}           
        </select>    
  )
}

export default FormSelect;