import {React, useEffect, useState, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { verifyVideoURL, verifyValidSite, verifyValidURL } from "../../common/formComponents/Validations";
import { toast } from "react-toastify";
import axios from "axios";
import FormButton from "../../common/formComponents/FormButton";
import FormSelect from "../../common/formComponents/FormSelect";
import FormLabel from "../../common/formComponents/FormLabel";
import FormTextareaInput from "../../common/formComponents/FormTextareaInput";
import FormTextInput from "../../common/formComponents/FormTextInput";
import FormSwitchList from "../../common/formComponents/FormSwitchList";
import FormSwitch from "../../common/formComponents/FormSwitch";
import FormValidationMessage from "../../common/formComponents/FormValidationMessage";
import CastGallery from "../CastGallery";
import CastCover from "../CastCover";
import { UserContext } from "../../../context";


function EditCast(props) {
    
    const navigate = useNavigate();

    const { showBoundary } = useErrorBoundary();
    const maxLength = 1000;

    const [person] = useContext(UserContext);

    console.log(person.data);

    const [data, setData] = useState({});
    const [invisibleCast, setInvisibleCast] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    
    const [preferences, setPreferences] = useState();
    const [languageValues, setLanguageValues] = useState();

    const [formError, setFormError] = useState(null);
    const [toggleDangerZone, setToggleDangerZone] = useState(false);

    const [coverArray, setCoverArray] = useState([]);

    const [formData, setFormData] = useState({});

    const [PLAN, setPLAN] = useState("FREE");

    function gotoUploadGallery (){
        navigate('/media', {
            state: {
              source: 'castGallery',
              id: person.data._id,
              currentGallery: person.data.cast.gallery
            }
        });
    }
    function gotoUploadCover (){
        navigate('/media', {
            state: {
              source: 'castCover',
              id: person.data._id,
              currentGallery: coverArray
            }
        });
    }

    function handleDangerZoneToggle () {
        setToggleDangerZone(!toggleDangerZone)
    }

    // Función para agregar una categoría si no existe en el arreglo.
    const togglePreference = (category) => {
        preferences[category] = !preferences[category];
        // Clonamos el objeto data para evitar mutaciones directas en el estado.
        const newData = { ...formData };
        // Verificamos si categories es nulo o indefinido.
        if (!newData.categories) {
          newData.categories = []; // Inicializamos como un arreglo vacío.
        }
      
        // Verificamos si la categoría ya existe en el arreglo categories.
        if (!newData.categories.includes(category) && preferences[category]) {
          // Si no existe, la agregamos al arreglo.
          newData.categories.push(category);
      
          // Actualizamos únicamente categorías con el nuevo objeto data.
          setFormData(newData);
          console.log(newData);
        }
        else{
            const index = newData.categories.indexOf(category);
            if (index > -1) {
                newData.categories.splice(index, 1);
            }
            setFormData(newData);
        }
      };

    // Función para agregar una categoría si no existe en el arreglo.
    const toggleLanguage = (lang) => {
        languageValues[lang] = !languageValues[lang];
        // Clonamos el objeto data para evitar mutaciones directas en el estado.
        const newData = { ...formData };
        // Verificamos si categories es nulo o indefinido.
        if (!newData.languages) {
          newData.languages = []; // Inicializamos como un arreglo vacío.
        }
      
        // Verificamos si la categoría ya existe en el arreglo categories.
        if (!newData.languages.includes(lang) && languageValues[lang]) {
          // Si no existe, la agregamos al arreglo.
          newData.languages.push(lang);
      
          // Actualizamos el estado con el nuevo objeto data.
          setFormData(newData);
        }
        else{
            const index = newData.languages.indexOf(lang);

            console.log(index);
            console.log(newData);

            if (index > -1) {
                newData.languages.splice(index, 1);
            }
            setFormData(newData);
        }
      };
    
    const handleChange = (e) => {
        setFormError(null);
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const cancelToken = axios.CancelToken.source();

        //console.log("Enviando los datos al servidor");

        
        let error = validateForm();

        //console.log(error);
        if(error){
            setFormError(error);
            console.log("Form validation failed, therefore it did not submit");
            return;
        }

        //console.log(formData);

        //console.log("Form validation passed, therefore it will submit");
        setSubmitted(true);
        
        
        try {            
            const url = `${process.env.REACT_APP_API_LOCATION}/cast/updateProfile`;
            await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                //console.log(response.data);
                toast.success('Perfil actualizado correctamente', { autoClose: 2000 });
                setSubmitted(false);
                
            } )
            .catch((error) => {
                showBoundary(error);
                console.error('Error al actualizar el perfil:', error);
            });
        } 
        catch (error) {
            console.log(error);
            showBoundary(error);
        }

    } 

    const validateForm = () => {
        let error = false;

        const {
            sex,
            ageRange,
            categories,
            resume,
            height,
            eyes,
            hair,
            hairType,
            peculiarities,
            ethnicity,
            physical,
            skills,
            languages,
            demo,
            facebook,
            twitter,
            vimeo,
            youtube,
            instagram,
            imdb,
            webpage
        } = formData;

        if(sex==='' || sex===null || sex===undefined || sex==='Seleccionar'){
            toast.error('El género no puede estar vacío', { autoClose: 2000 });
            error = {
                field_name: 'sex',
                message: 'El género no puede estar vacío'
            }        
            return error;    
        }

        if(ageRange==='' || ageRange===null || ageRange===undefined || ageRange==='Seleccionar'){
            toast.error('La edad no puede estar vacía', { autoClose: 2000 });
            error = {
                field_name: 'ageRange',
                message: 'La edad no puede estar vacía'
            }        
            return error;    
        }

        if(!categories || categories.length<=0){
            toast.error('La categoría no puede estar vacía', { autoClose: 2000 });
            error = {
                field_name: 'category',
                message: 'La categoría no puede estar vacía'
            }        
            return error;    
        }

        if(resume==='' || resume===null || resume===undefined){
            toast.error('La descripción no puede estar vacía', { autoClose: 2000 });
            error = {
                field_name: 'resume',
                message: 'La descripción no puede estar vacía'
            }        
            return error;    
        }

        if(PLAN==="PRO" || PLAN==="PREMIUM"){

            if(height==='' || height===null || height===undefined || height==='Seleccionar'){
                toast.error('La altura no puede estar vacía', { autoClose: 2000 });
                error = {
                    field_name: 'height',
                    message: 'La altura no puede estar vacía'
                }        
                return error;    
            }

            if(physical==='' || physical===null || physical===undefined || physical==='Seleccionar'){
                toast.error('La complexión no puede estar vacía', { autoClose: 2000 });
                error = {
                    field_name: 'physical',
                    message: 'La complexión no puede estar vacía'
                }        
                return error;    
            }

            if(ethnicity==='' || ethnicity===null || ethnicity===undefined || ethnicity==='Seleccionar'){
                toast.error('La etnicidad no puede estar vacía', { autoClose: 2000 });
                error = {
                    field_name: 'ethnicity',
                    message: 'La etnicidad no puede estar vacía'
                }        
                return error;    
            }

            if(eyes==='' || eyes===null || eyes===undefined || eyes==='Seleccionar'){
                toast.error('El color de ojos no puede estar vacío', { autoClose: 2000 });
                error = {
                    field_name: 'eyes',
                    message: 'El color de ojos no puede estar vacío'
                }        
                return error;    
            }

            if(hair==='' || hair===null || hair===undefined || hair==='Seleccionar'){
                toast.error('El color de cabello no puede estar vacío', { autoClose: 2000 });
                error = {
                    field_name: 'hair',
                    message: 'El color de cabello no puede estar vacío'
                }        
                return error;    
            }

            if(hairType==='' || hairType===null || hairType===undefined || hairType==='Seleccionar'){
                toast.error('El tipo de cabello no puede estar vacío', { autoClose: 2000 });
                error = {
                    field_name: 'hairType',
                    message: 'El tipo de cabello no puede estar vacío'
                }        
                return error;    
            }

            if(peculiarities==='' || peculiarities===null || peculiarities===undefined){
                toast.error('Las características físicas no pueden estar vacías', { autoClose: 2000 });
                error = {
                    field_name: 'peculiarities',
                    message: 'Las características físicas no pueden estar vacías'
                }        
                return error;    
            }

            if(skills==='' || skills===null || skills===undefined){
                toast.error('Las habilidades especiales no pueden estar vacías', { autoClose: 2000 });
                error = {
                    field_name: 'skills',
                    message: 'Las habilidades especiales no pueden estar vacías'
                }        
                return error;    
            }
        }

        if(!languages || languages.length===0){
            toast.error('Los idiomas no pueden estar vacíos', { autoClose: 2000 });
            error = {
                field_name: 'language',
                message: 'Los idiomas no pueden estar vacíos'
            }        
            return error;    
        }

        if(demo && demo !== ''){
            const videoURL = verifyVideoURL(demo);
            if(!videoURL){
                toast.error('La URL del demo no es válida, revisa que sea una URL de youtube o vimeo', { autoClose: 2000 });
                error = {
                    field_name: 'demo',
                    message: 'La URL del demo no es válida, revisa que sea una URL de youtube o vimeo'
                }        
                return error;
            }
            else{
                setData({...data, demo: videoURL});
            }
        }

        if(webpage && webpage !== ''){
            const valid = verifyValidURL(webpage, "wbpage");
            if(!valid){
                toast.error('La URL de la página web no es válida', { autoClose: 2000 });
                error = {
                    field_name: 'webpage',
                    message: 'La URL de la página web no es válida'
                }        
                return error;
            }
        }

        if(facebook && facebook !== ''){
            const valid = verifyValidSite(facebook, "facebook");
            if(!valid){
                toast.error('La URL de facebook no es válida', { autoClose: 2000 });
                error = {
                    field_name: 'facebook',
                    message: 'La URL de facebook no es válida'
                }        
                return error;
            }
        }

        if(twitter && twitter !== ''){
            const valid = verifyValidSite(twitter, "twitter");
            if(!valid){
                toast.error('La URL de twitter no es válida', { autoClose: 2000 });
                error = {
                    field_name: 'twitter',
                    message: 'La URL de twitter no es válida'
                }        
                return error;
            }
        }

        if(vimeo && vimeo !== ''){
            const valid = verifyValidSite(vimeo, "vimeo");
            if(!valid){
                toast.error('La URL de vimeo no es válida', { autoClose: 2000 });
                error = {
                    field_name: 'vimeo',
                    message: 'La URL de vimeo no es válida'
                }        
                return error;
            }
        }

        if(youtube && youtube !== ''){
            const valid = verifyValidSite(youtube, "youtube");
            if(!valid){
                toast.error('La URL de youtube no es válida', { autoClose: 2000 });
                error = {
                    field_name: 'youtube',
                    message: 'La URL de youtube no es válida'
                }        
                return error;
            }
        }

        if(instagram && instagram !== ''){
            const valid = verifyValidSite(instagram, "instagram");
            if(!valid){
                toast.error('La URL de instagram no es válida', { autoClose: 2000 });
                error = {
                    field_name: 'instagram',
                    message: 'La URL de instagram no es válida'
                }        
                return error;
            }
        }

        if(imdb && imdb !== ''){
            const valid = verifyValidSite(imdb, "imdb");
            if(!valid){
                toast.error('La URL de imdb no es válida', { autoClose: 2000 });
                error = {
                    field_name: 'imdb',
                    message: 'La URL de imdb no es válida'
                }        
                return error;
            }
        }

        return error;
    }


    const switchCast = async (e) => {
        e.preventDefault();
        let text = "";
        if(invisibleCast){
            text = "¿Deseas activar tu perfil de cast?";
        }
        else{
            text = "¿Deseas ocultar momentáneamente tu perfil de cast?";
        }
    
        if(window.confirm(text)){
            
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/cast/switchCast`;
                await axios.put(url, {
                    _id: person.data._id,
                    invisible: !invisibleCast
                })
                .then((response) => {
                    let msg = "";
                    if(invisibleCast){
                        msg = "Tu perfil de cast ha sido activado";
                    }
                    else{
                        msg = "Tu perfil de cast ha sido desactivado";
                    }
                    setInvisibleCast(!invisibleCast);
                    toast.success(msg, { autoClose: 2000 });
                    /*
                    setTimeout(() => {
                        navigate('/crew/'+person._id);
                    }, 1000);*/
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al activar/desactivar el perfil:', error);
                });
            } 
            catch (error) {
                console.log(error);
                showBoundary(error);
            }
        }
    }

    const deleteCast = async (e) => {
        e.preventDefault();
        let text = "¿Deseas eliminar tu perfil de cast? Esta acción no se puede deshacer";
    
        if(window.confirm(text)){
            
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/cast/deleteCast`;
                await axios.put(url, {
                    _id: person._id
                })
                .then((response) => {
                    let msg = "";
                    msg = "Tu perfil de cast ha sido eliminado";
                    toast.success(msg, { autoClose: 2000 });
                    
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al eliminar el perfil:', error);
                });
            } 
            catch (error) {
                console.log(error);
                showBoundary(error);
            }
        }
    }

    const gotoProfile = () => {
        navigate('/cast/'+person.data._id);
    };

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        const userId = person.data._id;
        if(userId){
            axios.get(`${process.env.REACT_APP_API_LOCATION}/cast/getUserMetadata/${userId}`,{
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                console.log(response.data);
                setFormData({
                    _id: response.data._id,
                    sex: response.data.cast.sex,
                    ageRange: response.data.cast.ageRange,
                    categories: response.data.cast.categories,
                    resume: response.data.cast.resume,
                    height: response.data.cast.height,
                    physical: response.data.cast.physical,
                    ethnicity: response.data.cast.ethnicity,
                    eyes: response.data.cast.eyes,
                    hair: response.data.cast.hair,
                    hairType: response.data.cast.hairType,
                    peculiarities: response.data.cast.peculiarities,
                    skills: response.data.cast.skills,
                    languages: response.data.cast.languages,
                    demo: response.data.cast.demo,
                    facebook: response.data.cast.facebook,
                    instagram: response.data.cast.instagram,
                    twitter: response.data.cast.twitter,
                    youtube: response.data.cast.youtube,
                    vimeo: response.data.cast.vimeo,                    
                    webpage: response.data.cast.webpage,
                    imdb: response.data.cast.imdb,
                    gallery: response.data.cast.gallery,
                    cover: response.data.cast.cover,
                    cast: response.data.cast
                });
                const cast = response.data.cast;
                setInvisibleCast(cast.invisible);

            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los datos del usuario:', error);
                }
            });

            setPLAN(person.data.plan);
                
        }


            axios.get(`${process.env.REACT_APP_API_LOCATION}/catalog/get/cast`,{
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                let preferences = {};
                let categories = response.data.map((item) => {                    
                    return item.value;
                });
                categories.forEach((item) => {
                    //agrega cada categoría con el valor que corresponda al objeto de preferencias
                    //if(data.categories && data.categories[item]){
                    //    preferences[item] = true;
                    //
                    //else{
                        preferences[item] = false;
                    //}
                });
                //setCategories(categories);
                setPreferences(preferences);        
            } )
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener las categorías de cast:', error);
                }
            });

            axios.get(`${process.env.REACT_APP_API_LOCATION}/catalog/get/language`,{
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                let languageValues = {};
                let languages = response.data.map((item) => {                    
                    return item.value;
                });
                languages.forEach((item) => {
                    //agrega cada categoría con el valor false al objeto de preferencias
                    languageValues[item] = false;
                });
                setLanguageValues(languageValues);
            } )
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener las categorías de cast:', error);
                }
            });

            let tmpArray = [];

            let lastSegment, width, height = "";
            if(person.data.cast.cover){
                if(person.data.cast.cover.url){
                    lastSegment = person.data.cast.cover.url.substring(person.data.cast.cover.url.lastIndexOf('/') + 1);
                }
                if(person.data.cast.cover.width){
                    width = person.data.cast.cover.width;
                }
                if(person.data.cast.cover.height){
                    height = person.data.cast.cover.height;
                }
            }

            tmpArray.push({
                url:lastSegment,
                width: width,
                height: height
            });
            setCoverArray(tmpArray);

        

        return () => {
            console.log("cleanup");
        }

    }, [showBoundary, person.data._id, person.data.cast, person.data.plan]);


    let gallery = person.data.cast.gallery;
    let galleryArray = [];
    for(let i=0; i<gallery.length; i++){
        let url = process.env.REACT_APP_PREFIX_FOR_IMAGES + "/" + person.data._id + "/" + gallery[i].url;
        let newItem = {
            url: url,
            width: gallery[i].width,
            height: gallery[i].height
        }
        galleryArray.push(newItem);
    }

    let galleryObject = {};
    galleryObject.gallery = galleryArray;



    if(!person){
        return <div>Cargando...</div>
    }

    return (
        <>
        <div className="container">
            <form onSubmit={handleSubmit}>
            <div className='row spaced'>
                <div className="col-lg-3">
                    <FormButton submit={false} onClick={gotoProfile} className="btn btn-options">Ver cast</FormButton>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">                    
                <div className="spacer40"></div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-12">
                            <h2 className="titles">Perfil de Cast</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-12 margin-10">
                        {invisibleCast &&  person.isCast &&
                            <FormSwitch
                                onChange={switchCast}
                                disabled={submitted ? "disabled":"" }
                                label="Activar perfil"
                                status={false}
                            />
                        }
                        {!invisibleCast && person.isCast &&
                            <FormSwitch
                                onChange={switchCast}
                                disabled={submitted ? "disabled":"" }
                                label="Desactivar perfil"
                                status={true}
                            /> 
                        }
                        </div>                        
                    </div>
                    <div className="spacer40"></div>               
                    <div className="row">
                        <div className="col">
                            <div className="spacer10"></div>
                            Foto de portada &nbsp;
                            <FormButton submit={false} onClick={gotoUploadCover} className="btn btn-purple" disabled={submitted ? "disabled":"" }>Cambiar <span className="mdi mdi-account-edit"></span></FormButton>
                            <div className="spacer10"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <CastCover person={formData}/>
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <FormLabel htmlFor="gender" required={true}>Género</FormLabel>
                            <FormSelect
                                name="sex"
                                data="gender"
                                onChange={handleChange}
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                                selected={formData.sex?formData.sex:'Seleccionar'}
                            /> 
                            {formError && formError.field_name === 'sex' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    
                        <div className="col-md-6 col-sm-12">
                            <FormLabel htmlFor="resumen" required={true}>Rango de edad o lo que aparenta</FormLabel>
                            <FormSelect
                                name="ageRange"
                                data="age"
                                onChange={handleChange}
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                                selected={formData.ageRange?formData.ageRange:'Seleccionar'}
                            />
                            {formError && formError.field_name === 'ageRange' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col">
                            <FormLabel htmlFor="resumen" required={true}>¿Cuál es tu categoría o categorías?</FormLabel>
                            <FormSwitchList
                                data="cast"
                                name="categories"
                                toggleFunction={togglePreference}
                                disabled={submitted ? "disabled":"" }
                                checkedValues={formData.categories}
                            />
                            {formError && formError.field_name === 'categories' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col form-group">
                            <FormLabel htmlFor="resumen" required={true}>Acerca de</FormLabel>
                            <FormTextareaInput
                                rows="5" 
                                placeholder="Cuéntanos de ti (1000 caracteres máx)." 
                                maxLength={maxLength}
                                name="resume"
                                value={(formData && formData.resume) ? formData.resume:''}
                                onChange={handleChange}      
                                disabled={submitted ? "disabled":"" }
                            />
                            
                            {formData && formData.resume &&
                            <small>Caracteres restantes: {maxLength - formData.resume.length}/{maxLength}</small>
                            }
                            {formData && !formData.resume &&
                            <small>Caracteres restantes: {maxLength}/{maxLength}</small>
                            }
                            {formError && formError.field_name === 'resume' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}     
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    
                    
                    
                    <>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="height">Altura <span className="text-purple">(Requerido)</span></label>
                            {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                            <FormSelect
                                name="height"
                                data="height"
                                onChange={handleChange}
                                selected={formData.height?formData.height:'Seleccionar'}
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                            />
                            }
                            {formError && formError.field_name === 'height' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            {(PLAN==="FREE") &&
                            <>
                            <select 
                                className="form-control agreements"
                                disabled="disabled"
                            >
                                <option value="cualquier">Seleccionar</option>
                            </select>
                            <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                            </>
                            }
                        </div>
                    
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="physical">Complexión <span className="text-purple">(Requerido)</span></label>
                            {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                            <FormSelect
                                name="physical"
                                data="body"
                                onChange={handleChange}
                                selected={formData.physical?formData.physical:'Seleccionar'}
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                            />
                            }
                            {formError && formError.field_name === 'physical' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            {(PLAN==="FREE") &&
                            <>
                            <select 
                                className="form-control agreements"
                                disabled="disabled"
                            >
                                <option value="cualquier">Seleccionar</option>
                            </select>
                            <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                            </>
                            }
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="ethnicity">Etnicidad <span className="text-purple">(Requerido)</span></label>
                            {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                            <FormSelect
                                name="ethnicity"
                                data="ethnicity"
                                onChange={handleChange}
                                selected={formData.ethnicity? formData.ethnicity:'Seleccionar' }
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                            />
                            }
                            {formError && formError.field_name === 'ethnicity' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            {(PLAN==="FREE") &&
                            <>
                            <select 
                                className="form-control agreements"
                                disabled="disabled"
                            >
                                <option value="cualquier">Seleccionar</option>
                            </select>
                            <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                            </>
                            }
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="height">Color de ojos <span className="text-purple">(Requerido)</span></label>
                            {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                            <FormSelect
                                name="eyes"
                                data="eyes"
                                onChange={handleChange}
                                selected={formData.eyes?formData.eyes:'Seleccionar'}
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                            />
                            }
                            {formError && formError.field_name === 'eyes' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            {(PLAN==="FREE") &&
                            <>
                            <select 
                                className="form-control agreements"
                                disabled="disabled"
                            >
                                <option value="cualquier">Seleccionar</option>
                            </select>
                            <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                            </>
                            }
                        </div>
                    
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="hair">Color de cabello <span className="text-purple">(Requerido)</span></label>
                            {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                            <FormSelect
                                name="hair"
                                data="hair"
                                onChange={handleChange}
                                selected={formData.hair?formData.hair:'Seleccionar'}
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                            />
                            }
                            {formError && formError.field_name === 'hair' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            {(PLAN==="FREE") &&
                            <>
                            <select 
                                className="form-control agreements"
                                disabled="disabled"
                            >
                                <option value="cualquier">Seleccionar</option>
                            </select>
                            <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                            </>
                            }
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="hairType">Tipo de cabello <span className="text-purple">(Requerido)</span></label>
                            {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                            <FormSelect
                                name="hairType"
                                data="hairType"
                                onChange={handleChange}
                                selected={formData.hairType ? formData.hairType : "Seleccionar"}
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                            />
                            }
                            {formError && formError.field_name === 'hairType' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            {(PLAN==="FREE") &&
                            <>
                            <select 
                                className="form-control agreements"
                                disabled="disabled"
                            >
                                <option value="cualquier">Seleccionar</option>
                            </select>
                            <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                            </>
                            }
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col form-group">
                            <FormLabel htmlFor="peculiarities" required={true}>Características físicas particulares ( Cicatrices,lunares, tatuajes, etc.)</FormLabel>
                            {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                            <FormTextareaInput
                                rows="5" 
                                placeholder="Cuéntanos de ti (1000 caracteres máx)." 
                                maxLength={maxLength}
                                name="peculiarities"
                                value={(formData && formData.peculiarities) ? formData.peculiarities:''}
                                onChange={handleChange}     
                                disabled={submitted ? "disabled":"" }
                            />
                            }
                            {(PLAN==="FREE") &&
                            <>
                            <FormTextareaInput
                                rows="5"
                                placeholder="Cuéntanos de ti (1000 caracteres máx)."
                                disabled="disabled"
                            >

                            </FormTextareaInput>
                            <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                            </>
                            }
                            {formData && formData.peculiarities &&
                            <small>Caracteres restantes: {maxLength - formData.peculiarities.length}/{maxLength}</small>
                            }
                            {formData && !formData.peculiarities &&
                            <small>Caracteres restantes: {maxLength}/{maxLength}</small>
                            }
                            {formError && formError.field_name === 'peculiarities' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col form-group">                            
                            <FormLabel htmlFor="resumen" required={true}>Habilidades especiales (Montar a caballo, stunts, gimnasia, natacion, canto, baile ,etc)</FormLabel>
                            {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                            <FormTextareaInput
                                rows="5" 
                                placeholder="Cuéntanos de ti (1000 caracteres máx)." 
                                maxLength={maxLength}
                                name="skills"
                                value={(formData && formData.skills) ? formData.skills:''}
                                onChange={handleChange}     
                                disabled={submitted ? "disabled":"" }
                            />
                            }
                            {(PLAN==="FREE") &&
                            <>
                            <FormTextareaInput
                                rows="5"
                                placeholder="Cuéntanos de ti (1000 caracteres máx)."
                                disabled="disabled"
                            >

                            </FormTextareaInput>
                            <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                            </>
                            }
                            {formData && formData.skills &&
                            <small>Caracteres restantes: {maxLength - formData.skills.length}/{maxLength}</small>
                            }
                            {formData && !formData.skills &&
                            <small>Caracteres restantes: {maxLength}/{maxLength}</small>
                            }
                            {formError && formError.field_name === 'skills' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    </>

                    
                    
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col">
                            <FormLabel htmlFor="language" required={true}>Idiomas</FormLabel>                    
                            <FormSwitchList
                                data="language"
                                toggleFunction={toggleLanguage}
                                disabled={submitted ? "disabled":"" }
                                checkedValues={formData.languages}
                            />
                           {formError && formError.field_name === 'language' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <h2>En Internet</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                          <div className="col form-group">
                            <FormLabel htmlFor="webpage">Página Web</FormLabel>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-web"></span></div>
                              </div>
                              <FormTextInput
                                name="webpage"
                                placeholder="Ej: www.cinekomuna.com"
                                value={(formData && formData.webpage)? formData.webpage:''}
                                onChange={handleChange}
                                disabled={submitted ? "disabled":"" }
                              />
                            </div>
                          </div>
                          <div className="col form-group">
                            <FormLabel htmlFor="demo">Demo reel</FormLabel>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-play"></span></div>
                              </div>
                                <FormTextInput
                                    name="demo"
                                    placeholder="URL de un video de youtube o vimeo"
                                    defaultValue={(formData && formData.demo)?formData.demo:''}
                                    onChange={handleChange}
                                    disabled={submitted ? "disabled":"" }
                                />
                                {formError && formError.field_name === 'demo' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col form-group">
                            <FormLabel htmlFor="facebook">Facebook</FormLabel>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-facebook"></span></div>
                              </div>
                                <FormTextInput
                                    name="facebook"
                                    placeholder="Ej. www.facebook.com/cinekomuna"
                                    
                                    value={(formData && formData.facebook)?formData.facebook:''}
                                    onChange={handleChange}
                                    disabled={submitted ? "disabled":"" }
                                />
                                
                            </div>
                            {formError && formError.field_name === 'facebook' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                          </div>
                          <div className="col form-group">
                            <FormLabel htmlFor="twitter">Twitter</FormLabel>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-twitter"></span></div>
                              </div>
                                <FormTextInput
                                    name="twitter"
                                    placeholder="Ej. www.twitter.com/cinekomuna"
                                    value={(formData && formData.twitter)?formData.twitter:''}
                                    onChange={handleChange}
                                    disabled={submitted ? "disabled":"" }
                                />                                
                            </div>
                            {formError && formError.field_name === 'twitter' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                          </div>
                          
                        </div>
                        <div className="row">
                          <div className="col form-group">
                            <FormLabel htmlFor="vimeo">Vimeo</FormLabel>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-vimeo"></span></div>
                              </div>
                                <FormTextInput
                                    name="vimeo"
                                    placeholder="Ej. www.vimeo.com/cinekomuna"
                                    value={(formData && formData.vimeo)?formData.vimeo:''}
                                    onChange={handleChange}
                                    disabled={submitted ? "disabled":"" }
                                />
                                
                            </div>
                            {formError && formError.field_name === 'vimeo' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                          </div>
                          <div className="col form-group">
                            <FormLabel htmlFor="youtube">Youtube</FormLabel>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-youtube"></span></div>
                              </div>
                                <FormTextInput
                                    name="youtube"
                                    placeholder="Ej. www.youtube.com/cinekomuna"
                                    value={(formData && formData.youtube)?formData.youtube:''}
                                    onChange={handleChange}
                                    disabled={submitted ? "disabled":"" }
                                />
                                
                            </div>
                            {formError && formError.field_name === 'youtube' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                          </div>
                          
                        </div>
                        <div className="row">
                          <div className="col form-group">
                            <FormLabel htmlFor="instagram">Instagram</FormLabel>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-instagram"></span></div>
                              </div>
                                <FormTextInput
                                    name="instagram"
                                    placeholder="Ej. www.instagram.com/cinekomuna"
                                    value={(formData && formData.instagram)?formData.instagram:''}
                                    onChange={handleChange}
                                    disabled={submitted ? "disabled":"" }
                                />
                                
                            </div>
                            {formError && formError.field_name === 'instagram' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                          </div>  
                          <div className="col form-group">
                            <FormLabel htmlFor="imdb">IMDB</FormLabel>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-movie-roll"></span></div>
                              </div>
                              <FormTextInput
                                name="imdb"
                                placeholder="Ej. www.imdb.com/cinekomuna"
                                value={(formData && formData.imdb)?formData.imdb:''}
                                onChange={handleChange}
                                disabled={submitted ? "disabled":"" }
                              />
                              
                            </div>
                            {formError && formError.field_name === 'imdb' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                          </div>  
                          
                        </div>
                        
                        
                        <div className="spacer40"></div>
                       
                        <div className="row">
                            <div className="col-lg-12"> 
                                <CastGallery person={galleryObject}/>
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12"> 
                            <FormButton submit={false} onClick={gotoUploadGallery} className="btn btn-purple" disabled={submitted ? "disabled":"" }>Administrar galería de Book <span className="mdi mdi-plus"></span></FormButton>
                        </div>
                        </div>
                        <div className="spacer40"></div>
                        <div className="row">
                            <div className="col">
                                <FormButton submit={true} className="btn btn-purple" disabled={submitted ? "disabled":"" }>Guardar</FormButton>
                            </div>
                        </div>            
                        {false &&
                        <>
                        <div className="spacer40"></div>
                        {person.isCast &&
                        <div className="row">
                            <div className="col-12 danger-zone">                    
                                <span 
                                    className={!toggleDangerZone ? 'mdi mdi-arrow-right cursor' : 'mdi mdi-arrow-down cursor'} 
                                    data-toggle="collapse" 
                                    data-target="#collapseExample" 
                                    aria-expanded="false" 
                                    aria-controls="collapseExample" 
                                    onClick={handleDangerZoneToggle}
                                >&nbsp;Mostar zona de riesgo</span>
                                <div className="collapse uppercase" id="collapseExample">
                                    <div className="spacer10"></div>
                                    <p className="text-danger">¡Cuidado! Estas acciones no se pueden deshacer</p>
                                    <div className="spacer10"></div>
                                    <FormButton submit={false} onClick={deleteCast} className="btn btn-purple" disabled={submitted ? "disabled":"" }> 
                                        Eliminar perfil de Cast
                                    </FormButton>
                                </div>
                            </div>
                        </div>
                        }
                        </>
            }
                        <div className="spacer40"></div>
                      </div>
                </div>
                    
            </div>  
            
            </form>
        </div>
        </>
    )
}

export default EditCast;