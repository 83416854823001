
export const typeCat = [
    { label: "PRINCIPALES", value: "PRINCIPALES", disabled: true },
    { label: "-----------", value: "-----------", disabled: true },
    { label: "Agencia de casting", value: "Agencia de casting" },
    { label: "Agencia de publicidad ", value: "Agencia de publicidad " },
    { label: "Agencia de representación/ management ", value: "Agencia de representación/ management " },
    { label: "Agencia y/o estudio doblaje/ locutores ", value: "Agencia y/o estudio doblaje/ locutores " },
    { label: "Asociación/ Organización/ Redes ", value: "Asociación/ Organización/ Redes " },
    { label: "Bodega de arte", value: "Bodega de arte" },
    { label: "Bodega de vestuario", value: "Bodega de vestuario" },
    { label: "Cámaras empresariales cine y televisión", value: "Cámaras empresariales cine y televisión" },
    { label: "Canal de televisión", value: "Canal de televisión" },
    { label: "Casa post-productora (Audio)", value: "Casa post-productora (Audio)" },
    { label: "Casa post-productora (Imagen)", value: "Casa post-productora (Imagen)" },
    { label: "Casa productora", value: "Casa productora" },
    { label: "Catering", value: "Catering" },
    { label: "Comisiones de filmación ", value: "Comisiones de filmación " },
    { label: "Distribuidora", value: "Distribuidora" },
    { label: "Escuelas (Cine/ Medios audiovisuales)", value: "Escuelas (Cine/ Medios audiovisuales)" },
    { label: "Estudio de animación", value: "Estudio de animación" },
    { label: "Exhibición (Salas/ Cineclubs/ Espacios )", value: "Exhibición (Salas/ Cineclubs/ Espacios )" },
    { label: "Festivales / Muestras de cine", value: "Festivales / Muestras de cine" },
    { label: "Grupo Gaffer/ Staff/ Electrico ", value: "Grupo Gaffer/ Staff/ Electrico " },
    { label: "Institución de gobierno", value: "Institución de gobierno" },
    { label: "Locaciones", value: "Locaciones" },
    { label: "Musicalización/ Musica original ", value: "Musicalización/ Musica original " },
    { label: "Plataforma de streaming", value: "Plataforma de streaming" },
    { label: "Renta de equipo", value: "Renta de equipo" },
    { label: "Renta de equipo (audio)", value: "Renta de equipo (audio)" },
    { label: "Renta de foro/ set", value: "Renta de foro/ set" },
    { label: "Servicios de producción", value: "Servicios de producción" },
    { label: "Sindicatos", value: "Sindicatos" },
    { label: "", value: "" },
    { label: "SECUNDARIAS", value: "SECUNDARIAS", disabled: true },
    { label: "-----------", value: "-----------", disabled: true },
    { label: "Agencia aduanal/ logistica cine", value: "Agencia aduanal/ logistica cine" },
    { label: "Agencia de extras", value: "Agencia de extras" },
    { label: "Agencia de investigación de mercado (Cine/ publicidad)", value: "Agencia de investigación de mercado (Cine/ publicidad)" },
    { label: "Agencia de modelos", value: "Agencia de modelos" },
    { label: "Agencia de trailers", value: "Agencia de trailers" },
    { label: "Agencia financiera producciones audiovisuales", value: "Agencia financiera producciones audiovisuales" },
    { label: "Agencia marketing cine", value: "Agencia marketing cine" },
    { label: "Animal wrangler", value: "Animal wrangler" },
    { label: "Armas para producciones audiovisuales", value: "Armas para producciones audiovisuales" },
    { label: "Aseguradora/ seguros producciones audiovisuales", value: "Aseguradora/ seguros producciones audiovisuales" },
    { label: "Book actores (Servicios foto y video)", value: "Book actores (Servicios foto y video)" },
    { label: "Colectivo/ Laboratorio cinematografico", value: "Colectivo/ Laboratorio cinematografico" },
    { label: "Compañía baile para producciones audiovisuales", value: "Compañía baile para producciones audiovisuales" },
    { label: "Compañía/ Asociación teatral", value: "Compañía/ Asociación teatral" },
    { label: "Contabilidad para producciones audiovisuales", value: "Contabilidad para producciones audiovisuales" },
    { label: "Copywriter", value: "Copywriter" },
    { label: "Derechos/ copyright musical", value: "Derechos/ copyright musical" },
    { label: "Desarrollo de carpetas", value: "Desarrollo de carpetas" },
    { label: "Despacho/ Asesoría legal", value: "Despacho/ Asesoría legal" },
    { label: "Diseño gráfico (Posters, postales ,tipografia)", value: "Diseño gráfico (Posters, postales ,tipografia)" },
    { label: "Diseño web ", value: "Diseño web " },
    { label: "Disfraces y pelucas", value: "Disfraces y pelucas" },
    { label: "Drone/ Fotografía aérea", value: "Drone/ Fotografía aérea" },
    { label: "Dummies", value: "Dummies" },
    { label: "Efectos caracterización (maquillaje, prostéticos, botargas)", value: "Efectos caracterización (maquillaje, prostéticos, botargas)" },
    { label: "Efectos SFX (Efectos especiales onset. Explosiones/ lluvia/ etc)  ", value: "Efectos SFX (Efectos especiales onset. Explosiones/ lluvia/ etc)  " },
    { label: "Efectos VFX/ CGI/ Motion  ", value: "Efectos VFX/ CGI/ Motion  " },
    { label: "Escuela de actuación", value: "Escuela de actuación" },
    { label: "Escuela de animación", value: "Escuela de animación" },
    { label: "Estudio creativo/ Art direction", value: "Estudio creativo/ Art direction" },
    { label: "Estudio de fotografía", value: "Estudio de fotografía" },
    { label: "Estudio de grabación (Música/ audio/ follys/ etc)", value: "Estudio de grabación (Música/ audio/ follys/ etc)" },
    { label: "Fondos/ Premios/ Residencias/ Talleres", value: "Fondos/ Premios/ Residencias/ Talleres" },
    { label: "Helicópteros para producciones audiovisuales", value: "Helicópteros para producciones audiovisuales" },
    { label: "Jardinería cinematográfica (Greensman)", value: "Jardinería cinematográfica (Greensman)" },
    { label: "Jingles", value: "Jingles" },
    { label: "Laboratorio/ Revelado fotográfico", value: "Laboratorio/ Revelado fotográfico" },
    { label: "Limpieza/ Sanitización set", value: "Limpieza/ Sanitización set" },
    { label: "Maquetas para producciones audiovisuales", value: "Maquetas para producciones audiovisuales" },            
    { label: "Provedores luces cinematograficas", value: "Provedores luces cinematograficas" },            
    { label: "Proveedores equipo de audio", value: "Proveedores equipo de audio" },
    { label: "Proveedores expendables", value: "Proveedores expendables" },
    { label: "Proveedores material cinematográfico (Celuloide)", value: "Proveedores material cinematográfico (Celuloide)" },
    { label: "Proveedores material maquillaje y peinados", value: "Proveedores material maquillaje y peinados" },
    { label: "Proveedores vídeo y fotografía", value: "Proveedores vídeo y fotografía" },
    { label: "Realidad virtual", value: "Realidad virtual" },
    { label: "Renta de campers", value: "Renta de campers" },
    { label: "Renta de equipo especializado", value: "Renta de equipo especializado" },
    { label: "Renta de pipas agua", value: "Renta de pipas agua" },
    { label: "Renta de transporte (Vans/ camionetas)", value: "Renta de transporte (Vans/ camionetas)" },
    { label: "Renta de video assist / DIT", value: "Renta de video assist / DIT" },
    { label: "Renta de walkie talkie (radio comunicación)", value: "Renta de walkie talkie (radio comunicación)" },
    { label: "Renta picture cars", value: "Renta picture cars" },
    { label: "Renta plantas de luz / generadores", value: "Renta plantas de luz / generadores" },
    { label: "Renta sanitarios moviles", value: "Renta sanitarios moviles" },
    { label: "Reproducciones", value: "Reproducciones" },
    { label: "Revista/ Blogs/ Podcasts de cine", value: "Revista/ Blogs/ Podcasts de cine" },
    { label: "Seguridad/ Vigilancia set", value: "Seguridad/ Vigilancia set" },
    { label: "Servicio de subtitulaje/ traducción", value: "Servicio de subtitulaje/ traducción" },
    { label: "Servicios edicion", value: "Servicios edicion" },
    { label: "Servicios escritura guión/ script doctor", value: "Servicios escritura guión/ script doctor" },
    { label: "Servicios grabación sonido directo", value: "Servicios grabación sonido directo" },
    { label: "Servicios streaming/ transmisión", value: "Servicios streaming/ transmisión" },
    { label: "Stock video y foto/ Archivo fílmico", value: "Stock video y foto/ Archivo fílmico" },
    { label: "Storyboard", value: "Storyboard" },
    { label: "Stunts", value: "Stunts" },
    { label: "Video 360", value: "Video 360" },
    { label: "Video mapping", value: "Video mapping" }
];

export const yearCat = [
    { label: "2025", value: "2025" },
    { label: "2024", value: "2024" },
    { label: "2023", value: "2023" },
    { label: "2022", value: "2022" },
    { label: "2021", value: "2021" },
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
    { label: "2017", value: "2017" },
    { label: "2016", value: "2016" },
    { label: "2015", value: "2015" },
    { label: "2014", value: "2014" },
    { label: "2013", value: "2013" },
    { label: "2012", value: "2012" },
    { label: "2011", value: "2011" },
    { label: "2010", value: "2010" },
    { label: "2009", value: "2009" },
    { label: "2008", value: "2008" },
    { label: "2007", value: "2007" },
    { label: "2006", value: "2006" },
    { label: "2005", value: "2005" },
    { label: "2004", value: "2004" },
    { label: "2003", value: "2003" },
    { label: "2002", value: "2002" },
    { label: "2001", value: "2001" },
    { label: "2000", value: "2000" },
    { label: "1999", value: "1999" },
    { label: "1998", value: "1998" },
    { label: "1997", value: "1997" },
    { label: "1996", value: "1996" },
    { label: "1995", value: "1995" },
    { label: "1994", value: "1994" },
    { label: "1993", value: "1993" },
    { label: "1992", value: "1992" },
    { label: "1991", value: "1991" },
    { label: "1990", value: "1990" },
    { label: "1989", value: "1989" },
    { label: "1988", value: "1988" },
    { label: "1987", value: "1987" },
    { label: "1986", value: "1986" },
    { label: "1985", value: "1985" },
    { label: "1984", value: "1984" },
    { label: "1983", value: "1983" },
    { label: "1982", value: "1982" },
    { label: "1981", value: "1981" },
    { label: "1980", value: "1980" },
    { label: "1979", value: "1979" },
    { label: "1978", value: "1978" },
    { label: "1977", value: "1977" },
    { label: "1976", value: "1976" },
    { label: "1975", value: "1975" },
    { label: "1974", value: "1974" },
    { label: "1973", value: "1973" },
    { label: "1972", value: "1972" },
    { label: "1971", value: "1971" },
    { label: "1970", value: "1970" }
];