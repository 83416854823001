import {React, useState} from "react";
import axios from "axios";

function ModalForSocialMedia(props){    

    const {projectId} = props;
    const {project} = props;
    
    const [data, setData] = useState({});

    const handleChange = ({currentTarget: input}) => { 
        setData({...data, [input.name]: input.value});
    };    
    
    function updateSocialMedia(e){
        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/project/updateSocialMedia`;
            axios.put(url, {
                projectId: projectId,
                project_web_page: data.project_web_page,
                project_facebook_page: data.project_facebook_page,
                project_twitter_page: data.project_twitter_page,
                project_instagram_page: data.project_instagram_page,
                project_youtube_page: data.project_youtube_page,
                project_vimeo_page: data.project_vimeo_page,
                project_imdb_page: data.project_imdb_page,
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });

            window.location.reload();

        } catch (error) {
            console.log(error);
        }   
    }

    return(
        <div className="modal fade" id="modalForSocialMedia" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><span className="mdi mdi-web"></span></div>
                                        </div>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Ej: www.cinekomuna.com" 
                                            maxLength="120" 
                                            name="project_web_page"
                                            defaultValue={project.project_web_page}
                                            onChange={handleChange}
                                        />
                        
                                    </div>
                                </div>
                            </div>
                            <div className="spacer20"></div>
                    
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><span className="mdi mdi-facebook"></span></div>
                                        </div>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Ej: www.cinekomuna.com" 
                                            maxLength="120" 
                                            name="project_facebook_page"
                                            onChange={handleChange}
                                            defaultValue={project.project_facebook_page}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="spacer20"></div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><span className="mdi mdi-twitter"></span></div>
                                        </div>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Ej: www.cinekomuna.com" 
                                            maxLength="120" 
                                            name="project_twitter_page"
                                            onChange={handleChange}
                                            defaultValue={project.project_twitter_page}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="spacer20"></div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><span className="mdi mdi-vimeo"></span></div>
                                        </div>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Ej: www.cinekomuna.com" 
                                            maxLength="120" 
                                            name="project_vimeo_page"
                                            onChange={handleChange}
                                            defaultValue={project.project_vimeo_page}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="spacer20"></div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><span className="mdi mdi-youtube"></span></div>
                                        </div>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Ej: www.cinekomuna.com" 
                                            maxLength="120" 
                                            name="project_youtube_page"
                                            onChange={handleChange}
                                            defaultValue={project.project_youtube_page}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="spacer20"></div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><span className="mdi mdi-instagram"></span></div>
                                        </div>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Ej: www.cinekomuna.com" 
                                            maxLength="120" 
                                            name="project_instagram_page"
                                            onChange={handleChange}
                                            defaultValue={project.project_instagram_page}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                           
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="row">
                            <div className="col-6">
                                <div className="col-lg-4"><button type="button" className="btn btn-purple btn-rounded" data-dismiss="modal">Cerrar</button></div>
                            </div>
                            <div className="col-6">
                                <div className="col-lg-4"><button type="button" className="btn btn-purple btn-rounded" data-dismiss="modal" onClick={updateSocialMedia}>Guardar</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalForSocialMedia;