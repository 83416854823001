import {React, useState, useEffect, useCallback, useRef}  from 'react';
import axios from 'axios';

function CrewFilters(props) {
    const [name, setName] = useState('');
    
    const selectForm = useRef(null);    
    const queryObject = useRef({});
    const country = useRef('');
    const state = useRef('');
    const city = useRef('');
    const ocupation =useRef('');
    
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    /**Cada que se escribe en el texto de búsqueda se invoca esta función */
    function handleChange(e) {        
        setName(e.target.value);
    }

    /**Cuando se elige un rol se invoca esta función */
    function handleRole(e) {
        ocupation.current = e.target.value;
        handleQuery();
    }

    /**Cuando se elige un país se invoca esta función */
    async function updateStates (event){
        if(event.target.value === 'cualquier'){
            country.current = '';
            state.current = '';
            city.current = '';
            delete queryObject.current.country;
            delete queryObject.current.state;
            delete queryObject.current.city;
        }
        else{
            country.current = event.target.value;
            delete queryObject.current.state;
            state.current = '';
            delete queryObject.current.city;
            city.current = '';
        }
        
        handleQuery();

        const url = `${process.env.REACT_APP_API_LOCATION}/location/getStates`;
        const response = await axios.post(url,{
            "country": event.target.value
        });       
        setStates(response.data);
        setCities([]);
        
    };

    /**Cuando se elige un estado se invoca esta función */
    async function handleState(e) {
        if(e.target.value === 'cualquier'){            
            state.current = '';
            city.current = '';
            delete queryObject.current.state;
            delete queryObject.current.city;
            
        }
        else{
            state.current = e.target.value;
            delete queryObject.current.city;
            city.current = '';
        }
                
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCities`;
        const response = await axios.post(url,{
            "state": e.target.value
        });
        setCities(response.data);
        handleQuery();
    }

    /**Cuando se elige una ciudad se invoca esta función */
    async function handleCity(e) {
        if(e.target.value === 'cualquier'){
            city.current = '';
            delete queryObject.current.city;
        }
        else{
            city.current = e.target.value;
        }
        
        handleQuery();  
    }

    /**Cuando se hace un submit */
    const handleSubmit = (e) => {
        e.preventDefault();
        handleQuery();
    }

    function handleQuery(){
        
        if(name!==''){
            queryObject.current.text = name;
        }
        else{   
            delete queryObject.current.text;
        }

        if(ocupation.current!=='' && ocupation.current!=='cualquier'){
            queryObject.current.role = ocupation.current;
        }
        else{
            delete queryObject.current.role;
        }

        if(country.current!==''){
            queryObject.current.country = country.current;
        }
        else{
            delete queryObject.current.country;
            delete queryObject.current.state;
            delete queryObject.current.city;
        }

        if(state.current!==''){
            queryObject.current.state = state.current;
        }
        else{
            delete queryObject.current.state;            
            delete queryObject.current.city;            
        }

        if(city.current!==''){
            queryObject.current.city = city.current;
        }
        else{  
            delete queryObject.current.city;
        }
        props.onSubmitCallback(queryObject.current);
    }

    /**Cuando se elige un departamento se invoca esta función */
    async function updateRoles (event){
        if(event.target.value === 'cualquier'){
            //role='';
            ocupation.current = '';
            handleQuery();
        }
        
        /**No lleva else porque el back sabe cómo devolver los valores */
        const url = `${process.env.REACT_APP_API_LOCATION}/crew/getRolesFromDepartment`;
        const response = await axios.post(url,{
            "category": event.target.value
        });       
        setRoles(response.data.roles);

        
    };    

    const loadDepartments = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/crew/getDepartments`;
        const response = await axios.get(url);
        setDepartments(response.data);
    }, []);

    const loadCountries = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCountries`;
        const response = await axios.post(url);
        setCountries(response.data);
    }, []);

    useEffect(() => {
        loadDepartments();
        loadCountries();
    }, [loadDepartments, loadCountries]);


    return (
    <div>
        <div className="sticky-top background-verylightgray rounded  p-3">
            <form ref={selectForm} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col"><span className="mdi mdi-tune"></span>&nbsp;Filtrar búsqueda<span className="mdi mdi-plus btn-fade" id="btn-fade"></span><span className="mdi mdi-minus btn-fade" id="btn-hide"></span></div>
            </div>
		    <div className="sec-fade" id="sec-fade">
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col agreements"><strong>Nombre</strong></div>
                </div>
                <div className="row">
                    <div className="col agreements">
                        <input 
                            type="text" 
                            className="form-control" 
                            name="text" 
                            placeholder="Nombre o apellido"
                            value={name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="oc" className="agreements"><strong>Departamento</strong> </label>
                        <select 
                            name="category" 
                            className="form-control agreements" 
                            onChange={(e) => updateRoles(e)}
                        >
                            <option value="cualquier"> Cualquier departamento </option>
                            {departments.map((item, index) => (
                                <option key={index} value={item.category}>{item.category}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label className="agreements" htmlFor="ocupation"><strong>Ocupación</strong> </label>
                        <select 
                            name="role" 
                            className="form-control agreements"
                            onChange={(e) => handleRole(e)}
                        >
                            <option value="cualquier"> Cualquier ocupación </option>
                            {roles.map((item, index) => (
                                <option key={index} value={item.roleName}>{item.roleName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col agreements"><strong>País</strong></div>
                </div>
                <div className="row">
                    <div className="col">
                        <select 
                            name="country" 
                            className="form-control agreements" 
                            onChange={(e) => updateStates(e)}
                        >
                            <option value="cualquier"> Cualquier país </option>
                            {countries.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col agreements"><strong>Estado</strong></div>
                </div>
                <div className="row">
                    <div className="col">
                        <select 
                            name="state" 
                            className="form-control agreements"
                            onChange={(e) => handleState(e)}
                        >
                            <option value="cualquier"> Cualquier estado </option>
                            {states.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col agreements"><strong>Ciudad</strong></div>
                </div>
                <div className="row">
                    <div className="col">
                        <select 
                            name="city" 
                            className="form-control agreements"
                            onChange={(e) => handleCity(e)}
                        >
                            <option value="cualquier"> Cualquier ciudad </option>
                            {cities.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                
            </div>
            </form>
          
        </div>
    </div>
  );
}

export default CrewFilters;