import React from 'react'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

const Title = styled.h1`
    font-family: 'Avenir-Black';
    font-size: 24px;
    margin-top: 3em;
    text-align: center;
    margin-bottom: 2em;
`

const TextBlock = styled.div`
    font-family: 'Avenir-Book';
    font-size: 18px;
    margin-top: 20px;
`

function success() {

const gotoHome = () => {
    window.location.href = '/'
}

  return (
    <>
    <Header/>   
        <Container>
            <Title>¡Felicidades por tu nueva membresía!</Title>
            <TextBlock>
                Queremos agradecerte por confiar en nosotros y por elegirnos para ser parte de tu viaje 
                hacia el logro de tus objetivos profesionales.
            </TextBlock>
            <TextBlock>
                Puedes tener la certeza de que tomaste una excelente decisión al invertir en tu futuro
            </TextBlock>
            <TextBlock>
                Esta membresía te brindará acceso a recursos exclusivos, herramientas poderosas y una comunidad exclusiva
            </TextBlock>
            <TextBlock>
                ¡Te damos la bienvenida a la familia Cinekomuna! Juntos, alcanzaremos nuevas alturas.
            </TextBlock>
            <div className='spacer20'></div>
            <div className='text-center'>
                <button className="btn-purlpe" onClick={gotoHome}>Ir a la página pricipal</button>
            </div>
            
            <div className='spacer40'></div>
        </Container>
    <Footer/>
    </>
  )
}

export default success