import {React, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import "./styles.scss";


function CastCover (props) {

    const person = props.person;
    const personId = person._id;
    const [coverArray, setCoverArray] = useState([]);
    const [url, setUrl] = useState("");


    let decoded = null;

    const navigate = useNavigate();

    function gotoUploadCover (){
        navigate('/media', {
            state: {
              source: 'castCover',
              id: person._id,
              currentGallery: coverArray
            }
        });
    }

    const hasEditPermission = () => {
        let result = false;
        if(person){
            if(localStorage.getItem("token")){
                decoded = jwtDecode(localStorage.getItem("token"));
                if (decoded._id === personId) {
                    result = true;
                } else {
                    result = false;
                }
            }
        }
        return result;
        
    }

    
    useEffect(() => {
        let tmpArray = [];
        let lastSegment, width, height = "";
        if(person && person.cast && person.cast.cover){
            if(person.cast.cover.url){
                setUrl(process.env.REACT_APP_PREFIX_FOR_IMAGES + "/" + person._id + "/" + person.cast.cover.url);
                lastSegment = person.cast.cover.url.substring(person.cast.cover.url.lastIndexOf('/') + 1);
            }
            if(person.cast.cover.width){
                width = person.cast.cover.width;
            }
            if(person.cast.cover.height){
                height = person.cast.cover.height;
            }

            tmpArray.push({
                url:lastSegment,
                width: width,
                height: height
            });
    
            setCoverArray(tmpArray);
        }
        
    }, [person]);
      

    if(!person){
        return <div>Cargando...</div>;
    }

    return(
        <div>
            {url &&
            <div 
                 className="container-fluid bg-overlay" 
                 style={{                    
                    background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${url})`
                  }}>
                <div className="spacer280"></div>
                <div className="spacer80"></div>
                {hasEditPermission() &&
                <button onClick={gotoUploadCover} className="text-white text-over no-border">
                    <span className="mdi mdi-camera"></span>
                </button>
                }
            </div>
            }
            {!url && 
            <div className="container-fluid bg-overlay-no-img">
                <div className="spacer280"></div>
                <a href="/" className="text-white text-over" id="openMediaGallery">
                    <span className="mdi mdi-camera"></span>
                </a>
            </div>
            }
        </div>
    )
}


export default CastCover;