import React from 'react';
import { Link } from "react-router-dom";

const NotFound = () =>{

    return(
        <div>
            <div className="spacer40"></div>
            <div className="container text-center">
                
                <>
                
                <h1>Nada por aquí</h1>
                <h2>Parece que la página que buscas no se encuentra</h2>
                <img src="/desert.jpg" alt="Desierto" className="img-fluid"/>
                <div className="spacer20"></div>
                <h2>¿Quieres ir a la <Link to={"/"}>página principal</Link>?</h2>
                </>
                
                <div className="spacer40"></div>
                
            </div>
        </div>
    )
}

export default NotFound;