import {React, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from 'react-toastify';
import axios from "axios";
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import FormTextareaInput from "../../common/formComponents/FormTextareaInput";
import FormLabel from "../../common/formComponents/FormLabel";
import FormButton from "../../common/formComponents/FormButton";
import FormValidationMessage from "../../common/formComponents/FormValidationMessage";
import FormTextInput from "../../common/formComponents/FormTextInput";
import FormSwitch from "../../common/formComponents/FormSwitch";
import  CrewCover  from "../CrewCover";
import { verifyVideoURL, verifyValidSite } from "../../common/formComponents/Validations";
import 'react-toastify/dist/ReactToastify.css';
import "./styles.scss";


function EditCrew(props) {

    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const maxLength = 1000;
    
    const[data, setData] = useState({
        _id:"",
        resume: "",
        twitter: "",
        instagram: "",
        facebook: "",
        youtube: "",
        vimeo:"",
        imdb: "",
        web: "",
        demo: "",
        invisible: false,
        selectedRoles: []
    });
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);    
    const [ocupation, setOcupation] = useState({});
    const [selectedToRemove, setSelectedToRemove] = useState([]);
    const [formError, setFormError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [invisibleCrew, setinvisibleCrew] = useState(true);
    const [toggleDangerZone, setToggleDangerZone] = useState(false);
    //const [error, setError] = useState('');

    const [person, setPerson] = useState({});
    const [coverArray, setCoverArray] = useState([]);

    const customOptionStyle = {
        "color": '#ED1567',
        "fontWeight": 'bold',
        "fontSize" : '12px',
      };


    /**Cuando se elige un departamento se invoca esta función */
    async function updateRoles (event){
        //console.log("Entrando a updateRoles");
        //console.log(event);
        if(event.value === 'cualquier'){
            setOcupation({});
        }
        
        let roleSet = [{ label: "Cualquier ocupación", value: "cualquier", department: "cualquier", idDepartment: "cualquier" }];
        
        /**No lleva else porque el back sabe cómo devolver los valores */
        const url = `${process.env.REACT_APP_API_LOCATION}/crew/getRolesFromDepartment`;
        const response = await axios.post(url,{
            "category": event.value
        });

        for(let i = 0; i < response.data.roles.length; i++){
            let roleObject = {
                label: response.data.roles[i].roleName,
                value: response.data.roles[i].roleId,
                department: event.label
            }
            roleSet.push(roleObject);
        }
        console.log(roleSet);
        setRoles(roleSet);
    };


    function gotoUploadCover (){
        navigate('/media', {
            state: {
              source: 'crewCover',
              id: person._id,
              currentGallery: coverArray
            }
        });
    }

    /**Cuando se elige un rol para agregar se invoca esta función */
    function handleRole(e) {
        const value = e.value;
        const label = e.label;
        const department = e.department;
        const intValue = parseInt(value,10);
        let obj = {
            value: intValue,
            label: label,
            department: department
        };

        //console.log(obj);

        setOcupation(obj);
    }

    function handleRightArrow(e){
        e.preventDefault();
        if(!submitted){
            //Busca dentro de sus roles si ya tiene el rol que se quiere agregar
            let obj = null;
            if(selectedRoles && selectedRoles.length > 0){
                obj = selectedRoles.find(o => o.value === ocupation.value);
                if(!obj){ //En caso de que no lo tenga se agrega
                    setSelectedRoles([...selectedRoles, ocupation]);
                }
                else{
                    toast.error("Ya habías agregado este rol", { autoClose: 2000 });
                        setTimeout(() => {
                        }, 1000);
                }
            }
            else{
                setSelectedRoles([ocupation]);
            }
            
            
        }
    }   

    function handleLeftArrow(e){
        e.preventDefault();
        if(!submitted){  
            let originalArray = selectedRoles;
            const newArray = originalArray.filter((element) => !selectedToRemove.includes(element.value));
            setSelectedRoles(newArray);
            setSelectedToRemove([]);    
        }
    }

    const handleChange = ({currentTarget: input}) => { 
        setData({...data, [input.name]: input.value});
    };

    const handleAboutChange = (event) => { 
        const inputText = event.target.value;
        // Verificar si el texto supera la longitud máxima
        if (inputText.length <= maxLength) {
            setData({...data, [event.target.name]: event.target.value});
        }
        
    };

    const handleSubmit = async (e) => {   
        e.preventDefault();
    
        setData({...data, visible: true});

        const error = validateForm();

        if(error){
            setFormError(error);
            toast.error(error.message, { autoClose: 2000 });
            //console.log("Form validation failed, therefore it did not submit");
            setTimeout(() => {
                setFormError(null);
            }, 5000);
            return;
        }

        console.log("Enviando los datos al servidor");
        setSubmitted(true); //descomentar esto para que no se pueda enviar el formulario más de una vez

        //Preparación de los datos que se van a guardar
        data._id = person._id;
        data.selectedRoles = selectedRoles;
        
        //data.selectedRoles = selectedRoles.map(a => a.value);
        //console.log(selectedRoles);
        //console.log(data);
        
        try {            
            const url = `${process.env.REACT_APP_API_LOCATION}/crew/updateProfile`;
            await axios.put(url, data)
            .then((response) => {
                //console.log(response.data);
                toast.success("Tu perfil de crew ha sido actualizado", { autoClose: 2000 });
                setTimeout(() => {
                    navigate('/crew/'+person._id);
                }, 1000);
            })
            .catch((error) => {
                showBoundary(error);
                console.error('Error al actualizar el perfil:', error);
            });
        } 
        catch (error) {
            console.log(error);
            showBoundary(error);
        }
    };


    const validateForm = () => {
        let error = false;
        //console.log("Validating form");

        if(data.resume==='' || data.resume===null || data.resume===undefined){
            error = {
                field_name: 'resume',
                message: 'La descripción no puede estar vacía'
            }        
            return error;    
        }

        if(selectedRoles && selectedRoles.length <= 0){
            error = {
                field_name: 'selectedRoles',
                message: 'Debes agregar al menos un rol'
            }        
            return error;
        }

        if(data.demo && data.demo !== ''){
            const videoURL = verifyVideoURL(data.demo);
            if(!videoURL){
                error = {
                    field_name: 'demo',
                    message: 'La URL del demo no es válida, revisa que sea una URL de youtube o vimeo'
                }        
                return error;
            }
            else{
                //console.log("Video URL: " + videoURL);
                setData({...data, demo: videoURL});
            }
        }

        if(data.demo && data.demo !== ''){
            const videoURL = verifyVideoURL(data.demo);
            if(!videoURL){
                error = {
                    field_name: 'demo',
                    message: 'La URL del demo no es válida, revisa que sea una URL de youtube o vimeo'
                }        
                return error;
            }
            else{
                setData({...data, demo: videoURL});
            }
        }

        if(data.facebook && data.facebook !== ''){
            const valid = verifyValidSite(data.facebook, "facebook");
            if(!valid){
                error = {
                    field_name: 'facebook',
                    message: 'La URL de facebook no es válida'
                }        
                return error;
            }
        }

        if(data.twitter && data.twitter !== ''){
            const valid = verifyValidSite(data.twitter, "twitter");
            if(!valid){
                error = {
                    field_name: 'twitter',
                    message: 'La URL de twitter no es válida'
                }        
                return error;
            }
        }

        if(data.vimeo && data.vimeo !== ''){
            const valid = verifyValidSite(data.vimeo, "vimeo");
            if(!valid){
                error = {
                    field_name: 'vimeo',
                    message: 'La URL de vimeo no es válida'
                }        
                return error;
            }
        }

        if(data.youtube && data.youtube !== ''){
            const valid = verifyValidSite(data.youtube, "youtube");
            if(!valid){
                error = {
                    field_name: 'youtube',
                    message: 'La URL de youtube no es válida'
                }        
                return error;
            }
        }

        if(data.instagram && data.instagram !== ''){
            const valid = verifyValidSite(data.instagram, "instagram");
            if(!valid){
                error = {
                    field_name: 'instagram',
                    message: 'La URL de instagram no es válida'
                }        
                return error;
            }
        }

        if(data.imdb && data.imdb !== ''){
            const valid = verifyValidSite(data.imdb, "imdb");
            if(!valid){
                error = {
                    field_name: 'imdb',
                    message: 'La URL de imdb no es válida'
                }        
                return error;
            }
        }

        return error;
    };


    const switchCrew = async (e) => {
        e.preventDefault();
        let text = "";
        if(invisibleCrew){
            text = "¿Deseas activar tu perfil de crew?";
        }
        else{
            text = "¿Deseas ocultar momentáneamente tu perfil de crew?";
        }
    
        if(window.confirm(text)){
            
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/crew/switchCrew`;
                await axios.put(url, {
                    _id: person._id,
                    invisible: !invisibleCrew
                })
                .then((response) => {
                    let msg = "";
                    if(invisibleCrew){
                        msg = "Tu perfil de crew ha sido activado";
                    }
                    else{
                        msg = "Tu perfil de crew ha sido desactivado";
                    }
                    setinvisibleCrew(!invisibleCrew);
                    toast.success(msg, { autoClose: 2000 });
                    /*
                    setTimeout(() => {
                        navigate('/crew/'+person._id);
                    }, 1000);*/
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al activar/desactivar el perfil:', error);
                });
            } 
            catch (error) {
                console.log(error);
                showBoundary(error);
            }
        }
    }

    const deleteCrew = async (e) => {
        e.preventDefault();
        let text = "¿Deseas eliminar tu perfil de crew? Esta acción no se puede deshacer";
    
        if(window.confirm(text)){
            
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/crew/deleteCrew`;
                await axios.put(url, {
                    _id: person._id
                })
                .then((response) => {
                    let msg = "";
                    msg = "Tu perfil de crew ha sido eliminado";
                    toast.success(msg, { autoClose: 2000 });
                    
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al eliminar el perfil:', error);
                });
            } 
            catch (error) {
                console.log(error);
                showBoundary(error);
            }
        }
    }




/*    function gotoUploadWidget (){
        navigate('/uploadWidget', {
            state: {
              source: 'editProfile'
            }
        });
    }*/

    function handleDangerZoneToggle () {
        setToggleDangerZone(!toggleDangerZone)
    }
  
    /**Cuando se elige un rol para remover se invoca esta función */
    function handleRemoveRole(e) {
        let options = document.getElementById('selectedRoles').selectedOptions;
        let values = Array.from(options).map(({ value }) => parseInt(value,10));
        setSelectedToRemove(values);
    }

    const gotoProfile = () => {
        navigate('/crew/'+person._id);
    };

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        let decoded = jwt_decode(localStorage.getItem("token"));
        const userId = decoded._id;

        if(userId){
            axios.post(`${process.env.REACT_APP_API_LOCATION}/user/getPerson`,{
                userId: userId,
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                setPerson(response.data);
                console.log(response.data);
                let currentInfo = {
                    _id: response.data._id,
                    resume: response.data.resume,
                    arrayOfRoles: response.data.arrayOfRoles,
                    facebook: response.data.facebook,
                    instagram: response.data.instagram,
                    twitter: response.data.twitter,
                    youtube: response.data.youtube,
                    vimeo: response.data.vimeo,
                    linkedin: response.data.linkedin,
                    imdb: response.data.imdb,
                    web: response.data.web,
                    demo: response.data.demo,
                    crew: response.data.crew
                }

                if(response.data.arrayOfRoles && response.data.arrayOfRoles.length > 0){
                    currentInfo.currentRoles = response.data.arrayOfRoles.map((item, index) => {return {value: item.roleId, label: item.name}})
                }

                setData(currentInfo);
                //console.log(currentInfo);
                setSelectedRoles(currentInfo.currentRoles);
                const crew = response.data.crew;
                setinvisibleCrew(crew.invisible);

                let tmpArray = [];

                let lastSegment, width, height = "";
                if(crew && crew.cover){
                    if(crew.cover.url){
                        lastSegment = crew.cover.url.substring(crew.cover.url.lastIndexOf('/') + 1);
                    }
                    if(crew.cover.width){
                        width = crew.cover.width;
                    }
                    if(crew.cover.height){
                        height = crew.cover.height;
                    }
                    
                }

                tmpArray.push({
                    url:lastSegment,
                    width: width,
                    height: height
                });
                setCoverArray(tmpArray);

            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los datos del usuario:', error);
                }
            });

            axios.get(`${process.env.REACT_APP_API_LOCATION}/crew/getDepartments`,{
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                let departmentSet = [{ label: "Cualquier departamento", value: "cualquier" }];
                for(let i = 0; i < response.data.length; i++){
                    let departmentObject = {
                        label: response.data[i].category,
                        value: response.data[i].category
                    }
                    departmentSet.push(departmentObject);
                }
                setDepartments(departmentSet);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los departamentos:', error);
                }
            });
        }

        return () => {
            console.log("cleanup");           
        }

    }, [showBoundary]);

    if(!person){
        return <div>Cargando...</div>
    }


    return (
        <>
        <div className="container">
            <form onSubmit={handleSubmit}>
            <div className='row spaced'>
                <div className="col-lg-3">
                    <FormButton submit={false} onClick={gotoProfile} className="btn btn-options">Ver crew</FormButton>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-12">
                            <h2 className="titles">Perfil de Crew</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-12">
                        {invisibleCrew && person.isCrew &&
                            <FormSwitch
                                onChange={switchCrew}
                                disabled={submitted ? "disabled":"" }
                                label="Activar perfil"
                                status={false}
                            />
                        }
                        {!invisibleCrew && person.isCrew &&
                            <FormSwitch
                                onChange={switchCrew}
                                disabled={submitted ? "disabled":"" }
                                label="Desactivar perfil"
                                status={true}
                            />
                        }
                        </div>
                        
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col">
                            Foto de portada &nbsp;
                            {person.isCrew &&
                            <FormButton type="button" className="btn btn-purple" onClick={gotoUploadCover}>Cambiar <span className="mdi mdi-account-edit"/></FormButton>
                            }
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col">
                            <CrewCover person={data}/>
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    
                    <div className="row">
                        <div className="col form-group">
                            <FormLabel htmlFor="resumen" required={true}>Acerca de</FormLabel>                            
                            <FormTextareaInput
                                rows="5" 
                                placeholder="Cuéntanos de ti (1000 caracteres máx)." 
                                maxLength={maxLength}
                                name="resume"
                                defaultValue={(data && data.resume) ? data.resume:''}
                                onChange={handleAboutChange}      
                                disabled={submitted ? "disabled":"" }
                            />
                            {data && data.resume &&
                            <small>Caracteres restantes: {maxLength - data.resume.length}/{maxLength}</small>
                            }
                            {data && !data.resume &&
                            <small>Caracteres restantes: {maxLength}/{maxLength}</small>
                            }
                            {formError && formError.field_name === 'resume' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}     
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col">
                            Elige tu rol principal y los demás roles que desempeñas dentro de la industria. 
                            (El primero que elijas será tu rol principal).
                        </div>
                    </div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col-5 border">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="oc" className="agreements"><strong>Departamento</strong> </label>
                                    <Select
                                        name="category"
                                        defaultValue={{ label: "Cualquier departamento", value: "cualquier" }}
                                        options={departments}
                                        className="agreements"
                                        onChange={(e) => updateRoles(e)}                                            
                                        isDisabled={submitted ? "disabled":"" }                                        
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="agreements" htmlFor="ocupation"><strong>Ocupación</strong> </label>
                                    <Select
                                        name="role"
                                        defaultValue={{ label: "Cualquier ocupación", value: "cualquier" }}
                                        options={roles}
                                        className="agreements"
                                        onChange={(e) => handleRole(e)} 
                                        isDisabled={submitted ? "disabled":"" }
                                    />
                                    
                                </div>
                            </div>
                            <div className="spacer20"></div>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-center">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className={submitted ? "mdi mdi-arrow-right-bold big-size arrow-disabled":"mdi mdi-arrow-right-bold big-size"} onClick={handleRightArrow}></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={submitted ? "mdi mdi-arrow-left-bold big-size arrow-disabled":"mdi mdi-arrow-left-bold big-size"} onClick={handleLeftArrow}></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                        <div className="col-5 border">
                        <label htmlFor="oc" className="agreements"><strong>Roles que has seleccionado</strong> <span className="text-purple">(Requerido)</span></label>
                            <select 
                                name="selectedRoles" 
                                className="form-control agreements"
                                id="selectedRoles"                                
                                multiple
                                onClick={(e) => handleRemoveRole(e)}
                                disabled={submitted ? "disabled":"" }
                            >                                
                                {selectedRoles && selectedRoles.map((item, index) => (
                                    <option key={index} value={item.value} style={index===0 ? customOptionStyle: null }>{item.label}</option>
                                ))}

                            </select>
                            <p className="agreements"><strong>Este es tu rol principal:</strong></p>
                            {selectedRoles && selectedRoles.length > 0 &&
                                <p style={customOptionStyle} >{selectedRoles[0].label}</p>
                            }
                            {formError && formError.field_name === 'selectedRoles' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}     
                        </div>
                    </div>
                    
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col">
                            <h2 className="titles">En internet</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="web" required={false}>Página Web</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-web"></span></div>
                            </div>
                            <FormTextInput 
                                name="web"
                                onChange={handleChange}
                                placeholder="Ej: www.cinekomuna.com"
                                maxLength="120"
                                value={data && data.web ? data.web:''}
                                disabled={submitted ? "disabled":"" }
                            />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="demo" required={false}>Demo reel</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-play"></span></div>
                            </div>
                            <FormTextInput
                                name="demo"
                                onChange={handleChange}
                                placeholder="URL de un video de youtube o vimeo"
                                maxLength="150"
                                value={data && data.demo ? data.demo:''}
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'demo' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="facebook" required={false}>Facebook</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-facebook"></span></div>
                            </div>
                            <FormTextInput 
                                name="facebook" 
                                onChange={handleChange} 
                                placeholder="Ej. www.facebook.com/cinekomuna" 
                                maxLength="120"
                                value={data && data.facebook ? data.facebook:''}
                                disabled={submitted ? "disabled":"" }
                            />                               
                            </div>
                            {formError && formError.field_name === 'facebook' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="twitter" required={false}>Twitter</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-twitter"></span></div>
                            </div>
                            <FormTextInput
                                name="twitter"
                                onChange={handleChange}
                                placeholder="Ej. www.twitter.com/cinekomuna"
                                maxLength="120"
                                value={data && data.twitter ? data.twitter:''}
                                disabled={submitted ? "disabled":"" }
                            />         
                            </div>
                            {formError && formError.field_name === 'twitter' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}                   
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="vimeo" required={false}>Vimeo</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-vimeo"></span></div>
                            </div>
                            <FormTextInput
                                name="vimeo"
                                onChange={handleChange}
                                placeholder="Ej. www.vimeo.com/cinekomuna"
                                maxLength="120"
                                value={data && data.vimeo ? data.vimeo:''}
                                disabled={submitted ? "disabled":"" }
                            />
                            
                            </div>
                            {formError && formError.field_name === 'vimeo' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="youtube" required={false}>Youtube</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-youtube"></span></div>
                            </div>
                            <FormTextInput
                                name="youtube"
                                onChange={handleChange}
                                placeholder="Ej. www.youtube.com/cinekomuna"
                                maxLength="120"
                                value={data && data.youtube ? data.youtube:''}
                                disabled={submitted ? "disabled":"" }
                            />
                            </div>
                            {formError && formError.field_name === 'youtube' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="instagram" required={false}>Instagram</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-instagram"></span></div>
                            </div>
                            <FormTextInput
                                name="instagram"
                                onChange={handleChange}
                                placeholder="Ej. www.instagram.com/cinekomuna"
                                maxLength="120"
                                value={data && data.instagram ? data.instagram:''}
                                disabled={submitted ? "disabled":"" }
                            />
                            </div>
                            {formError && formError.field_name === 'instagram' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="imdb" required={false}>IMDB</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><img src="/imdb.png" className="icon-size" alt="imdb icon"/></div>
                            </div>
                            <FormTextInput
                                name="imdb"
                                onChange={handleChange}
                                placeholder="Ej. www.imdb.com/cinekomuna"
                                maxLength="120"
                                value={data && data.imdb ? data.imdb:''}
                                disabled={submitted ? "disabled":"" }
                            />
                            </div>
                            {formError && formError.field_name === 'imdb' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                        
                            
                    
                </div>
            </div>  

            <div className="spacer10"></div>
            <div className="row">
                <div className="col-lg-2 col-md-6">
                    <FormButton submit={true} className="btn btn-purple" disabled={submitted ? "disabled":"" }> 
                        Guardar
                    </FormButton>
                </div>                
            </div>
            {false &&
                <>
            <div className="spacer40"></div>
            {person.isCrew &&
            <div className="row">
                <div className="col-12 danger-zone">                    
                    <span 
                        className={!toggleDangerZone ? 'mdi mdi-arrow-right cursor' : 'mdi mdi-arrow-down cursor'} 
                        data-toggle="collapse" 
                        data-target="#collapseExample" 
                        aria-expanded="false" 
                        aria-controls="collapseExample" 
                        onClick={handleDangerZoneToggle}
                    >&nbsp;Mostar zona de riesgo</span>
                    <div className="collapse uppercase" id="collapseExample">
                        <div className="spacer10"></div>
                        <p className="text-danger">¡Cuidado! Estas acciones no se pueden deshacer</p>
                        <div className="spacer10"></div>
                        <FormButton submit={false} onClick={deleteCrew} className="btn btn-purple" disabled={submitted ? "disabled":"" }> 
                            Eliminar perfil de Crew
                        </FormButton>
                    </div>
                </div>
            </div>
            }
            </>
            }
            <div className="spacer40"></div>
            </form>
        </div>
        </>
    )
}

export default EditCrew;