import {React, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
require('./home.styles.css');

function ProjectHighlighted(props) {
    
    const [projects, setProjects] = useState([]);

    useEffect( () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/project/getHighlighted`;
        axios.get(url)
        .then((response) => {
            setProjects(response.data);
        });
    }, []);

    if(!projects) {
        return <div>Cargando...</div>;
    }

    return (
    <div className="container">            
        <div className="spacer80"></div>
        <div className='row'>
            <div className='col'>
                <strong className="uppercase">Proyectos destacados</strong>
                <hr/>
            </div>
        </div>
        
        <div className='row'>
        
        {
            projects.map((project, index) => {
                return (
                    
                    <div className='col-lg-3 col-md-4 col-sm-6 mx-auto text-center' key={index}>
                        <div className="spacer20"></div>
                            <Link to={`/project/${project._id}`} className="card-img-top link-black"><img className="card-img-top w-100" src={project.cover.url} alt="Foto de portada"/></Link>
                            
                            
                            
                        <div className="title p-2">
                            <Link to={`/project/${project._id}`} className="card-img-top link-black">{project.project_title}</Link>
                        </div>
                        <div className="agreements mx-auto">
                            <strong>
                                {project.fullname}<br/> 
                            </strong>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col"><span className="mdi mdi-eye"></span><small>&nbsp;
                                {project.views}
                                    </small>
                                    </div>
                                <div className="col"><span className="mdi mdi-thumb-up"></span><small>&nbsp;
                                {project.likes}
                                </small>
                                </div>
                            </div>
                        </div>
                    </div>    
                    
                )
            })
        }

        </div>
    </div>

    );
}

export default ProjectHighlighted;