//The context is going to be used to store globally the user data, so we can access it from any component without having to pass it as a prop.
import React, {createContext, useState, useEffect} from "react";
import axios from "axios";

//createContext creates a context with the default values of the interface
const UserContext = createContext([
    {
        data: null,
        error: null,
        loading: true
    },//After the default values the second element is a function that does nothing, but allows to change the values of the context
    () => {}
]);


//The provider is a component that wraps the rest of the components and allows them to access the context
const UserProvider = ({children}) => {
    console.log("UserProvider in Context");

    const [user, setUser] = useState({
        data: null,
        error: null,
        loading: true
    });

    const token = localStorage.getItem('token');

    if(token){
        //Set the token in the headers of axios, so it is sent in every request
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    const fetchUser = async () => {
        try {

            const {data:response} = await axios.get(`${process.env.REACT_APP_API_LOCATION}/auth/me`);

            console.log(response);

            console.log(response, "UserContext fetchUser");
            //If there are errors, set the error in the state
            if (response.data && response.data.errors && response.data.errors.length){
                setUser({
                    data: null,
                    error: response.data.errors[0].msg,
                    loading: false
                });
            }
            //If there is user data, set the user in the state
            else if(response.data && response.data.user){
                setUser({
                    data: {
                        _id: response.data.user._id,
                        email: response.data.user.email,
                        name: response.data.user.name,
                        resume: response.data.user.resume,
                        cast: response.data.user.cast,
                        crew: response.data.user.crew,
                        lastname: response.data.user.lastname,
                        fullname: response.data.user.fullname,
                        photo: response.data.user.photo,
                        role: response.data.user.role,
                        isCrew: response.data.user.isCrew,
                        isCast: response.data.user.isCast,
                        stripeCustomerId: response.data.user.stripeCustomerId,
                        likesProject: response.data.user.likesProject,
                        plan: response.data.user.plan,
                    },
                    error: null,
                    loading: false
                });
            }
            //If there is no user data, set the state to null
            else {
                setUser({
                    data: null,
                    error: null,
                    loading: false
                });
            }
        } catch (error) {
            setUser({
                data: null,
                error: error.response.data.errors[0].msg,
                loading: false
            });
            
            if(error.response.data.errors[0].msg === "Token expirado, autorización denegada"){
                console.log("Token expired, removing token from localStorage");
                localStorage.removeItem('token');
                console.log("Token removed from localStorage");
            }

           
        }
        
    };

    useEffect(() => {
        //Call fetch only if token exists, otherwise there is nonsense
        //console.log("token in UserProvider", token);
        if(token){
            fetchUser();
        }
        else{
            setUser({
                data: null,
                error: null,
                loading: false
            });
        }
    }, [token]);

    return <UserContext.Provider value={[user, setUser]}>
        {children}
    </UserContext.Provider>;
};

export {UserContext, UserProvider};