import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../context";
import Header from '../common/Header';
import Footer from '../common/Footer';
import ProjectCover from './ProjectCover';
import ProjectMeta from './ProjectMeta';
import ProjectBody from "./ProjectBody";
//import SideBar from "./SideBar";
import axios from "axios";
//import jwtDecode from "jwt-decode";
import NotFound from "../../NotFound";
import { Button } from "react-bootstrap";


function ProjectPage(props) {
    const { showBoundary } = useErrorBoundary();

    const navigate = useNavigate();
    const [project, setProject] = useState({});
    const [views , setViews] = useState(0);
    const [likes , setLikes] = useState(0);
    const [showProject, setShowProject] = useState(false);

    const [state] = useContext(UserContext);

    //console.log(state.data);
    let projectId = null;
    const location = useLocation();
    if(location) {
        projectId = location.pathname.slice(location.pathname.lastIndexOf("/")+1 , location.pathname.length);
    }

  //  const [sidebarOpen, setSideBarOpen] = useState(false);

    /*const handleViewSidebar = () => {
        setSideBarOpen(!sidebarOpen);
    };*/

    //let decoded = useRef(null);

    /*const hasEditPermission = () => {
        let result = false;
        if(localStorage.getItem("token")){
            decoded = jwtDecode(localStorage.getItem("token"));
            if (decoded._id === project.userId) {
                result = true;
            } else {
                result = false;
            }
        }
        return result;
        
    }*/

    const editProject = () => {
        navigate(`/projectForm/${project._id}`);
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        
        if(projectId){
            axios.get(`${process.env.REACT_APP_API_LOCATION}/project/getProject/${projectId}`, { 
                cancelToken: cancelToken.token 
            })
            .then((response) => {

                if(!response.data.invisible){
                    setShowProject(true);
                    console.log("El proyecto está visible")
                    //console.log(response.data);
                    setProject(response.data);
                    setViews(response.data.views);
                    setLikes(response.data.likes);
                    axios.put(`${process.env.REACT_APP_API_LOCATION}/project/addView`, {
                        projectId: projectId
                    });
                    
                }
                else if(response.data.invisible){
                    console.log("El proyecto está invisible");
                    console.log(state.data);
                    if(state && state.data && state.data.user && state.data.user._id === response.data.userId){
                        setShowProject(true);
                        console.log("El usuario es dueño del proyecto");
                        setProject(response.data);
                        setViews(response.data.views);
                        setLikes(response.data.likes);
                        axios.put(`${process.env.REACT_APP_API_LOCATION}/project/addView`, {
                            projectId: projectId
                        });
                        setShowProject(true);
                    }
                    else{
                        console.log("El usuario no es dueño del proyecto");
                        //navigate('/notfound');
                        setShowProject(false);
                    }
                    
                }
                else{
                    console.log("El proyecto no existe o no está visible o el usuario no tiene permisos para verlo");
                    //navigate('/notfound');
                    setShowProject(false);
                }
            })
            .catch((error) => {
                console.log(error);
                if(error.response.request.status===404){
                    navigate('/notfound');
                }
            });
        }

        return () => {
            cancelToken.cancel();
        }

    }, [project._id, projectId, showBoundary, navigate, state]);

   

    

    if(!project){
        return <div>Cargando ...</div>;
    }

    if(!showProject){
        setTimeout(() => {
            <>
                <Header/>
                    <div className="container">
                        <div className="spacer120"></div>
                        <div className="row">
                            <div className="col-12">
                                <p>El proyecto que estás buscando no existe.</p>
                                <p className="mb-0">Por favor, verifica que la URL sea correcta.</p>
                            </div>
                        </div>
                        <div className="spacer120"></div>
                    </div>
                <Footer/>   
                </>
        }, 5000);
    }

    return(
        
        <div>
            <Header/>
                <>
                {state && state.data && state.data.user && state.data.user._id && project.userId === state.data.user._id &&
                <Button className="fixed-button btn-purple" onClick={editProject}>Editar proyecto</Button>
                }
                <ProjectCover cover={project.cover} projectId={project._id} owner={project.userId}/>                 
                <ProjectMeta likes={likes} views={views}/>
                <ProjectBody project={project}/>

                </>
            <Footer/>
            
        </div>
    )
}




export default ProjectPage;

