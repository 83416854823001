import React from 'react';
import ProjectHighlighted from '../home/ProjectHighlighted';

const ErrorHandler = (props) => {
    return(
        <div>
            <div className="spacer40"></div>
            <div className="container text-center">
                {props.code===404 &&
                <>
                <h1>{props.title} no encontrado</h1>
                <h2>{props.code}</h2>
                <img src="/desert.jpg" alt="Desierto" className="img-fluid"/>
                <div className="spacer40"></div>
                <ProjectHighlighted/>
                </>
                }
                {props.code===500 &&
                <>
                <h1>Se cayó el servidor</h1>
                <h2>{props.code}</h2>
                <img src="/desert.jpg" alt="Desierto" className="img-fluid"/>
                <div className="spacer40"></div>
                </>
                }
                {props.code>=401 && props.code<404 &&
                <>
                <h1>Bueno, esto es embarazoso, ha ocurrido un error</h1>
                <h2>{props.code}</h2>
                <img src="/desert.jpg" alt="Desierto" className="img-fluid"/>
                <div className="spacer40"></div>
                </>
                }
                <div className="spacer40"></div>
            </div>
            
        </div>
    )
}

export default ErrorHandler;