import {React, useEffect, useState} from 'react';
import { useErrorBoundary } from "react-error-boundary";
import axios from 'axios';
import config from '../../config';

function CastFilmography(props) {
    const { showBoundary } = useErrorBoundary();

    const {personId} = props;
    const [filmography, setFilmography] = useState([]);    

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        
        if(personId){

            axios.get(`${process.env.REACT_APP_API_LOCATION}/cast/getFilmography/${personId}`, {
                cancelToken: cancelToken.token,
            })
            .then((response) => {            
                setFilmography(response.data);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener la filmografía del usuario:', error);
                }
            });
        }

        //Clean up function
        return () => {
            cancelToken.cancel();
        }


    }, [personId, showBoundary]);

    if(!filmography){
        return <div>Cargando...</div>;
    }

    return (
        <>
        {filmography && filmography.length > 0 &&
            <div>
                <div className="row">
                    <div className="col">
                        <div className="spacer40 d-lg-none"></div>
                        <strong className="uppercase">Filmografía</strong>
                        <hr/>
                    </div>
                </div>
                {filmography.map((project, index) => (
                <div className="row" key={index}>
                    <div className="col"><a href={"/project/"+project._id} className="about text-black">{project.project_year} - {project.project_title}</a></div>
                </div>
                ))}
                <div className="spacer40"></div>
            </div>
        }
        </>
    )
}

export default CastFilmography;