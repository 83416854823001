import React from 'react';
import CastAbout from './CastAbout';
import CastFilmography from './CastFilmography';
import CastPersonalInfo from './CastPersonalInfo';
import CastFollowers from './CastFollowers';
import CastFollowing from './CastFollowing';

function CastSidebar(props) {
    const { person } = props;
    return (
        <div>
            <CastAbout person={person}/>
            <div className="spacer20"></div>    
            <CastFilmography personId={person._id}/>        
            <div className="spacer20"></div>
            <CastPersonalInfo person={person}/>
            <div className="spacer20"></div>
            <CastFollowers personId={person._id} name={person.fullname}/>
            <div className="spacer20"></div>
            <CastFollowing personId={person._id} name={person.fullname} type="crew"/>
            <div className="spacer20"></div>
            <CastFollowing personId={person._id} name={person.fullname} type="cast"/>
            {
            /*
            <IndustryIFollow/>
            */
}
        </div>
    )
}

export default CastSidebar;