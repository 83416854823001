import {React, useState, useEffect, useContext} from 'react'
import { useLocation } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import FormLabel from '../common/formComponents/FormLabel';
import FormSelect from '../common/formComponents/FormSelect';
import FormTextareaInput from '../common/formComponents/FormTextareaInput';
import FormTextInput from '../common/formComponents/FormTextInput';
import FormSwitch from '../common/formComponents/FormSwitch';
import FormButton from '../common/formComponents/FormButton';
import ProjectGallery from './ProjectGallery';
import ProjectCover from './ProjectCover';
import FormValidationMessage from '../common/formComponents/FormValidationMessage';
import { verifyVideoURL, verifyValidSite, verifyValidURL } from "../common/formComponents/Validations";
import Header from '../common/Header';
import Footer from '../common/Footer';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import jwt_decode from "jwt-decode";
import { UserContext } from "../../context";


function ProjectForm() {
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate();

    const initialState = {
        _id: '',
        creator: '',
        project_title: '',       
        project_desc: '',
        project_type: '',
        project_status: '',
        project_year: '',
        project_genre: '',
        project_role: [],
        project_web_page: '',
        project_facebook_page: '',
        project_twitter_page: '',
        proj_vimeo_page: '',
        proj_youtube_page: '',
        proj_instagram_page: '',        
        proj_imdb_page: '',
        project_demo: '',
        cover: ''
    }

    //const [data] = useState([]);
    
    const [state] = useContext(UserContext);
    console.log(state.data,"state.data");

    const [project] = useState({});
    const [years, setYears] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [ocupation, setOcupation] = useState({});
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedToRemove, setSelectedToRemove] = useState([]);
    const [toggleDangerZone, setToggleDangerZone] = useState(false);
    const [formError, setFormError] = useState(null);
    const [invisible, setInvisible] = useState(false);
    const [projectIsMain, setProjectIsMain] = useState(false);
    const [enable, setEnable] = useState(false);

    const [formData, setFormData] = useState(initialState);

    const [projectData, setProjectData] = useState({ project_title: '', project_year: '' });

    const [PLAN, setPLAN] = useState("FREE");

    let projectId = null;
    const location = useLocation();
    if(location) {
        projectId = location.pathname.slice(location.pathname.lastIndexOf("/")+1 , location.pathname.length);
    }

    const gotoProject = () => {
        navigate('/project/'+projectId);
    };

    const gotoProfile = () => {
        navigate('/editProfile');
    };

    const handleChange = (e) => {
        setFormError(null);
        setFormData({...formData, [e.target.name]: e.target.value});
    };


    function handleDangerZoneToggle () {
        setToggleDangerZone(!toggleDangerZone)
    }

    function gotoUploadGallery (){
        navigate('/media', {
            state: {
              source: 'projectGallery',
              id: projectId,
              currentGallery: project.gallery
            }
        });
    }

    /**Cuando se elige un departamento se invoca esta función */
    async function updateRoles (event){
        if(event.value === 'cualquier'){
            setOcupation({});
        }
        
        let roleSet = [{ label: "Cualquier ocupación", value: "cualquier", department: "cualquier", idDepartment: "cualquier" }];
        
        /**No lleva else porque el back sabe cómo devolver los valores */
        const url = `${process.env.REACT_APP_API_LOCATION}/crew/getRolesFromDepartment`;
        const response = await axios.post(url,{
            "category": event.value
        });

        for(let i = 0; i < response.data.roles.length; i++){
            let roleObject = {
                label: response.data.roles[i].roleName,
                value: response.data.roles[i].roleId,
                department: event.label
            }
            roleSet.push(roleObject);
        }
        //console.log(roleSet);
        setRoles(roleSet);
    };

    /**Cuando se elige un rol para agregar se invoca esta función */
    function handleRole(e) {
        const value = e.value;
        const label = e.label;
        const department = e.department;
        const intValue = parseInt(value,10);
        let obj = {
            value: intValue,
            label: label,
            department: department
        };

        //console.log(obj);

        setOcupation(obj);
    }

    function handleRightArrow(e){
        e.preventDefault();
        if(!submitted){
            //Busca dentro de sus roles si ya tiene el rol que se quiere agregar
            let obj = null;
            if(selectedRoles && selectedRoles.length > 0){
                obj = selectedRoles.find(o => o.value === ocupation.value);
                if(!obj){ //En caso de que no lo tenga se agrega
                    setSelectedRoles([...selectedRoles, ocupation]);
                }                
            }
            else{
                setSelectedRoles([ocupation]);
                let roles = [];
                selectedRoles.map((item, index) => {
                    roles.push(item.label);
                    return roles;
                });
                setProjectData({...projectData, project_role: roles});
            }
            
            
        }
    }   

    function handleLeftArrow(e){
        e.preventDefault();
        if(!submitted){  
            let originalArray = selectedRoles;
            const newArray = originalArray.filter((element) => !selectedToRemove.includes(element.value));
            setSelectedRoles(newArray);
            setSelectedToRemove([]);   
            
            let roles = [];
            selectedRoles.map((item, index) => {
                roles.push(item.label);
                return roles;
            });
            setProjectData({...projectData, project_role: roles});
        }
    }

    /**Cuando se elige un rol para remover se invoca esta función */
    function handleRemoveRole(e) {
        let options = document.getElementById('selectedRoles').selectedOptions;
        let values = Array.from(options).map(({ value }) => parseInt(value,10));
        setSelectedToRemove(values);
    }


    const switchInvisible = async (e) => {
        e.preventDefault();
        let text = "";
        if(invisible){
            text = "¿Deseas activar el proyecto?";
        }
        else{
            text = "¿Deseas ocultar momentáneamente el proyecto?";
        }
    
        if(window.confirm(text)){
            
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/project/switchInvisible`;
                await axios.post(url, {
                    projectId: projectId,
                    invisible: !invisible
                })
                .then((response) => {
                    let msg = "";
                    if(invisible){
                        msg = "El proyecto ha sido activado";
                    }
                    else{
                        msg = "El proyecto ha sido desactivado";
                    }
                    setInvisible(!invisible);
                    setEnable(!enable);
                    toast.success(msg, { autoClose: 2000 });
                    /*
                    setTimeout(() => {
                        navigate('/crew/'+person._id);
                    }, 1000);*/
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al activar/desactivar el proyecto:', error);
                });
            } 
            catch (error) {
                console.log(error);
                showBoundary(error);
            }
        }
    }

    const switchIsMain = async (e) => {
        e.preventDefault();
        let text = "";
        if(!projectIsMain){
            text = "¿Deseas que este sea tu proyecto principal?";
        }
        else{
            text = "¿Deseas quitar este proyecto como principal?";
        }
    
        if(window.confirm(text)){
            
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/project/switchMain`;
                await axios.post(url, {
                    projectId: projectId,
                    project_is_main: !projectIsMain
                })
                .then((response) => {
                    let msg = "";
                    if(!projectIsMain){
                        msg = "El proyecto ha sido marcado como principal";
                        setEnable(true);
                    }
                    else{
                        msg = "El proyecto ha sido desactivado como principal";
                        setEnable(false);
                    }
                    setProjectIsMain(!projectIsMain);
                    
                    toast.success(msg, { autoClose: 2000 });
                    /*
                    setTimeout(() => {
                        navigate('/crew/'+person._id);
                    }, 1000);*/
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al activar/desactivar el proyecto:', error);
                });
            } 
            catch (error) {
                console.log(error);
                showBoundary(error);
            }
        }
    }


    const deleteProject = async (e) => {
        e.preventDefault();
        let text = "¿Deseas eliminar este proyecto? Esta acción no se puede deshacer";
    
        if(window.confirm(text)){
            
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/project/deleteProject`;
                await axios.put(url, {
                    _id: projectId
                })
                .then((response) => {
                    let msg = "";
                    msg = "El proyecto ha sido eliminado";
                    toast.success(msg, { autoClose: 2000 });
                    
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al eliminar el proyecto:', error);
                });
            } 
            catch (error) {
                console.log(error);
                showBoundary(error);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const cancelToken = axios.CancelToken.source();

        console.log("Enviando los datos al servidor");

        
        let error = validateForm();

        console.log(error);

        if(error){
            setFormError(error);
            console.log("Form validation failed, therefore it did not submit");
            return;
        }

        console.log(formData);

        console.log("Form validation passed, therefore it will submit");
        setSubmitted(true);

        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/project/updateProject`;
            await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                console.log(response.data.message);

                let roles = [];
                selectedRoles.map((item, index) => {
                    roles.push(item.label);
                    return roles;
                });

                console.log(roles);

                let data = {
                    _id: projectId,
                    project_role: roles
                }

                axios.post(`${process.env.REACT_APP_API_LOCATION}/project/updateProjectRoles`, data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    cancelToken: cancelToken.token,
                })
                .then((response) => {
                    console.log(response.data.message);
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al guardar los roles del proyecto:', error);
                });

                toast.success("Tus datos han sido actualizados correctamente", { autoClose: 2000 });
                setTimeout(() => {
                    //window.location.reload();
                }, 1000);
                
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al guardar los datos del proyecto:', error);
                }
            });
        }
        catch(error){
            console.log(error);    
            showBoundary(error);        
        }
        finally{
            setSubmitted(false);
        }

    } 

    const validateForm = () => {
        const { 
            project_title, 
            project_type, 
            project_status, 
            project_year, 
            project_genre, 
            project_desc, 
            project_demo,
            project_facebook_page,
            project_twitter_page,
            proj_vimeo_page,
            proj_youtube_page,
            proj_instagram_page,
            proj_imdb_page,
            proj_external_view
        } = formData;        
        let error = null;

        if(project_title && project_title === ''){
            toast.error("El título del proyecto no puede estar vacío", { autoClose: 2000 });
            return { 
                field_name: 'project_title', 
                message: 'El título del proyecto no puede estar vacío' 
            };
        }

        if(project_desc==='' || project_desc===null || project_desc===undefined){
            toast.error("La descripción no puede estar vacía", { autoClose: 2000 });
            error = {
                field_name: 'project_desc',
                message: 'La descripción no puede estar vacía'
            }        
            return error;                
        }

        if(project_type==='' || project_type===null || project_type===undefined || project_type==='Seleccionar'){
            toast.error("El tipo de proyecto no puede estar vacío", { autoClose: 2000 });
            error = {
                field_name: 'project_type',
                message: 'El tipo de proyecto no puede estar vacío'
            }        
            return error;    
        }

        if(project_status==='' || project_status===null || project_status===undefined || project_status==='Seleccionar'){
            toast.error("El estatus de proyecto no puede estar vacío", { autoClose: 2000 });
            error = {
                field_name: 'project_status',
                message: 'El estatus de proyecto no puede estar vacío'
            }        
            return error;    
        }

        if(project_year==='' || project_year===null || project_year===undefined || project_year==='Seleccionar'){
            toast.error("El año de proyecto no puede estar vacío", { autoClose: 2000 });
            error = {
                field_name: 'project_year',
                message: 'El año de proyecto no puede estar vacío'
            }        
            return error;    
        }

        if(project_genre==='' || project_genre===null || project_genre===undefined || project_genre==='Seleccionar'){
            toast.error("El género de proyecto no puede estar vacío", { autoClose: 2000 });
            error = {
                field_name: 'project_genre',
                message: 'El género de proyecto no puede estar vacío'
            }        
            return error;    
        }

        if(project_demo && project_demo !== ''){
            const videoURL = verifyVideoURL(project_demo);
            if(!videoURL){
                toast.error("La URL del demo no es válida, revisa que sea una URL de youtube o vimeo", { autoClose: 2000 });
                error = {
                    field_name: 'project_demo',
                    message: 'La URL del demo no es válida, revisa que sea una URL de youtube o vimeo'
                }        
                toast.error("La URL del demo no es válida, revisa que sea una URL de youtube o vimeo", { autoClose: 2000 });
                return error;
            }            
        }

        if(project_facebook_page && project_facebook_page !== ''){
            const valid = verifyValidSite(project_facebook_page, "facebook");
            if(!valid){
                toast.error("La URL de facebook no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'project_facebook_page',
                    message: 'La URL de facebook no es válida'
                }        
                return error;
            }
        }

        if(project_twitter_page && project_twitter_page !== ''){
            const valid = verifyValidSite(project_twitter_page, "twitter");
            if(!valid){
                toast.error("La URL de twitter no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'project_twitter_page',
                    message: 'La URL de twitter no es válida'
                }        
                return error;
            }
        }

        if(proj_vimeo_page && proj_vimeo_page !== ''){
            const valid = verifyValidSite(proj_vimeo_page, "vimeo");
            if(!valid){
                toast.error("La URL de vimeo no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'proj_vimeo_page',
                    message: 'La URL de vimeo no es válida'
                }        
                return error;
            }
        }

        if(proj_youtube_page && proj_youtube_page !== ''){
            const valid = verifyValidSite(proj_youtube_page, "youtube");
            if(!valid){
                toast.error("La URL de youtube no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'proj_youtube_page',
                    message: 'La URL de youtube no es válida'
                }        
                return error;
            }
        }

        if(proj_instagram_page && proj_instagram_page !== ''){
            const valid = verifyValidSite(proj_instagram_page, "instagram");
            if(!valid){
                toast.error("La URL de instagram no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'proj_instagram_page',
                    message: 'La URL de instagram no es válida'
                }        
                return error;
            }
        }

        if(proj_imdb_page && proj_imdb_page !== ''){
            const valid = verifyValidSite(proj_imdb_page, "imdb");
            if(!valid){
                toast.error("La URL de imdb no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'proj_imdb_page',
                    message: 'La URL de imdb no es válida'
                }        
                return error;
            }
        }
        if(proj_external_view && proj_external_view !== ''){
            const valid = verifyValidURL(proj_external_view, "imdb");
            if(!valid){
                toast.error("La URL del sitio de streaming no es válida, ¿olvidaste agregar https://?", { autoClose: 2000 });
                error = {
                    field_name: 'proj_external_view',
                    message: 'La URL del sitio de streaming no es válida, ¿olvidaste agregar https://?'
                }        
                return error;
            }
        }
            
        
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        let decoded = jwt_decode(localStorage.getItem("token"));
        const userId = decoded._id;

        if(userId){
            axios.get(`${process.env.REACT_APP_API_LOCATION}/project/getProject/`+projectId,{
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                console.log(response.data);
                setFormData({
                    _id: response.data._id,
                    userId: response.data.userId,
                    project_title: response.data.project_title,
                    project_desc: response.data.project_desc,
                    project_type: response.data.project_type,
                    project_status: response.data.project_status,
                    project_year: response.data.project_year,
                    project_genre: response.data.project_genre,
                    project_web_page: response.data.project_web_page,
                    project_demo: response.data.project_demo,
                    project_facebook_page: response.data.project_facebook_page,
                    project_twitter_page: response.data.project_twitter_page,
                    proj_vimeo_page: response.data.proj_vimeo_page,
                    proj_youtube_page: response.data.proj_youtube_page,
                    proj_instagram_page: response.data.proj_instagram_page,
                    proj_imdb_page: response.data.proj_imdb_page,
                    proj_external_view: response.data.proj_external_view,
                    cover: response.data.cover,
                    gallery: response.data.gallery
                });
                setInvisible(response.data.invisible);
                setProjectIsMain(response.data.project_is_main);     
                setPLAN(state.data.plan);           
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los datos del usuario:', error);
                }
            });
        }

        getYears();

        axios.get(`${process.env.REACT_APP_API_LOCATION}/crew/getDepartments`,{
            cancelToken: cancelToken.token,
        })
        .then((response) => {
            let departmentSet = [{ label: "Cualquier departamento", value: "cualquier" }];
            for(let i = 0; i < response.data.length; i++){
                let departmentObject = {
                    label: response.data[i].category,
                    value: response.data[i].category
                }
                departmentSet.push(departmentObject);
            }
            setDepartments(departmentSet);
        })
        .catch((error) => {
            if(axios.isCancel(error)){
                console.log("Request cancelled");
            }
            else{
                showBoundary(error);
                console.error('Error al obtener los departamentos:', error);
            }
        });

        function getYears(){
            let years = [];
            for(let i=2025; i>=1970; i--){
                years.push(i);
            }
            setYears(years);
        }

        return () => {
            cancelToken.cancel();
        }

        

    }, [showBoundary, projectId, state.data.plan]);

    if(!formData){
        return <div>Cargando...</div>
    }

  return (
    <>
    
    <Header/>

    <div className="container">
        <div className="row">
        <div className="col-lg-3 rounded background-verylightgray p-3">
                <div className="sticky-top">
                    <div className="spacer10"></div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className={"col menu-link linkable"} onClick={gotoProject}><span className="mdi mdi-eye"></span>&nbsp;Ver proyecto</div>
                    </div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className={"col menu-link linkable"} onClick={gotoProfile}><span className="mdi mdi-account"></span>&nbsp;Editar perfil</div>
                    </div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col text-14">
                            <hr/>
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col text-14">
                            <a href="/ProjectList" className="text-black" id=""><span className="mdi mdi-briefcase"></span> Producciones/muestras</a>
                        </div>
                    </div>       
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col text-14">
                            <a href="/IndustyList" className="text-black" id=""><span className="mdi mdi-domain"></span> Empresas/industrias</a>
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div><hr/></div>
                </div>
            </div>
            <div className="col-lg-9">
                <div className="container">
                    <form onSubmit={handleSubmit}>

                    <div className='row spaced'>
                        <div className="col-lg-3">
                            <FormButton submit={false} onClick={gotoProject} className="btn btn-options">Ver proyecto</FormButton>
                        </div>
                    </div>    

                    <div className='row spaced'>
                        <div className="col-lg-12">
                            <ProjectCover cover={formData.cover} projectId={formData._id} owner={formData.userId}/>
                        </div>
                    </div>


                    <div className="row spaced">
                        <div className="col-lg-3 col-sm-12">
                            <h2 className="titles">Datos del proyecto</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-12 margin-10">
                        {formData && formData.invisible &&
                            <FormSwitch
                                onChange={switchInvisible}
                                disabled={submitted ? "disabled":"" }
                                label="Activar proyecto"
                                status={enable}
                                isOn={enable}
                            />
                        }
                        {formData && !formData.invisible &&
                            <FormSwitch
                                onChange={switchInvisible}
                                disabled={submitted ? "disabled":"" }
                                label="Desactivar proyecto"
                                status={!enable}
                                isOn={!enable}
                            /> 
                        }
                        </div>                        
                    </div>
                    <div className='spacer40'></div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 form-group">
                            <FormLabel htmlFor="title" required={true}>Título del proyecto <span className="text-purple">(Requerido)</span></FormLabel>
                            <FormTextInput
                                name="project_title"
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                                onChange={handleChange} 
                                value={formData && formData.project_title ? formData.project_title:''}
                            />
                            {formError && formError.field_name === 'project_title' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-12 form-group">
                            <FormLabel htmlFor="type" required={true}>Tipo</FormLabel>
                            <FormSelect
                                name="project_type"
                                data="projectType"
                                onChange={handleChange} 
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                                selected={formData.project_type}
                            />
                            {formError && formError.field_name === 'project_type' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                        <div className="col-lg-6 col-md-12 form-group">
                            <FormLabel htmlFor="status" required={true}>Estatus</FormLabel>
                            <FormSelect
                                name="project_status"
                                data="projectStatus"
                                onChange={handleChange} 
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                                selected={formData.project_status}
                            />
                            {formError && formError.field_name === 'project_status' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 form-group">
                            <FormLabel htmlFor="year" required={true}>Año</FormLabel>
                            <select 
                                name='project_year' 
                                className="form-control agreements"     
                                onChange={handleChange} 
                                disabled={submitted ? "disabled":"" }
                                selected={formData.project_year}
                            >
                                {years.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                            {formError && formError.field_name === 'project_year' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                        <div className="col-lg-6 col-md-12 form-group">
                            <FormLabel htmlFor="genre" required={true}>Género</FormLabel>
                            <FormSelect
                                name="project_genre"
                                data="projectGenre"
                                onChange={handleChange} 
                                className="form-control agreements"
                                disabled={submitted ? "disabled":"" }
                                selected={formData.project_genre}
                            />
                            {formError && formError.field_name === 'project_genre' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="row spaced">
                        <div className="col">
                            Elige cuál fue el rol o roles que desempeñaste en tu proyecto
                        </div>
                    </div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col-5 border">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="oc" className="agreements"><strong>Departamento</strong> </label>
                                    <Select
                                        name="category"
                                        defaultValue={{ label: "Cualquier departamento", value: "cualquier" }}
                                        options={departments}
                                        className="agreements"
                                        onChange={(e) => updateRoles(e)}                                            
                                        isDisabled={submitted ? "disabled":"" }                                        
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="agreements" htmlFor="ocupation"><strong>Ocupación</strong> </label>
                                    <Select
                                        name="role"
                                        defaultValue={{ label: "Cualquier ocupación", value: "cualquier" }}
                                        options={roles}
                                        className="agreements"
                                        onChange={(e) => handleRole(e)} 
                                        isDisabled={submitted ? "disabled":"" }
                                    />
                                    
                                </div>
                            </div>
                            <div className="spacer20"></div>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-center">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className={submitted ? "mdi mdi-arrow-right-bold big-size arrow-disabled":"mdi mdi-arrow-right-bold big-size"} onClick={handleRightArrow}></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={submitted ? "mdi mdi-arrow-left-bold big-size arrow-disabled":"mdi mdi-arrow-left-bold big-size"} onClick={handleLeftArrow}></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                        <div className="col-5 border">
                        <label htmlFor="oc" className="agreements"><strong>Roles que has seleccionado</strong> <span className="text-purple">(Requerido)</span></label>
                            <select 
                                name="selectedRoles" 
                                className="form-control agreements"
                                id="selectedRoles"                                
                                multiple
                                onClick={(e) => handleRemoveRole(e)}
                                disabled={submitted ? "disabled":"" }
                            >                                
                                {selectedRoles && selectedRoles.map((item, index) => (
                                    <option key={index} value={item.value}>{item.label}</option>
                                ))}

                            </select>
                            
                        </div>
                    </div>
                    <div className='row spaced'>    
                        <div className="col-lg-12">
                            <FormLabel htmlFor="title" required={true}>Descripción / Sinopsis: </FormLabel>
                            <FormTextareaInput
                                name="project_desc"
                                onChange={handleChange} 
                                className="form-control agreements"
                                value={formData && formData.project_desc ? formData.project_desc:''}
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'project_desc' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="row spaced">
                        <div className="col">
                            <h2 className="titles">En internet</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="web" required={false}>Página Web</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-web"></span></div>
                            </div>
                            <FormTextInput 
                                name="project_web_page"
                                onChange={handleChange}
                                placeholder="Ej: www.cinekomuna.com"
                                value={formData && formData.project_web_page ? formData.project_web_page:''}
                                maxLength="120"
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'project_web_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="demo" required={false}>Demo reel</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-play"></span></div>
                            </div>
                            <FormTextInput
                                name="project_demo"
                                onChange={handleChange}
                                value={formData && formData.project_demo ? formData.project_demo:''}
                                placeholder="URL de un video de youtube o vimeo"
                                maxLength="150"
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'project_demo' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="facebook" required={false}>Facebook</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-facebook"></span></div>
                            </div>
                            <FormTextInput 
                                name="project_facebook_page" 
                                onChange={handleChange} 
                                placeholder="Ej. www.facebook.com/cinekomuna" 
                                value={formData && formData.project_facebook_page ? formData.project_facebook_page:''}
                                maxLength="120"
                                disabled={submitted ? "disabled":"" }
                            />                      
                            {formError && formError.field_name === 'project_facebook_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}       
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="twitter" required={false}>Twitter</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-twitter"></span></div>
                            </div>
                            <FormTextInput
                                name="project_twitter_page"
                                onChange={handleChange}
                                placeholder="Ej. www.twitter.com/cinekomuna"
                                value={formData && formData.project_twitter_page ? formData.project_twitter_page:''}
                                maxLength="120"
                                disabled={submitted ? "disabled":"" }
                            />         
                            {formError && formError.field_name === 'project_twitter_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="vimeo" required={false}>Vimeo</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-vimeo"></span></div>
                            </div>
                            <FormTextInput
                                name="proj_vimeo_page"
                                onChange={handleChange}
                                placeholder="Ej. www.vimeo.com/cinekomuna"
                                value={formData && formData.proj_vimeo_page ? formData.proj_vimeo_page:''}
                                maxLength="120"
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'proj_vimeo_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="youtube" required={false}>Youtube</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-youtube"></span></div>
                            </div>
                            <FormTextInput
                                name="proj_youtube_page"
                                onChange={handleChange}
                                placeholder="Ej. www.youtube.com/cinekomuna"
                                value={formData && formData.proj_youtube_page ? formData.proj_youtube_page:''}
                                maxLength="120"
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'proj_youtube_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="instagram" required={false}>Instagram</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><span className="mdi mdi-instagram"></span></div>
                            </div>
                            <FormTextInput
                                name="proj_instagram_page"
                                onChange={handleChange}
                                placeholder="Ej. www.instagram.com/cinekomuna"
                                value={formData && formData.proj_instagram_page ? formData.proj_instagram_page:''}
                                maxLength="120"
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'proj_instagram_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 form-group">
                            <FormLabel htmlFor="imdb" required={false}>IMDB</FormLabel>
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><img src="/imdb.png" className="icon-size" alt="imdb icon"/></div>
                            </div>
                            <FormTextInput
                                name="proj_imdb_page"
                                onChange={handleChange}
                                placeholder="Ej. www.imdb.com/cinekomuna"
                                value={formData && formData.proj_imdb_page ? formData.proj_imdb_page:''}
                                maxLength="120"
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'proj_imdb_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                            </div>
                        </div>
                    </div>
                    <div className='row spaced'>
                        <div className="col">
                            <h2 className="titles">Se puede ver en</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 form-group">
                            <p className='spaced'>
                            Si tu película ya se encuentra en una plataforma de streaming (Netflix, Amazon Prime, Filminlatino, Mubi, Cinépolis Klic, etc) puedes incluir aquí el link, el cual generará un botón de play que aparecerá en el perfil de tu proyecto y que redireccionará a los visitantes para que puedan ver tu película. Si dejas el campo vacío no aparecerá en el Perfil de tu Proyecto.
                            </p>
                            <p className='spaced'>
                            Si en cambio tu película y/o trailer de la misma se encuentran en youtube y/o vimeo y quieres de igual manera compartirla en el perfil del proyecto, tendrás la opción de hacerlo una vez creado el perfil y agregandola como un link de "video"
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 form-group">
                            <FormLabel htmlFor="streaming" required={false}>Streaming</FormLabel>
                            <FormTextInput
                                name="proj_external_view"
                                onChange={handleChange}
                                placeholder="Ej. www.netflix.com/cinekomuna"
                                value={formData && formData.proj_external_view ? formData.proj_external_view:''}
                                maxLength="120"
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'proj_external_view' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>

                    <div className="row spaced">
                        <div className="col">
                            <h2 className="titles">Marcar como proyecto principal</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 form-group">
                            Los proyectos marcados como <strong>Proyecto Principal</strong> tienen una presencia 
                            destacada en tu <strong>Perfil</strong>, ubicándose en la parte superior, con un formato 
                            más grande.
                        </div>
                    </div>
                    {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                    <div className="row">
                        <div className="col-lg-12 col-md-12 form-group">
                            {!projectIsMain &&
                                <FormSwitch
                                    onChange={switchIsMain}
                                    disabled={submitted ? "disabled":"" }
                                    label="Marcar como principal"
                                    status={false}
                                    isOn={false}
                                />
                            }
                            {projectIsMain &&
                                <FormSwitch
                                    onChange={switchIsMain}
                                    disabled={submitted ? "disabled":"" }
                                    label="Desactivar como principal"
                                    status={true}
                                    isOn={true}
                                /> 
                            }
                        </div>
                    </div>
                    }
                    
                    {(PLAN==="FREE") &&
                        <>
                        <FormSwitch
                            disabled={"disabled"}
                            label="Marcar como principal"                            
                        />
                        <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                        </>
                    }
                    
                    <div className="row spaced">
                        <div className="col-lg-12 mx-auto">
                            <h2>Galería de imágenes</h2>
                        </div>
                    </div>
                    <div className="spacer10"></div>
                    
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col-lg-12"> 
                            <ProjectGallery project={formData}/>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12"> 
                            <FormButton submit={false} onClick={gotoUploadGallery} className="btn btn-purple" disabled={submitted ? "disabled":"" }>Administrar galería de proyecto <span className="mdi mdi-plus"></span></FormButton>
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col">
                            <FormButton submit={true} className="btn btn-purple" disabled={submitted ? "disabled":"" }>Guardar</FormButton>
                        </div>
                    </div>            
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col-12 danger-zone">                    
                            <span 
                                className={!toggleDangerZone ? 'mdi mdi-arrow-right cursor' : 'mdi mdi-arrow-down cursor'} 
                                data-toggle="collapse" 
                                data-target="#collapseExample" 
                                aria-expanded="false" 
                                aria-controls="collapseExample" 
                                onClick={handleDangerZoneToggle}
                            >&nbsp;Mostar zona de riesgo</span>
                            <div className="collapse uppercase" id="collapseExample">
                                <div className="spacer10"></div>
                                <p className="text-danger">¡Cuidado! Estas acciones no se pueden deshacer</p>
                                <div className="spacer10"></div>
                                <FormButton submit={false} onClick={deleteProject} className="btn btn-purple" disabled={submitted ? "disabled":"" }> 
                                    Eliminar proyecto
                                </FormButton>
                            </div>
                        </div>
                    </div>
                            



                    <div className="spacer40"></div>
                
                </form>
                </div>
            </div>
        </div>
    </div>

    

    <Footer/>
    </>
  )
}

export default ProjectForm