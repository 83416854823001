import {React, useEffect, useState} from "react";
import { useErrorBoundary } from "react-error-boundary";
import axios from "axios";

function CrewFollowers(props) {
    const { showBoundary } = useErrorBoundary();

    const {personId,name} = props;
    const [followers, setFollowers] = useState([]);

    /**
     * Paginación
     */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(9);

    // Calcula el número total de páginas
    const totalPages = Math.ceil(followers.length / itemsPerPage);

    // Calcula el índice del primer elemento en la página actual
    const startIndex = (currentPage - 1) * itemsPerPage;

    // Obtiene los elementos que se mostrarán en la página actual
    const currentItems = followers.slice(startIndex, startIndex + itemsPerPage);    

    // Maneja el cambio de página hacia atrás
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setItemsPerPage(9); //Esta línea sólo es para que no marque warning por no usar setItemsPerPage
        }
    };

    // Maneja el cambio de página hacia adelante
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            setItemsPerPage(9); //Esta línea sólo es para que no marque warning por no usar setItemsPerPage
        }
    };

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if(personId){

            axios.get(`${process.env.REACT_APP_API_LOCATION}/crew/getFollowers/${personId}`, {
                cancelToken: cancelToken.token,
            })
            .then((response) => {            
                setFollowers(response.data);           
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los seguidores del usuario:', error);
                }
            });
        }

        //Clean up function
        return () => {
            cancelToken.cancel();
        }

    }, [personId, showBoundary]); 

    if(!followers){
        return <div>Cargando...</div>;
    }


    return (
        <>        
        {followers && followers.length > 0 &&
        <div>
            <div className="row">
                <div className="col">
                    <strong className="uppercase">Seguidores de {name}</strong>
                    <hr/>
                </div>
            </div>
            <div className="spacer20"></div>
            
            <div className="row mb-2">
                <div className="col">
                    <div className="row">
                        {currentItems.map((follower, index) => {
                            let url = '';
                            // Verifica si follower.isCrew es true o false
                            if (follower.isCrew) {
                            // Asigna un valor si isCrew es true
                                url = '/crew/' + follower._id;
                            } 
                            else if(follower.isCast) {
                            // Asigna otro valor si isCrew es false
                                url = '/cast/' + follower._id;
                            }
                            else{
                                url = '/';
                            }
                            
                            return(
                                <div className="col-md-4 col-4 mb-2 text-center" key={index}>                               
                                    <a href={url}>
                                        <div className="image-container-50">
                                            <img 
                                                src={follower.photo} 
                                                className="image" 
                                                data-toggle="tooltip" 
                                                data-placement="top" 
                                                title={follower.fullname} 
                                                alt="Profile"
                                            />
                                        </div>
                                        {
                                        <span className="name-profile">{follower.fullname}</span>
                                        }
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                    {totalPages > 1 &&
                    <div className="row">
                        <div className="col-6 text-center">
                            <button aria-label="Previous" className="btn text-black" onClick={handlePrevPage} disabled={currentPage === 1}>
                                <i className="mdi mdi-arrow-left-circle text-black"></i>
                                
                            </button>
                        </div>
                        <div className="col-6 text-center">
                            <button aria-label="Next" className="btn text-black" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                <i className="mdi mdi-arrow-right-circle text-black"></i>
                            </button>                            
                        </div>
                    </div>
                    }
                </div>
                
            </div>
            
        </div>
        }
        </>
    )
}

export default CrewFollowers;