import React from 'react'

function FormLabel({htmlFor, required, children}) {
  return (
    <div className='form-label'>
        <label htmlFor={htmlFor}>
            {children}
            {required && <span className="text-purple"> * </span>}       
        </label>
    </div>
  )
}

export default FormLabel