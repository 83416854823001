import React, {useContext, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import {Col} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { UserContext } from "../context";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import FormButton from "../components/common/formComponents/FormButton";
import styled from 'styled-components';
import { toast } from "react-toastify";

const Row = styled.div`
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 10px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
`

const ColSmall = styled.div`
    flex: 0 0 15%;  
    max-width: 15%;
    height: 100px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
`
const ColHeaderSmall = styled.div`
    flex: 0 0 15%;  
    max-width: 15%;
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
`

// a round button with a plus sign to add a new image
const PlusButton= styled.button`
    background-color: #ED1567;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
`
/*
const ColMedium = styled.div`
    flex: 0 0 25%;
    max-width: 25%;
    vertical-align: middle;
    padding: 40px 0 0 0;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
`
const ColHeaderMedium = styled.div`
    flex: 0 0 25%;
    max-width: 25%;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
`
*/
const ColLarge = styled.div`
    flex: 0 0 35%;  
    max-width: 35%;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
`
const ColHeaderLarge = styled.div`
    flex: 0 0 35%;  
    max-width: 35%;
    padding-left: 30px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
`

const Image = styled.img`
    width: 100px;
    max-height: 80px;
    object-fit: contain;
    cursor: pointer;
`

const MediaPage = () => {
    const location = useLocation();
    const [state] = useContext(UserContext);
    const [media, setMedia] = useState([]);
    
    const [zoomImage, setZoomImage] = useState({
        url: "",
        name: ""        
    });
    const [submitted, setSubmitted] = useState(false);
    const selected = useRef([]);
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalImages, setTotalImages] = useState(0);
    const [PLAN, setPLAN] = useState("FREE");
    const membershipLimits = {
        FREE: 3,
        PRO: 20,
        PREMIUM: 50
      };

    const [activeDeleteButton, setActiveDeleteButton] = useState(false);
    const [show, setShow] = useState(false);
    const [showSubscription, setShowSubscription] = useState(false);

    
    const [fileList, setFileList] = useState(null);

    const inputRef = useRef(null);
    
    let source, entity, imageType = "none";
    let id = "";


   
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseSubscription = () => setShowSubscription(false);
    const handleShowSubscription = () => setShowSubscription(true);
    
    if(location.state){
        source = location.state.source;
        console.log(source);
        id = location.state.id;
        console.log(id);
        //currentGallery = location.state.currentGallery;

        //Identifies the source page: project, industry, crew, cast
        const sourceToEntityMap = {
            'project': 'project',
            'industry': 'industry',
            'crew': 'crew',
            'cast': 'cast',
            'user': 'user'
        };
        
        const entities = Object.keys(sourceToEntityMap);
        
        for (const sourceKey of entities) {
            if (source.indexOf(sourceKey) !== -1) {
                entity = sourceToEntityMap[sourceKey];
                break;
            }
        }

        //Identifies the type of image: gallery, profile, cover
        const typeToImageTypeMap = {
            'Gallery': 'Gallery',
            'Profile': 'Profile',
            'Cover': 'Cover',
            'Photo': 'Photo',
            'Logo': 'Logo'
        };

        const types = Object.keys(typeToImageTypeMap);

        for (const typeKey of types) {
            if (source.indexOf(typeKey) !== -1) {
                imageType = typeToImageTypeMap[typeKey];
                break;
            }
        }
    }


    const validateSubscriptionAndAddImage = () => {

        console.log(totalImages);
        console.log(membershipLimits[PLAN]);

        if(totalImages >= membershipLimits[PLAN]){
            handleShowSubscription();
        }
        else{
            console.log("show modal");
            handleShow();
        }
    }
    
    const handleZoom = (url, name) => {
        setZoomImage({
            url: url,
            name: name
        });
    }

    const handleCheck = (e) => {
        let tempSelected = selected.current;
        if(e.target.checked){
            //add to selected if not added
            if(tempSelected.indexOf(e.target.value) === -1){
                tempSelected.push(e.target.value);
            }
        }
        else{
            //remove from selected
            const index = tempSelected.indexOf(e.target.value);
            if (index > -1) {
                tempSelected.splice(index, 1);
            }
        }
        selected.current = tempSelected;

        if(selected.current.length > 0){
            setActiveDeleteButton(true);
        }
        else{
            setActiveDeleteButton(false);
        }

        //isChecked(e.target.value);
        console.log(selected.current);
    }

    const handleActivate = (e) => {
        let newArray = [];
        newArray.push(e.target.value);
        selected.current = newArray;        
        
        if(selected.current.length > 0){
            setActiveDeleteButton(true);
        }
        else{
            setActiveDeleteButton(false);
        }
        
    }

    const handleDelete = async () => {
        if(!selected.current.length){
            toast.error("Seleccione al menos una imagen");
            setSubmitted(false);
            return;
        }

        if(window.confirm("¿Estás seguro de que deseas eliminar las imágenes seleccionadas?")){
            setSubmitted(true);
            setLoading(true);
            try{
            
                //From the selected array, extracts the first segment of the string, which is the name of the image, and creates an array with the names of the images to be deleted
                let imagesToDelete = [];
                selected.current.forEach(image => {
                    let element = image.split("|");
                    imagesToDelete.push(element[0]);
                });

                //send to the server the array with the names of the images to be deleted
                const response = await axios.post(`${process.env.REACT_APP_API_LOCATION}/media/delete`,{
                    userId: state.data._id,
                    images: imagesToDelete
                });

                if(response.status === 200){
                    toast.success("Imagenes eliminadas");
                    selected.current = [];
                    setSubmitted(false);
                    setActiveDeleteButton(false);
                }
                else{
                    toast.error(response.data.error);
                    setSubmitted(false);
                }
            }
            catch(error){
                console.log(error);
                toast.error(error.response.data.error);
                setSubmitted(false);
            }
            finally{
                setLoading(false);
                setReload(true);
            }
                
        }
    }
    
    const save = async () => {
        setSubmitted(true);      
    
        if(!selected.current.length){
            toast.error("Seleccione al menos una imagen");
            setSubmitted(false);
            return;
        }
        else{            
            const entityToCollectionMap = {
                'project': 'project',
                'industry': 'industry',
                'crew': 'crew',
                'cast': 'cast',
                'user': 'user'
            };
            let collection = entityToCollectionMap[entity];

            const imageTypeToActionMap = {
                'Gallery': 'saveGallery',
                'Profile': 'saveProfile',
                'Cover': 'saveCover',
                'Photo': 'savePhoto',
                'Logo': 'saveLogo'
            };
            let action = imageTypeToActionMap[imageType];

            let gallery = [];

            console.log(selected.current);
            console.log(selected.current.length);

            //iterates every element in the string array media, and for every one creates an object with the fields url, width, height, using the | character a separator, and every row is insterted into gallery array as a json object
            for (let i = 0; i < selected.current.length; i++) {
                let element = selected.current[i].split("|");
                let mediaObject = {
                    url: element[0],
                    width: element[1],
                    height: element[2]
                }
                gallery.push(mediaObject);
            }

            console.log(id);
            console.log(gallery);

            let url = `${process.env.REACT_APP_API_LOCATION}/${collection}/${action}`;
            console.log(url);
            
            const response = await axios.post(url,{
                id: id,
                gallery: gallery
            });

            if(response.status === 200){
                if(action==="saveGallery"){
                    toast.success("Imagenes agregadas a la galería");
                }
                else if(action==="saveProfile"){
                    toast.success("Imagen de perfil actualizada");
                }
                else if(action==="saveCover"){
                    toast.success("Imagen de portada actualizada");
                }
                else if(action==="savePhoto"){
                    toast.success("Imagen actualizada");
                }
                else if(action==="saveLogo"){
                    toast.success("Logo actualizado");
                }
                setSubmitted(false);

                window.location.reload();
            }
            else{
                if(action==="saveGallery"){
                    toast.error("Error al agregar imagenes a la galería");
                }
                else if(action==="saveProfile"){
                    toast.error("Error al actualizar imagen de perfil");
                }
                else if(action==="saveCover"){
                    toast.error("Error al actualizar imagen de portada");
                }
                else if(action==="savePhoto"){
                    toast.error("Error al guardar imagen");
                }
                else if(action==="saveLogo"){
                    toast.error("Error al actualizar logo");
                }

                setSubmitted(false);
            }
        }
    }

    const goToPage = () => {
        let page = "/"+entity+"/"+id;
        window.location.href = page;
    }
    const goToEdit = () => {
        let page = "";

        if(entity === "project"){
            page = "/projectForm/"+id;
        }
        if(entity === "cast" || entity === "crew" || entity === "user"){
            page = "/editProfile";
        }
        if(entity === "industry"){
            page = "/industryForm/"+id;
        }

        window.location.href = page;
    }

    let label = "";
    if(entity === "project"){
        label = "proyecto";
    }
    else if(entity === "industry"){
        label = "industria";
    }
    else if(entity === "crew"){
        label = "crew";
    }
    else if(entity === "cast"){
        label = "cast";
    }
    else if(entity === "user"){
        label = "perfil";
    }

    const handleFileChange = (e) => {
        setFileList(Array.from(e.target.files));
    };

    const openFileSelector = () => {
        inputRef.current.click();
    };
    
    const handleUploadClick = async () => {

        if (fileList.length === 0) {
            toast.error("Selecciona al menos una imagen");
            return;
        }

        setLoading(true);
        setSubmitted(true);

        //check size of every image, if it is bigger than 5MB, return an error
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].size > 5242880) {
                toast.error("La imagen "+fileList[i].name+" es demasiado grande. El tamaño máximo permitido es de 5MB.");
                return;
            }
        }

        const formData = new FormData();
        formData.append('userId', state.data._id);
        fileList.forEach((file, index) => {
            formData.append(`file`, file);
        });

        const url = `${process.env.REACT_APP_API_LOCATION}/media/upload`;

        try {
            const response = await axios.post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
      
            console.log(response.data);
            toast.success('Imágenes subidas correctamente.');
            setReload(true);

        } 
        catch (error) {
            console.error('Error al enviar las imágenes:', error);
            toast.error('Error al enviar las imágenes.');
        }
        finally {
            setLoading(false);
            setSubmitted(false);
            handleClose();
            setReload(true);
        }
        
    };

    useEffect(() => {
        let currentGallery = [];    
        if(location.state && location.state.currentGallery){
            currentGallery = location.state.currentGallery;
        }

        const fetchMedia = async () => {
            const media = await axios.post(`${process.env.REACT_APP_API_LOCATION}/media/getMedia`,{
                userId: state.data._id
            });
    
            //console.log(media.data);
            setMedia(media.data);

            //console.log(currentGallery);

            //itereates media.data and adds every image to selected array
            let tempSelected = [];

            //console.log(location.state.currentGallery);
            currentGallery.forEach(galleryImage => {
                media.data.forEach(image => {
                    if(image.name === galleryImage.url){
                        tempSelected.push(image.name+"|"+image.width+"|"+image.height);
                    }
                });
            });
    
            selected.current = tempSelected;
            
        };

        fetchMedia();
        setPLAN(state.data.plan);
        setTotalImages(media.length);
    },[state.data._id, location.state, reload, state.data.plan, media.length]);

    const files = fileList ? [...fileList] : [];

    console.log(imageType)
    return (
        <div>
            <Header />
                {imageType === "Gallery" &&        
                <>
                <Row>
                    <Col className="col-lg-3 pb-20">
                        <PlusButton onClick={validateSubscriptionAndAddImage}>+</PlusButton>
                    </Col>   
                </Row>         
                <Row>
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={save} className="btn btn-options" disabled={submitted ? "disabled":"" }>Agregar a galería</FormButton>
                    </Col>
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={goToEdit} className="btn btn-options" >Editar {label}</FormButton>
                    </Col>
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={goToPage} className="btn btn-options" >Ver {label}</FormButton>
                    </Col>                    
                </Row>
                </>
                }
                {imageType === "Cover" &&        
                <>
                <Row>
                    <Col className="col-lg-3 pb-20">
                        <PlusButton onClick={validateSubscriptionAndAddImage}>+</PlusButton>
                    </Col>   
                </Row>         
                <Row>
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={save} className="btn btn-options" disabled={submitted ? "disabled":"" }>Establecer como portada</FormButton>
                    </Col>
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={goToEdit} className="btn btn-options" >Editar {label}</FormButton>
                    </Col>
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={goToPage} className="btn btn-options" >Ver {label}</FormButton>
                    </Col>                    
                </Row>
                </>
                }
                {imageType === "Photo" &&           
                <>
                <Row>
                    <Col className="col-lg-3 pb-20">
                        <PlusButton onClick={validateSubscriptionAndAddImage}>+</PlusButton>
                    </Col>   
                </Row>
                <Row>                    
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={save} className="btn btn-options" disabled={submitted ? "disabled":"" }>Establecer como perfil</FormButton>
                    </Col>
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={goToEdit} className="btn btn-options" >Editar {label}</FormButton>
                    </Col>        
                                               
                </Row>
                </>
                }
                {imageType === "Logo" &&            
                <>
                <Row>
                    <Col className="col-lg-3 pb-20">
                        <PlusButton onClick={validateSubscriptionAndAddImage}>+</PlusButton>
                    </Col>   
                </Row>     
                <Row>
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={save} className="btn btn-options" disabled={submitted ? "disabled":"" }>Establecer como logo</FormButton>
                    </Col>
                    <Col className="col-lg-3">
                        <FormButton submit={false} onClick={goToEdit} className="btn btn-options" >Editar {label}</FormButton>
                    </Col>                                      
                </Row>
                </>
                }
                {imageType === "none" &&         
                <>
                <Row>
                    <Col className="col-lg-3 pb-20">
                        <PlusButton onClick={validateSubscriptionAndAddImage}>+</PlusButton>
                    </Col>   
                </Row>
                <Row>
                    <Col className="col-lg-3">
                        <FormButton onClick={handleDelete} className="btn btn-options" disabled={!activeDeleteButton} style={{ cursor: loading ? 'wait' : 'pointer' }}>
                            {loading ? 'Eliminando...' : 'Eliminar imágenes'}
                        </FormButton>
                    </Col>
                </Row>
                </>   
                }
                <Row>
                    <ColHeaderLarge>ID</ColHeaderLarge>
                    <ColHeaderLarge>Imagen</ColHeaderLarge>
                    <ColHeaderSmall>Tamaño</ColHeaderSmall>
                    <ColHeaderSmall>Dimensión</ColHeaderSmall>
                </Row>
                <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Cargar imagen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input type="file" name="file" onChange={handleFileChange} multiple ref={inputRef}/>
                            <button onClick={openFileSelector}>Seleccionar</button>

                            <br/>

                            {files.length > 0 && 
                            <small>{files.length} imagen(es)</small>
                            }

                            
                            

                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleUploadClick} disabled={submitted} style={{ cursor: loading ? 'wait' : 'pointer' }}>
                                {loading ? 'Cargando...' : 'Subir'}
                            </Button>
                            <Button onClick={handleClose}>
                                Cerrar
                            </Button>
                            
                        </Modal.Footer>
                    </Modal>
                { media.map((image, index) => {
                        return (
                            <div className='form-group align-items-center' key={index}>
                                <Row>
                                    <ColLarge>
                                        {(imageType === "Gallery" || imageType==="none") &&   
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={image.name}
                                                name={image.name}
                                                value={image.name+"|"+image.width+"|"+image.height}
                                                defaultChecked={selected.current.indexOf(image.name+"|"+image.width+"|"+image.height) !== -1 ? true : false}
                                                onChange={handleCheck}
                                            />
                                            <label className="custom-control-label" htmlFor={image.name}>
                                                {image.name}
                                            </label>
                                        
                                        </div>
                                        }
                                        {imageType !== "Gallery" &&  imageType !== "none" && 
                                            <div className="custom-control custom-radio">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    id={image.name}
                                                    name={"image"}
                                                    value={image.name+"|"+image.width+"|"+image.height}
                                                    defaultChecked={selected.current.indexOf(image.name+"|"+image.width+"|"+image.height) !== -1 ? true : false}
                                                    onChange={handleActivate}
                                                />
                                                <label className="custom-control-label" htmlFor={image.name}>
                                                    {image.name}
                                                </label>
                                            </div>
                                        }
                                    </ColLarge>
                                    <ColLarge><Image src={image.url} alt={image.name} onClick={()=> handleZoom(image.url, image.name) } data-toggle="modal" data-target="#exampleModalCenter" /></ColLarge>
                                    <ColSmall>{image.bytes} bytes</ColSmall>
                                    <ColSmall>{image.width} x {image.height}</ColSmall>
                                </Row>
                            </div>
                            
                        );
                    }) 
                }
                
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{"maxWidth":"80%"}}>
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">{zoomImage.name}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {zoomImage && 
                            <div style={{ 
                                backgroundImage: `url(${zoomImage.url})`,
                                backgroundRepeat: 'no-repeat',
                                height:'300px',
                                backgroundSize: 'contain',
                            }}/>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />

            <Modal show={showSubscription} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Has alcanzado tu límite</Modal.Title>
            </Modal.Header>
            <Modal.Body>Tu membresía sólo te permite subir {membershipLimits[PLAN]} imágenes. Puedes adquirir una <a href="/subscription">membresía superior</a> para eliminar este límite </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseSubscription}>
                Cerrar
            </Button>
            
            </Modal.Footer>
        </Modal>
        </div>
    );
};

export default MediaPage;