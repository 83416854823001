import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import {gallery} from "./Gallery";
import CloudinaryUploadWidget from "../common/CloudinaryUploadWidget";

export default function App() {

    const [images, setImages] = useState(gallery);
    //const hasSelected = images.some((image) => image.isSelected);
/*
    const handleSelect = (index) => {
        const nextImages = images.map((image, i) => 
            i === index ? { ...image, isSelected: !image.isSelected } : image
        );
        setImages(nextImages);
    };*/

    const handleSelect = (index) => {
        // Copia el arreglo de imágenes y establece isSelected=false en todos los elementos
    const updatedImages = images.map((image, i) => ({
        ...image,
        isSelected: i === index ? !image.isSelected : false,
      }));
  
      // Actualiza el estado con el arreglo actualizado
      setImages(updatedImages);
      };

      /*
    const handleSelectAllClick = () => {
        const nextImages = images.map((image) => ({
        ...image,
        isSelected: !hasSelected,
        }));
        setImages(nextImages);
    };*/

    return (
        <div>
            {/*
        <div className="p-t-1 p-b-1">
            <button onClick={handleSelectAllClick}>
            {hasSelected ? "Clear selection" : "Select all"}
            </button>
    </div>*/}
        <Gallery images={images} onSelect={handleSelect} />

        <CloudinaryUploadWidget />

        </div>
    );
}