import React, {useContext } from 'react'
import {useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../context';
import { useErrorBoundary } from "react-error-boundary";
import { toast } from 'react-toastify';

import FormLabel from "../common/formComponents/FormLabel";
import FormTextInput from "../common/formComponents/FormTextInput";
import FormButton from "../common/formComponents/FormButton";
import FormValidationMessage from "../common/formComponents/FormValidationMessage";
import axios from "axios";

import 'react-toastify/dist/ReactToastify.css';
import "./styles.scss"

function EditPersonalInfo(props) {
    //const [loggedUser] = useContext(UserContext);    
    const [state] = useContext(UserContext);    
    const { showBoundary } = useErrorBoundary();
    console.log("Logged user: " + state.data._id);
    const navigate = useNavigate();
        
    const initialState = {
        _id: '',
        name: '',
        lastname: '',
        lastname2: '',
        country: '',
        state: '',
        city: '',
        photo: ''
    }

    const [person, setPerson] = useState(initialState);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState(initialState);
    const [formError, setFormError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [coverArray, setCoverArray] = useState([]);
    const [url, setUrl] = useState("");
    
    const country = useRef('');
    const cstate = useRef('');
    const city = useRef('');
    
    const handleChange = (e) => { 
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const validateForm = () => {
        let error = false;
        if(formData.name===''){
            error = {
                field_name: 'name',
                message: 'El nombre no puede estar vacío'
            }        
            return error;    
        }

        if(formData.lastname===''){
            error = {
                field_name: 'lastname',
                message: 'El primer apellido no puede estar vacío'
            }        
            return error;    
        }

        if(!formData.country){
            error = {
                field_name: 'country',
                message: 'El país no puede estar vacío'
            }        
            return error;    
        }

        if(!formData.state){
            error = {
                field_name: 'state',
                message: 'El estado no puede estar vacío'
            }        
            return error;    
        }

        if(!formData.city){
            error = {
                field_name: 'city',
                message: 'La ciudad no puede estar vacía'
            }        
            return error;
        }

        return error;
    };

    const handleSubmit = async (e) => {   
        e.preventDefault();
        const cancelToken = axios.CancelToken.source();

        console.log("Enviando los datos al servidor");
        let error = validateForm();
        if(error){
            setFormError(error);
            console.log("Form validation failed, therefore it did not submit");
            return;
        }

        console.log(formData);

        console.log("Form validation passed, therefore it will submit");
        setSubmitted(true);

        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/user/updatePersonalInfo`;
            await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                console.log(response.data.message);                
                toast.success("Tus datos han sido actualizados correctamente", { autoClose: 2000 });
                setTimeout(() => {
                    //window.location.reload();
                }, 1000);
                
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al guardar los datos del usuario:', error);
                }
            });
        }
        catch(error){
            console.log(error);    
            showBoundary(error);        
        }

        /*
        if(data.lastname===''){
            alert("El primer apellido no puede estar vacío");
            return;
        }

        if(!data.country){
            alert("El país no puede estar vacío");
            return;
        }

        if(!data.state){
            alert("El estado no puede estar vacío");
            return;
        }

        if(!data.city){
            alert("La ciudad no puede estar vacía");
            return;
        }
        
        try {            
            const url = `${process.env.REACT_APP_API_LOCATION}/user/updatePersonalInfo`;
            const { data: res } = await axios.put(url, data);         
            console.log(res.message);
            navigate('/editProfile');
        } 
        catch (error) {
            console.log(error);
            if(error.response && 
                error.response.status >= 400 &&
                error.response.status <= 500) {
                //setError(error.response.data.message);
            }
        }*/
        
    }

    function gotoUploadPhoto (){

        console.log(person);
        console.log(coverArray);
        navigate('/media', {
            state: {
              source: 'userPhoto',
              id: person._id,
              currentGallery: coverArray
            }
        });
    }

    async function updateStates (event){
        if(event.target.value === 'cualquier'){
            country.current = '';
            cstate.current = '';
            city.current = '';
        }
        else{
            country.current = event.target.value;
            formData.country = event.target.value;
            cstate.current = '';
            city.current = '';
        }        
        
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getStates`;
        const response = await axios.post(url,{
            "country": event.target.value
        });       
        setStates(response.data);
        setCities([]);        
    };

    async function handleState(e) {
        if(e.target.value === 'cualquier'){            
            cstate.current = '';
            city.current = '';
        }
        else{
            cstate.current = e.target.value;
            formData.state = e.target.value;
            city.current = '';
        }
                
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCities`;
        const response = await axios.post(url,{
            "state": e.target.value
        });
        setCities(response.data);
    }

    async function handleCity(e) {
        if(e.target.value === 'cualquier'){
            city.current = '';
        }
        else{
            city.current = e.target.value;
            formData.city = e.target.value;
        }
    }


useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    //console.log("Logged user: " + state);
    const userId = state.data._id;


//    console.log("userId: " + userId);

    if(userId){
        axios.post(`${process.env.REACT_APP_API_LOCATION}/user/getPersonToEdit`,{
            userId: userId,
            cancelToken: cancelToken.token,
        })
        .then((response) => {
            setPerson(response.data);
            console.log(response.data);
            //Objeto que se va a utilizar para manipular los datos y guardarlos
            setFormData({
                _id: response.data._id,
                name: response.data.name,
                lastname: response.data.lastname,
                lastname2: response.data.lastname2,
                country: response.data.country,
                state: response.data.state,
                city: response.data.city,
                photo: response.data.photo
            });

            

            let tmpArray = [];

            let lastSegment, width, height = "";
            if(response.data.photo){
                if(response.data.photo.url){
                    setUrl(process.env.REACT_APP_PREFIX_FOR_IMAGES + "/" + response.data._id + "/" + response.data.photo.url);
                    lastSegment = response.data.photo.url.substring(response.data.photo.url.lastIndexOf('/') + 1);
                }
                if(response.data.photo.width){
                    width = response.data.photo.width;
                }
                if(response.data.photo.height){
                    height = response.data.photo.height;
                }
                
            }

            tmpArray.push({
                url:lastSegment,
                width: width,
                height: height
            });
            setCoverArray(tmpArray);
        })
        .catch((error) => {
            if(axios.isCancel(error)){
                console.log("Request cancelled");
            }
            else{
                showBoundary(error);
                console.error('Error al obtener los datos del usuario:', error);
            }
        });
        
        axios.post(`${process.env.REACT_APP_API_LOCATION}/location/getCountries`,{
            cancelToken: cancelToken.token,
        })
        .then((response) => {
            setCountries(response.data);
        })
        .catch((error) => {
            if(axios.isCancel(error)){
                console.log("Request cancelled");
            }
            else{
                showBoundary(error);
                console.error('Error al obtener los países:', error);
            }
        });
        
    }

    return () => {
        cancelToken.cancel();
    };

}, [state.data._id, showBoundary]);


if(!person){
    return <div>Cargando...</div>
}

    return (
        <>
        <div className="container">
                <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-12">
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <div className="image-container-100">
                                {url && url !== "" &&
                                <img src={url} className="image" alt="Profile"/>
                                }
                                {(!url || url === "") &&
                                <img src="/profile.png" className="image" alt="Profile"/>
                                }
                            </div>                            
                        </div>
                    </div>
                    <div className="row p-1 m-2">
                        <div className="col text-14 text-purple text-center">
                            <button className="btn btn-purple" onClick={gotoUploadPhoto}>Cambiar foto de perfil <span className="mdi mdi-account-edit"></span></button>
                        </div>
                    </div>
                    <hr className="hr-dark"/>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col">
                            <h2 className="titles">Datos personales</h2>
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col-12 col-md-4 form-group">
                            <FormLabel htmlFor="nombre" required={true}>Nombre</FormLabel>
                            <FormTextInput 
                                name="name" 
                                onChange={handleChange} 
                                placeholder="Nombre o nombres" 
                                maxLength="30"
                                value={formData && formData.name ? formData.name:''}
                                disabled={submitted ? "disabled":"" }
                            />                           
                            {formError && formError.field_name === 'name' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}     
                        </div>
                        <div className="col-12 col-md-4 form-group">
                            <FormLabel htmlFor="lastname" required={true}>Primer Apellido</FormLabel>

                            <FormTextInput
                                name="lastname"
                                value={formData && formData.lastname ? formData.lastname:''} 
                                onChange={handleChange} 
                                placeholder="Apellido paterno" 
                                maxLength="30"
                                disabled={submitted ? "disabled":"" }
                            />
                            {formError && formError.field_name === 'lastname' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}     
                        </div>
                        <div className="col-12 col-md-4 form-group">
                            <FormLabel htmlFor="lastname2">Segundo Apellido</FormLabel>
                            <FormTextInput
                                name="lastname2"
                                value={formData && formData.lastname2 ? formData.lastname2:''} 
                                onChange={handleChange} 
                                placeholder="Apellido materno" 
                                maxLength="30"
                                disabled={submitted ? "disabled":"" }
                            />
                        </div>
                    </div>
                
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col">
                            <h2 className="titles">Ubicación</h2>
                        </div>
                    </div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col">
                            <strong>Actual: {formData.country} / {formData.state} / {formData.city}</strong>
                        </div>
                    </div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col-md-4">
                            <FormLabel htmlFor="country" required={true}>País</FormLabel>                            
                            <select 
                                name="country" 
                                className="form-control agreements" 
                                onChange={(e) => updateStates(e)}
                                disabled={submitted ? "disabled":"" }
                            >
                                <option value="cualquier"> Cualquier país </option>
                                {countries.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                            {formError && formError.field_name === 'country' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}     
                        </div>
                        <div className="col-md-4">
                            <FormLabel htmlFor="state" required={true}>Estado</FormLabel>
                            
                            <select 
                                name="state" 
                                className="form-control agreements"
                                onChange={(e) => handleState(e)}
                                disabled={submitted ? "disabled":"" }
                            >
                                <option value="cualquier"> Cualquier estado </option>
                                {states.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                            {formError && formError.field_name === 'state' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                        <div className="col-md-4">
                            <FormLabel htmlFor="city" required={true}>Ciudad</FormLabel>
                            
                            <select 
                                name="city" 
                                className="form-control agreements"
                                onChange={(e) => handleCity(e)}
                                disabled={submitted ? "disabled":"" }
                            >
                                <option value="cualquier"> Cualquier ciudad </option>
                                {cities.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                            {formError && formError.field_name === 'city' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="spacer40"></div>
                
                    <div className="row">
                        <div className="col"><h2 className="titles">Actividades Profesionales</h2></div>
                    </div>              
                    <div className="row">
                        <div className="col">
                            <strong>Cinekomuna</strong> te permite crear un <strong>perfil profesional </strong> 
                            como <strong>crew</strong> y/o como <strong>cast. </strong>
                            Elige en el menú de la izquierda la opción que más se acomode a tu actividad creativa y profesional dentro 
                            del medio. Si aplicas para las <strong>dos</strong> puedes crear ambos perfiles. 
                            <br/><br/>
                        </div>
                    </div>
                                
                </div>
            </div>  

            <div className="spacer40"></div>
            <div className="row">
                <div className="col text-center">
                    <FormButton submit={true} className="btn btn-purple" disabled={submitted ? "disabled":"" }>
                        Guardar
                    </FormButton>
                </div>
            </div>
            <div className="spacer40"></div>
            </form>
        </div>
        
        
        </>
    )
}

export default EditPersonalInfo;