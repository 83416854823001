import {React, useState, useEffect} from 'react';
import axios from 'axios';

function IndustryHighlighted(props){
    
    const [industries, setIndustries] = useState([]);

    useEffect( () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/industry/getIndustryHighlighted`;
        axios.get(url)
            .then((response) => {
                setIndustries(response.data);
                //console.log(response.data);
            });
    }, []);


    if(!industries) {
        return <div>Cargando...</div>;
    }

    return (
    <div className="container">
        <div className='spacer80'></div>
        <div className='row'>
            <div className='col'>
                <strong className="uppercase">Perfiles destacados de empresas</strong>
                <hr/>
            </div>
        </div>
        
        <div className='row'>
        
        {
            industries.map((industry, index) => {
                return (
                    
                    <div className="col-lg-3 col-md-4 col-sm-6 mx-auto text-center" key={index}>
                        <div className="spacer20"></div>
                        <a href={"/industry/"+industry._id} className="text-black">
                            <img src={industry.logo} id="profileImageinCastPage" className="borde-img" alt="Portada"/>
                        </a>

                        <div className="title p-2">
                            <a href={"/industry/"+industry._id} className="text-black">{industry.name}</a>
                        </div>
                        
                        <div className="agreements mx-auto">                       
                            <span className="mdi mdi-map-marker"></span> {industry.city}
                        </div>

                        <div>
                            <div className="row">
                                <div className="col"><span className="mdi mdi-eye"></span><small>&nbsp;
                                        {industry.views}
                                    </small>
                                </div>
                                <div className="col"><span className="mdi mdi-thumb-up"></span><small>&nbsp;
                                        {industry.likes}
                                    </small>
                                </div>
                                
                            </div>
                        </div>    
                    </div>
                )
            })
        }
        </div>
        <div className='spacer80'></div>
    </div>

    );
}



export default IndustryHighlighted;