import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import './index.css';
import { UserProvider } from './context';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryComponent from './components/errors/ErrorBoundaryComponent';
  
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    
    <UserProvider>
        <BrowserRouter>
          <ErrorBoundary 
            FallbackComponent={ErrorBoundaryComponent} 
            onError={(error, errorInfo) => console.log({ error, errorInfo })}>
            <App/>
          </ErrorBoundary>
        </BrowserRouter>      
    </UserProvider>   
  );

