import {React, useEffect, useState} from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Hand } from "../../static/images/hand.svg";
import {formatearURL} from "../common/FormatFunctions";
import jwtDecode from "jwt-decode";
import jwt_decode from "jwt-decode";
import axios from "axios";
import cn from "classnames";
import "./styles.scss";

function CastMeta(props) {
    
    const { showBoundary } = useErrorBoundary();

    const navigate = useNavigate();

    const particleList = Array.from(Array(10));

    const {person, views, activeSession} = props;

    const [following, setFollowing] = useState(false);
    const [liked, setLiked] = useState(null);
    const [clicked, setClicked] = useState(false);
    //const [toggleRolesOpened, setToggleRolesOpened] = useState(false);
    const [likes , setLikes] = useState(0);
    const [followersCount, setFollowersCount] = useState(0);

    let currentUser = {
        _id: null
    };

    const token = localStorage.getItem("token");
    if(token){
        var decoded = jwt_decode(token);
        currentUser._id = decoded._id;        
    }

    

    function gotoUploadWidget (){
        navigate('/uploadWidget', {
            state: {
              source: 'editProfile'
            }
        });
    }

    const hasEditPermission = () => {
        let result = false;
        if(person){
            if(localStorage.getItem("token")){
                decoded = jwtDecode(localStorage.getItem("token"));
                if (decoded._id === person._id) {
                    result = true;
                } else {
                    result = false;
                }
            }
        }
        return result;
        
    }
    

    const handleLikeClick = async () => {        
        try {
          // Enviar una solicitud POST al servidor para agregar el "Me gusta"

            if(!liked){
                await axios.put(`${process.env.REACT_APP_API_LOCATION}/cast/likePerson`, {
                    userId: currentUser._id, // El ID del usuario que da "Me gusta"
                    personId: person._id, // El ID de la persona que recibe "Me gusta"
                    }, {
                        headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
                });
                setLiked(true);
                setLikes(likes + 1);  
            }
            else{
                await axios.put(`${process.env.REACT_APP_API_LOCATION}/cast/unlikePerson`, {
                    userId: currentUser._id, // El ID del usuario que da "Me gusta"
                    personId: person._id, // El ID de la persona que recibe "Me gusta"
                    }, {
                        headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
                });
                setLiked(false);
                setLikes(likes - 1);  
            }
                   
        } catch (error) {
            showBoundary(error);
          console.error('Error al dar Me gusta:', error);
        }
    };

    const handleFollowClick = async () => {        
        try {
          // Enviar una solicitud POST al servidor para agregar el "Follow"

            if(!following){
                await axios.put(`${process.env.REACT_APP_API_LOCATION}/cast/followPerson`, {
                    userId: currentUser._id, // El ID del usuario que da "Me gusta"
                    personId: person._id, // El ID de la persona que recibe "Me gusta"
                    }, {
                        headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
                });
                setFollowing(true);
                setFollowersCount(followersCount + 1);
            }
            else{
                await axios.put(`${process.env.REACT_APP_API_LOCATION}/cast/unfollowPerson`, {
                    userId: currentUser._id, // El ID del usuario que da "Me gusta"
                    personId: person._id, // El ID de la persona que recibe "Me gusta"
                    }, {
                        headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
                });
                setFollowing(false);
                setFollowersCount(followersCount - 1);
            }
         
        } catch (error) {
            showBoundary(error);
          console.error('Error al dar Seguir:', error);
        }
    };

    useEffect(() => {
        
        const cancelToken = axios.CancelToken.source();

        if(person._id){

            axios.get(`${process.env.REACT_APP_API_LOCATION}/cast/likesCount/${person._id}`, {
                cancelToken: cancelToken.token,
            })
            .then((response) => {            
                setLikes(response.data.likesCount);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los datos del usuario:', error);
                }
            });

            axios.get(`${process.env.REACT_APP_API_LOCATION}/cast/followersCount/${person._id}`, {
                cancelToken: cancelToken.token,
            })
            .then((response) => {            
                setFollowersCount(response.data.followersCount);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los datos del usuario:', error);
                }
            });

            if(person.followers && person.followers.includes(currentUser._id)){
                setFollowing(true);
            }
            else{
                setFollowing(false);
            }
        
            if(person.whoLikesMe && person.whoLikesMe.includes(currentUser._id)){
                setLiked(true);
            }
        }

        return () => {
            cancelToken.cancel();
        }

    
    }, [person._id, currentUser._id, person.followers, person.whoLikesMe, showBoundary]);

    let web = '';
    person && person.cast && person.cast.webpage ? web = person.cast.webpage : web = '';

    let facebook = '';
    person && person.cast && person.cast.facebook ? facebook = person.cast.facebook : facebook = '';

    let instagram = '';
    person && person.cast && person.cast.instagram ? instagram = person.cast.instagram : instagram = '';

    let twitter = '';
    person && person.cast && person.cast.twitter ? twitter = person.cast.twitter : twitter = '';    

    let youtube = '';
    person && person.cast && person.cast.youtube ? youtube = person.cast.youtube : youtube = '';

    let vimeo = '';
    person && person.cast && person.cast.vimeo ? vimeo = person.cast.vimeo : vimeo = '';

    let imdb = '';
    person && person.cast && person.cast.imdb ? imdb = person.cast.imdb : imdb = '';

    
    let followingCount = 0;
    person && person.followingCount ? followingCount = person.followingCount : followingCount = 0;

    
    if(web !== ''){
        web = formatearURL(web);
    }

    let webText = web;
    const webLength = 30;
    if(webText.length > webLength){
        webText = webText.slice(0,webLength) + "...";
    }

  return (
    <>
    <div className="container">
        <div className="spacer60"></div>
        <div className="row">
            <div className="d-lg-none col-12">
                {currentUser && hasEditPermission() &&
                <div>
                    <div className="image-container-100 borde-img">
                        <img src={person.photo} className="image" alt="Profile"/>
                    </div>
                    <a href="/" className="text-white text-over" id="profileImageChange"><span className="mdi mdi-camera"></span></a>
                </div>
                }
                {!hasEditPermission() &&
                    <div className="image-container-100 borde-img">
                        <img src={person.photo} className="image" alt="Profile"/>
                    </div>
                }
            </div>
            <div className="col-lg-8 col-md-8  col-12">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-12 d-none d-lg-flex">
                        <div>
                            <div className="image-container-100 borde-img">
                                <img src={person.photo} className="image" alt="Profile"/>
                            </div>
                            {hasEditPermission() &&
                            <button onClick={gotoUploadWidget} className="text-white text-over no-border">
                                <span className="mdi mdi-camera"></span>
                            </button>
                            }
                        </div>
                    </div>
                    <div className="col-lg-10 col-12">
                        <div className="row">
                            <div className="col h3-title">
                                {person.fullname}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="uppercase">{person.categories}</span>
                            </div>
                        </div>
                        <div className="spacer10"></div>
                        <div className="row">
                            <div className="col">
                                <span className="mdi mdi-map-marker"></span> <small>{person.city}, {person.state}</small>
                            </div>
                        </div>
                        <div className="spacer10"></div>
                        <div className="row">
                            <div className="col">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    {currentUser && activeSession &&
                                    <>
                                    {following &&
                                    <button className="btn mr-2 button-unfollow" onClick={handleFollowClick}> <span className="mdi mdi-account-cancel"></span> DEJAR DE SEGUIR</button>
                                    }
                                    {!following &&
                                    <button className="btn mr-2 button-follow" onClick={handleFollowClick}> <span className="mdi mdi-plus-circle"></span> SEGUIR</button>
                                    }
                                    </>
                                    }
                                
                                    {currentUser && activeSession &&
                                        <>
                                        <button
                                            onClick={handleLikeClick}
                                            onAnimationEnd={() => setClicked(false)}
                                            className={cn("btn","button-follow", "mr-2","like-button-wrapper", {
                                            liked,
                                            clicked,
                                            })}      
                                        >
                                            
                                            {liked && (
                                            <div className="particles">
                                                {particleList.map((_, index) => (
                                                <div
                                                    key={index}
                                                    className="particle-rotate"
                                                    style={{
                                                    transform: `rotate(${
                                                        (360 / particleList.length) * index + 1
                                                    }deg)`,
                                                    }}
                                                >
                                                    <div className="particle-tick" />
                                                </div>
                                                ))}
                                            </div>
                                            )}
                                            
                                            <div className="like-button">
                                            <Hand />
                                            <span>ME GUSTA</span>
                                            <span className={cn("suffix", { liked })}>BA</span>
                                            </div>
                                        </button>
                                        
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-2 col-sm-4 border-left">
                <div className="row">
                    <div className="col-md-12"><span className="mdi mdi-eye"></span>&nbsp;<span className="agreements">Vistas {views}</span></div>
                </div>
                <div className="row">
                    <div className="col-md-12"><span className="mdi mdi-thumb-up"></span>&nbsp;<span className="agreements">Valoraciones {likes}</span></div>        
                </div>
                <div className="row">
                    <div className="col-md-12"><span className="mdi mdi-account-arrow-left"></span>&nbsp;<span className="agreements">Seguidores {followersCount}</span></div>
                </div>
                <div className="row">
                    <div className="col-md-12"><span className="mdi mdi-account-arrow-right"></span>&nbsp;<span className="agreements">Siguiendo {followingCount}</span></div>
                </div>
            </div>
            <div className="col-lg-2 col-12 border-left">
                <div className="spacer30 d-lg-none"></div>
                <div className="row">
                    
                    <div className="col-lg-4 col-2">
                        {facebook &&
                        <a href={facebook} target="_blank" rel="noreferrer"> <span className="mdi mdi-facebook text-black"></span></a>
                        }
                        {!facebook &&
                        <span className="mdi mdi-facebook text-light"></span>
                        }
                    </div>
                    <div className="col-lg-4 col-2">
                        {instagram &&
                        <a href={instagram} target="_blank" rel="noreferrer"> <span className="mdi mdi-instagram text-black"></span></a>
                        }
                        {!instagram &&
                        <span className="mdi mdi-instagram text-light"></span>
                        }
                    </div>
                    <div className="col-lg-4 col-2">
                        {twitter &&
                        <a href={twitter} target="_blank" rel="noreferrer"> <span className="mdi mdi-twitter text-black"></span></a>
                        }
                        {!twitter &&
                        <span className="mdi mdi-twitter text-light"></span>
                        }
                    </div>
                    <div className="col-lg-4 col-2">
                        {youtube &&
                        <a href={youtube} target="_blank" rel="noreferrer"> <span className="mdi mdi-youtube text-black"></span></a>
                        }
                        {!youtube &&
                        <span className="mdi mdi-youtube text-light"></span>
                        }
                    </div>
                    <div className="col-lg-4 col-2">
                        {vimeo &&
                        <a href={vimeo} target="_blank" rel="noreferrer"> <span className="mdi mdi-vimeo text-black"></span></a>
                        }
                        {!vimeo &&
                        <span className="mdi mdi-vimeo text-light"></span>
                        }
                    </div>
                    <div className="col-lg-4 col-2">
                        {imdb &&
                        <a href={imdb} target="_blank" rel="noreferrer"> <img src="/imdb.png" className="icon-size" alt="imdb icon"/></a>
                        }
                        {!imdb &&
                        <img src="/imdb.png" className="icon-size" alt="imdb icon"/>
                        }
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col-md-9 col-sm-12 text-center no-center">
                        <a href={web} target="_blank" rel="noreferrer" className="text-black"><strong className="text-black"><small>{webText}</small></strong></a>
                    </div>
                </div>
                

            </div>
        </div>
    </div>


    </>
  )
}

export default CastMeta;