import {React,useState,useContext, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from '../../context';


require("./styles.scss");


function IndustryCover (props) {
    const navigate = useNavigate();

    let industry = props.industry;


    const [user] = useContext(UserContext);
    const [itsMine, setItsMine] = useState(false);
    const [coverArray, setCoverArray] = useState([]);

    const location = useLocation();
    let industryId = null;

    if(location) {
        industryId = location.pathname.slice(location.pathname.lastIndexOf("/")+1 , location.pathname.length);
    }

    useEffect(() => {
        /*Check if user is logged */
        if(user && user.data){
            console.log("El usuario está firmado con id: "+user.data._id);
            /**Check if this document belongs to the logged user */
            if(user.data._id === industry.creator){  
                //console.log("El documento pertenece al usuario firmado");
                setItsMine(true);
            }
        }
        else{
            //console.log("El usuario no está firmado");
        }

        let tmpArray = [];

        let lastSegment, width, height = "";
        if(industry.cover){
            if(industry.cover.url){
                lastSegment = industry.cover.url.substring(industry.cover.url.lastIndexOf('/') + 1);
            }
            if(industry.cover.width){
                width = industry.cover.width;
            }
            if(industry.cover.height){
                height = industry.cover.height;
            }
        }

        tmpArray.push({
            url:lastSegment,
            width: width,
            height: height
        });
        setCoverArray(tmpArray);

    }, [industry, user]);
    

    function gotoUploadWidget (){
        navigate('/media', {
            state: {
              source: 'industryCover',
              id: industryId,
              currentGallery: coverArray
            }
        });
    }

    if(!industry){
        return <div>Cargando...</div>;
    }
    
    return(
        <div>
            {industry && industry.cover && industry.cover.url && 
            <div 
                 className="container-fluid bg-overlay" 
                 style={{                    
                    background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${industry.cover.url})`
                  }}>
                <div className="spacer280"></div>
                {itsMine &&
                <button onClick={gotoUploadWidget} className="text-white icon-over-cover no-border" id="openMediaGallery">
                    <span className="mdi mdi-camera"></span>
                </button>
                }
            </div>
            }
            {(!industry.cover || !industry.cover.url) && 
            <div className="container-fluid bg-overlay-no-img">
                <div className="spacer280"></div>
                {itsMine &&
                <button onClick={gotoUploadWidget} className="text-white icon-over-cover no-border" id="openMediaGallery">
                    <span className="mdi mdi-camera"></span>
                </button>
                }
            </div>
            }
        </div>
    )
}


export default IndustryCover;