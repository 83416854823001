import React from 'react';


const PortletList = ({ portlets, hasEditPermission, deletePortlet, setData, onMoveUp, onMoveDown }) => {

     
if(!portlets) {
    return <div>Cargando...</div>;  
}

  return (
    <div className='col-10 mx-auto'>
{
    console.log(portlets.length)
}
        {portlets.map((portlet, index) => (


            <li className="list-group-item clearfix" key={index}>
                {hasEditPermission() &&
                <div className="row">
                    <div className="col">
                    <span className="float-right">                       
                        {portlet && !portlet.type === "image" &&
                        <button onClick={event => setData(portlet._id, portlet.type, portlet.title, portlet.content,portlet.author, portlet.url, portlet.version)} className="noBorder"><span className="mdi mdi-pencil"></span></button>
                        }
                        
                        <button onClick={event => deletePortlet(portlet._id)} className='noBorder'><span className="mdi mdi-pencil"></span></button>

                        {index !== 0 &&
                        <button onClick={event => onMoveUp(index)} className='noBorder'><span className="mdi mdi-arrow-up"></span></button>                        
                        }

                        {index+1 !== portlets.length &&
                        <button onClick={event => onMoveDown(index)} className='noBorder'><span className="mdi mdi-arrow-down"></span></button>                        
                        }
                    </span>
                    </div>
                </div>
                }
                {portlet && portlet.type === "text" &&
                <>
                <div className="row">
                    <div className="col text-center">
                    <h1 className="h1-title">{portlet.title}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                            {portlet.content}
                            </div>
                        </div>
                    </div>
                </div>
                </>
                }
                {portlet && portlet.type === "quote" &&
                <>                
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                            <blockquote>{portlet.content}</blockquote>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <cite>{portlet.author}</cite>
                    </div>
                </div>
                </>
                }   
                {portlet && portlet.type === "link" &&
                <>                
                <div className="row">
                    <div className="col">
                        <a href={portlet.url} target="_blank" rel="noreferrer">{portlet.content}</a>
                    </div>
                </div>               
                </>
                }
                {portlet && portlet.type === "video" &&
                <>               
                <div className="row">
                    <div className="col">
                        <h1 className="h1-title">{portlet.title}</h1>
                    </div>
                </div> 
                <div className="row">
                    <div className="col">
                        {portlet.content}
                    </div>
                </div>
                <div className="row">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" title={portlet._id} src={portlet.url} allowFullScreen></iframe>
                </div>
                </div>  
                </>
                }
                {portlet && portlet.type === "image" &&
                <>               
                <div className="row">
                    <div className="col">
                        <h1 className="h1-title">{portlet.title}</h1>
                    </div>
                </div> 
                <div className="row">
                    <div className="col">
                        {portlet.content}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <img src={portlet.url}  className='img-fluid' alt={portlet.title}/>
                    </div>
                </div> 
                </>
                }

            </li>
        ))}
    </div>
  );
};

export default PortletList;