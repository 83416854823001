import {React, useEffect, useState} from 'react';
import axios from 'axios';

function UserHighlighted(props){
    const [users, setUsers] = useState([]);
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");


    useEffect(() => {
        
            let url = '';
            if (props.type === 'crew') {            
                url = `${process.env.REACT_APP_API_LOCATION}/crew/getCrewHighlighted`;
                setTitle("Perfiles destacados de crew");
                setUrl("/crew/");
                
            }
            else if (props.type === 'cast') {
                url = `${process.env.REACT_APP_API_LOCATION}/cast/getCastHighlighted`;
                setTitle("Perfiles destacados de cast");
                setUrl("/cast/");
            }

            try {
                axios.get(url)
                    .then((response) => {
                        setUsers(response.data);
                    });
            } 
            catch (error) {
                console.log(error);
            }        
        
    }, [props.type]);

    if(!users) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="container">
            <div className="spacer80"></div>
            <div className="row">
                <div className="col ">
                    <strong className="uppercase">{title}</strong>
                    <hr/>
                </div>
            </div>
            <div className="row">
            {/* Inicia cada recuadro de cada crew */}
            {users.map((user, index) => {
                return (
                    <div className="col-lg-3 col-md-4 col-sm-6 mx-auto text-center" key={index}>
                        <div className="spacer20"></div>
                        <div className="image-container borde-img">
                            <a href={url+user._id} className="text-black">
                                <img className="image" src={user.photo} alt="Imagen"/>
                            </a>
                        </div>
                        
                        <div className="title p-2">
                            <a href={url+user._id} className="text-black">{user.fullname}</a>
                        </div>
                        <div className="agreements mx-auto">
                            <strong>
                                {user.role}
                            </strong>                      
                        </div>
                        <div className="agreements mx-auto">                       
                            <span className="mdi mdi-map-marker"></span> {user.city}
                        </div>
                        <div>
                            <div className="row">
                                <div className="col"><span className="mdi mdi-eye"></span><small>&nbsp;
                                        {user.views}
                                    </small>
                                </div>
                                <div className="col"><span className="mdi mdi-thumb-up"></span><small>&nbsp;
                                    {user.likes}
                                </small>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                )
            })
            }
            </div>
        </div>
        );
    }
    
    export default UserHighlighted;