import React, {useCallback, useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";
import ProjectGallery from "./ProjectGallery";
import ProjectCrew from "./ProjectCrew";
import ProjectCast from "./ProjectCast";
import ProjectCompany from "./ProjectCompany";
import ProjectEditMenu from "./ProjectEditMenu";
import PortletList from "./PortletList";
import jwtDecode from "jwt-decode";
import axios from 'axios';
import ErrorHandler from "../common/ErrorHandler";
import ScrollToTopButton from "../common/ScrollToTopButton";
import ProjectDemo from "./ProjectDemo";

const ProjectBody = (props) => {
    const { showBoundary } = useErrorBoundary();
    
    const {project} = props
    const projectId = project._id;

    
    const [portlets, setPortlets] = useState([]);    
    const [hasError, setHasError] = useState({code:0, message:""});
    const [isLoading, setIsLoading] = useState(false);

    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [quote, setQuote] = useState("");
    const [author, setAuthor] = useState("");
    const [url, setUrl] = useState("");
    const [version, setVersion] = useState("");
    const [secure_url, setSecure_url] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [showModalQuote, setShowModalQuote] = useState(false);
    const [showModalLink, setShowModalLink] = useState(false);
    const [showModalVideo, setShowModalVideo] = useState(false);
    const [showModalImage, setShowModalImage] = useState(false);

    const [portletToEdit, setPortletToEdit] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);

    
    let decoded = null;
    
    function saveToMedia(image){
        image.userId = decoded._id;
        let target = "people/";
        
        image.public_id = 
            target 
            + decoded._id 
            + "/" 
            + image.public_id.substring(image.public_id.lastIndexOf("/") + 1, image.public_id.length);

        const url = `${process.env.REACT_APP_API_LOCATION}/media/add`;

        axios.post(url, image
        ).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        });
    }

    

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    
    const handleFileUpload = async () => {
        try {
          setIsLoading(true);
          let folder = "/people/" +  decoded._id;
          const formData = new FormData();
          formData.append('file', selectedFile);
          formData.append('upload_preset', "upload");
          formData.append('api_key', '328777567137453');
          formData.append('folder', folder);
          
    
          const response = await axios.post(
            `https://api.cloudinary.com/v1_1/cloudname/image/upload`,
            formData,
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
              },
            }
          );
    
          console.log('Imagen subida exitosamente:', response.data);
          saveToMedia(response.data);
          setUrl(response.data.secure_url);
          setVersion(response.data.version);
          setSecure_url(response.data.secure_url);
          
          let newPortlet = { 
            projectID: projectId,
            type: type,
            title: title,
            content: content,
            author: "",
            url: response.data.secure_url,
            order: 1,
            version: response.data.version,
            secure_url: response.data.secure_url
          }

          console.log(newPortlet);
          addPortlet(newPortlet);
          setShowModalImage(false);
        } catch (error) {
          console.error('Error al subir la imagen:', error);
        }
        finally{
            setIsLoading(false);
        }   
      };

    const hasEditPermission = () => {
        let result = false;
        if(localStorage.getItem("token")){
            decoded = jwtDecode(localStorage.getItem("token"));
            if (decoded._id === project.userId) {
                result = true;
            } else {
                result = false;
            }
        }

        return result;
        
    }
    
    const handleClose = () => {
        setTitle('');
        setContent('');
        setAuthor('');
        setType('');
        setUrl('');
        setShowModal(false);
        setShowModalQuote(false);
        setShowModalLink(false);
        setShowModalVideo(false);
        setShowModalImage(false);
        setPortletToEdit({});

    };
    
    function handleShow(){        
        setShowModal(true);
    }

    function handleShowQuote(){
        setShowModalQuote(true);
    } 

    function handleShowLink(){
        setShowModalLink(true);
    }

    function handleShowVideo(){
        setShowModalVideo(true);
    }

    function handleShowImage(){
        setShowModalImage(true);
    }

    
            
    const fetchPortlets = useCallback(async (cancelToken) => {
        
        if(projectId){            
            axios.get(`${process.env.REACT_APP_API_LOCATION}/project/getProjectPortlets/${projectId}`,{
                cancelToken: cancelToken.token
            })
            .then((response) => {
                setPortlets(response.data);
                //console.log(response.data);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los datos del proyecto:', error);
                }
            });
        }
    },[projectId, showBoundary]); 
    
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        fetchPortlets(cancelToken);

        return () => {
            cancelToken.cancel();
        }
        
    }, [fetchPortlets]);

    
    function verifyVideoURL(videoURL){
        let normalizedURL = videoURL;
        // eslint-disable-next-line no-useless-escape                
        const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=\w+)(?:\S+)?$/;
        // eslint-disable-next-line no-useless-escape
        const vimeoRegex = /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

        const isYouTube = youtubeRegex.test(normalizedURL);
        const isVimeo = vimeoRegex.test(normalizedURL);

        if (!isYouTube && !isVimeo) {
            alert("URL de video inválida");                    
            throw new Error('URL de video inválida');            
        }

        let videoId, embeddedUrl;

        if (isYouTube) {
            videoId = normalizedURL.match(/v=([\w-]+)/)[1];
            embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
        } 
        else {
            videoId = normalizedURL.match(vimeoRegex)[1];
            embeddedUrl = `https://player.vimeo.com/video/${videoId}`;
        }
        
        return embeddedUrl;
    }

    function handleTitleChange(e){
        setType("text");
        setTitle(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, title: e.target.value});
        }
    }
    function handleTextChange(e){
        setType("text");
        setContent(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, content: e.target.value});
        }
    }

    function handleQuoteChange(e){
        setType("quote");
        setQuote(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, content: e.target.value});
        }
    }

    function handleAuthorChange(e){
        setType("quote");
        setAuthor(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, author: e.target.value});
        }   
    }

    function handleLinkChange(e){
        setType("link");
        setUrl(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, url: e.target.value});
        }
    }

    function handleTextToLinkChange(e){
        setType("link");
        setContent(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, content: e.target.value});
        }   
    }

    function handleVideoTitleChange(e){
        setType("video");
        setTitle(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, title: e.target.value});
        }
    }

    function handleVideoTextChange(e){
        setType("video");
        setContent(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, content: e.target.value});
        }
    }

    function handleVideoLinkChange(e){
        setType("video");
        setUrl(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, url: e.target.value});
        }
    }

    function handleImageTitleChange(e){
        setType("image");
        setTitle(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, title: e.target.value});
        }
    }

    function handleImageTextChange(e){
        setType("image");
        setContent(e.target.value);
        if(portletToEdit._id){
            setPortletToEdit({...portletToEdit, content: e.target.value});
        }
    }

    const savePortlet = (e) => {
        console.log("savePortlet");
        e.preventDefault();
        if(!portletToEdit._id){
            console.log("agregar nuevo");

            let newPortlet = {};

            if(type === "text"){    
                newPortlet = { 
                    projectID: projectId,
                    type: type,
                    title: title,
                    content: content,
                    author: null,
                    url: null,
                    order:1,
                    version: 1
                };
             }
             else if(type === "quote"){    
                newPortlet = { 
                    projectID: projectId,
                    type: type,
                    title: "",
                    content: quote,
                    author: author,
                    url: null,
                    order:1,
                    version: 1
                };
             }
            else if(type === "link"){
                let normalizedURL = url;
                if(url.indexOf("https://") === -1){
                    normalizedURL = "https://" + url;
                }

                newPortlet = { 
                    projectID: projectId,
                    type: type,
                    title: "",
                    content: content,
                    author: "",
                    url: normalizedURL,
                    order:1,
                    version: 1
                }
            }
            else if(type === "video"){
                console.log("video");
                const embeddedUrl = verifyVideoURL(url);
                newPortlet = { 
                    projectID: projectId,
                    type: type,
                    title: title,
                    content: content,
                    author: "",
                    url: embeddedUrl,
                    order: 1,
                    version: 1
                }
            }
            else if(type === "image"){
                console.log("image");
                newPortlet = { 
                    projectID: projectId,
                    type: type,
                    title: title,
                    content: content,
                    author: "",
                    url: url,
                    order: 1,
                    version: version
                }
            }
            addPortlet(newPortlet);
             
        }
        else{
            console.log("modificar existente");
            console.log(portletToEdit);
            if(portletToEdit.type === "video"){
                let updatedPortlet = {};
                const embeddedUrl = verifyVideoURL(portletToEdit.url);

                console.log(embeddedUrl);
                
                updatedPortlet = { 
                    _id: portletToEdit._id,
                    projectID: projectId,
                    type: portletToEdit.type,
                    title: portletToEdit.title,
                    content: portletToEdit.content,
                    author: "",
                    url: embeddedUrl,
                    order: 1,
                    version: 1
                }

                console.log(updatedPortlet);

                updatePortlet(updatedPortlet);
                
            }
            else{
                updatePortlet(portletToEdit);
            }
                
            
        }
        setTitle('');
        setContent('');
        setAuthor('');
        setQuote('');
        setUrl('');
        setType('');
        
        setPortletToEdit({});
        
        setShowModal(false);
        setShowModalQuote(false);
        setShowModalLink(false);
        setShowModalVideo(false);
        setShowModalImage(false);
    }


    const handleMoveUp = (index) => {
        if (index === 0) return; // No se puede subir más arriba el primer elemento
        const newPortlets = [...portlets];
        const temp = newPortlets[index];
        newPortlets[index] = newPortlets[index - 1];
        newPortlets[index - 1] = temp;
        setPortlets(newPortlets);
        updatePortletOrder(newPortlets);
      };
    
      const handleMoveDown = (index) => {
        if (index === portlets.length - 1) return; // No se puede bajar más abajo el último elemento
        const newPortlets = [...portlets];
        const temp = newPortlets[index];
        newPortlets[index] = newPortlets[index + 1];
        newPortlets[index + 1] = temp;
        setPortlets(newPortlets);
        updatePortletOrder(newPortlets);
      };

      const updatePortletOrder = async (newPortlets) => {
        try {
          const updatedPortlets = newPortlets.map((portlet, index) => ({
            ...portlet,
            order: index + 1,
          }));

          console.log(updatedPortlets);
    
          await axios.put(`${process.env.REACT_APP_API_LOCATION}/project/updatePortletOrder`, updatedPortlets, { 
            headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });
        } catch (error) {
            console.error(error);
        }
          
      };

    function setData(id, type, title, content, author, url, version){
        setPortletToEdit({_id:id, type:type, title:title, content:content, author:author, url:url, version:version, secure_url:secure_url});
        
        if(type === "text"){
            handleShow();
        }
        else if(type === "quote"){
            handleShowQuote();
        }
        else if(type === "link"){
            handleShowLink();
        }
        else if(type === "video"){
            handleShowVideo();
        }
        else if(type === "image"){
            handleShowImage();
        }
    }
          
    const addPortlet = async (newPortlet) => {
        try {
            console.log("addPortlet");
            await axios.post(`${process.env.REACT_APP_API_LOCATION}/project/addPortlet`, newPortlet, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            }).then((response) => {
                console.log(response);
            }).catch((error) => {
                setHasError({code: error.response.status, message: error.response.data.error});  
                window.location.href = "/error?code=" 
                    + error.response.status 
                    + "&message=" + error.response.data.error;
            }).finally(() => {
                fetchPortlets(); // Actualiza la lista de portlets después de agregar uno nuevo
            });
        } 
        catch (error) {
            console.log(error.response);
            setHasError({code: error.response.status, message: error.response.data.error});
            console.log(hasError);
        }
      };

      const updatePortlet = async newPortlet => {   
        try {
            console.log("updatePortlet");
            const url = `${process.env.REACT_APP_API_LOCATION}/project/updatePortlet/${newPortlet._id}`;
            console.log(url);
            await axios.put(url, newPortlet, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            }).then((response) => {
                console.log(response);
            }).catch((error) => {
                setHasError({code: error.response.status, message: error.response.data.error});  
                window.location.href = "/error?code=" 
                    + error.response.status 
                    + "&message=" + error.response.data.error;
            }).finally(() => {
                fetchPortlets(); // Actualiza la lista de portlets después de agregar uno nuevo
            });
        } 
        catch (error) {
            console.log(error.response);
            setHasError({code: error.response.status, message: error.response.data.error});
            console.log(hasError);
        }
        
      };

      const deletePortlet = async id => {        
        const portletId = id;
        //console.log("deletePortlet "+portletId);
        if(window.confirm("¿Quieres eliminar esta fila?")){
            const url = `${process.env.REACT_APP_API_LOCATION}/project/deletePortlet/${portletId}`;
            try {
                await axios.delete(url, {
                    headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
                });
            } 
            catch (error) {
                console.error(error);
                setHasError({code: error.response.status, message: error.response.data.error});  
                    window.location.href = "/error?code=" 
                        + error.response.status 
                        + "&message=" + error.response.data.error;
            } finally {
                fetchPortlets();
            }
        }
      };

     if (!project) {
        return <div>Cargando...</div>;
     }
      
      if (hasError.code > 0) return <ErrorHandler code={hasError.code} title={hasError.message}/>
    return(
        <div>

            {hasEditPermission() && 
            
            <ProjectEditMenu                 
                handleShow={handleShow}
                handleShowQuote={handleShowQuote}
                handleShowLink={handleShowLink}
                handleShowVideo={handleShowVideo}
                handleShowImage={handleShowImage}
            />
            }
            {project.proj_external_view &&
            <div>
                <div className="spacer40"></div>
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <a href={project.proj_external_view} target="_blank" rel="noreferrer" className="btn btn-purple text-white"><span className="mdi mdi-filmstrip"></span> Ver Película</a>
                  </div>
                </div>
                <div className="spacer40"></div>
            </div>
            }
            {project.project_desc &&
                <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <h3 className="h1-title">DESCRIPCIÓN / SINÓPSIS</h3>
                        <hr/>
                    </div>
                </div>
                <div className="spacer40"></div>
                <div className="row">
                    <div className="col">
                        {project.project_desc}
                    </div>
                </div>
                <div className="spacer40"></div>
                </div>  
            }
            <div className="row">
                <div className="col-10 mx-auto">
                    <ProjectDemo project={project}/>
                </div>
            </div>
            <div className="spacer40"></div>
            
            {project.gallery && project.gallery.length > 0 &&
            <div className="container-fluid text-center mx-auto justify-content-center">
                <div className="spacer40"></div>
                <div className="row">
                    <div className="col-lg-12">
                        <strong className="h1-title">GALERÍA</strong>
                        <ProjectGallery project={project}/>
                    </div>
                </div>
                <div className="spacer20"></div>
                <div className="row">
                    <div className="col">
                        <hr/>
                    </div>
                </div>
                <div className="spacer40"></div>
            </div>
            
            }
            <div className="spacer20"></div>
            <ProjectCrew projectId={projectId}/>
            <div className="spacer20"></div>
            <ProjectCast projectId={projectId}/>
            <ProjectCompany projectId={projectId}/>
            
            <PortletList 
                portlets={portlets} 
                hasEditPermission={hasEditPermission} 
                deletePortlet={deletePortlet} 
                portletToEdit={portletToEdit}
                handleShow={handleShow}
                setData={setData}
                onMoveUp={handleMoveUp} 
                onMoveDown={handleMoveDown}
            />

            <div className="spacer80"></div>

            <ScrollToTopButton/>
            
            {/*Modal para portlet de texto*/}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>TEXTO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>Introduce un Título y el Texto que deseas agregar a tu proyecto. 
                    Una vez creado puedes arrastrarlo para establecer el orden en el que 
                    quieres que aparezca.
                </p>
                <div style={{width:'100%'}}>
                    <label htmlFor="title">Título <span className="text-purple">(Requerido)</span></label>
                    <input 
                        type="text" 
                        className="form-control" 
                        onChange={(e) => handleTitleChange(e)}
                        defaultValue={portletToEdit.title}
                    />
                </div>
                    
                <div className="spacer20"></div>
                <div style={{width:'100%'}}>
                    <label htmlFor="content">Texto <span className="text-purple">(Requerido)</span></label>
                    <textarea 
                        rows="4" 
                        className="form-control" 
                        cols="50" 
                        onChange={(e) => handleTextChange(e)}
                        defaultValue={portletToEdit.content}
                    ></textarea>
                </div>
                <div className="spacer20"></div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={savePortlet}>
                    Guardar
                </Button>
                </Modal.Footer>
            </Modal>

            {/*Modal para frase*/}
            <Modal show={showModalQuote} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>FRASE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>Introduce una frase y un autor. Una vez creado puedes arrastrarlo para establecer el 
                    orden en el que quieres que aparezca.
                </p>
                <div style={{width:'100%'}}>
                    <label htmlFor="quote">Frase <span className="text-purple">(Requerido)</span></label>
                    <textarea 
                        rows="4" 
                        className="form-control" 
                        cols="50" 
                        onChange={(e) => handleQuoteChange(e)}
                        defaultValue={portletToEdit.content}
                    ></textarea>
                </div>
                
                    
                <div className="spacer20"></div>
                <div style={{width:'100%'}}>
                    <label htmlFor="author">Autor <span className="text-purple">(Requerido)</span></label>
                    <input 
                        type="text" 
                        className="form-control" 
                        onChange={(e) => handleAuthorChange(e)}
                        defaultValue={portletToEdit.author}
                    />
                </div>
                <div className="spacer20"></div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={savePortlet}>
                    Guardar
                </Button>
                </Modal.Footer>
            </Modal>

            {/*Modal para link*/}
            <Modal show={showModalLink} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>ENLACE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>Introduce un texto para tu enlace y la URL correspondiente. 
                    Una vez agregada la nueva sección puedes arrastrarla para establecer el orden 
                    en el que quieres que aparezca.
                </p>
                <div style={{width:'100%'}}>
                    <label htmlFor="link">Texto del enlace <span className="text-purple">(Requerido)</span></label>
                    <input 
                        type="text" 
                        className="form-control" 
                        onChange={(e) => handleTextToLinkChange(e)}
                        defaultValue={portletToEdit.content}
                    />
                </div>
                <div style={{width:'100%'}}>
                    <label htmlFor="link">URL del enlace <span className="text-purple">(Requerido)</span></label>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon3">https://</span>
                        </div>
                        <input 
                            type="text" 
                            className="form-control" 
                            onChange={(e) => handleLinkChange(e)}
                            defaultValue={portletToEdit.url}
                        />
                    </div>
                    
                </div>                                                    
                
                <div className="spacer20"></div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={savePortlet}>
                    Guardar
                </Button>
                </Modal.Footer>
            </Modal>

            {/*Modal para video*/}
            <Modal show={showModalVideo} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>ENLACE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>
                    Introduce un Título y la URL del video de vimeo o youtube que quieres agregar. 
                    Una vez agregada la nueva sección puedes arrastrarla para establecer el orden en 
                    el que quieres que aparezca.
                </p>
                <div style={{width:'100%'}}>
                    <label htmlFor="title">Título <span className="text-purple">(Requerido)</span></label>
                    <input 
                        type="text" 
                        className="form-control" 
                        onChange={(e) => handleVideoTitleChange(e)}
                        defaultValue={portletToEdit.title}
                    />
                </div>
                <div className="spacer20"></div>
                <div style={{width:'100%'}}>
                    <label htmlFor="content">Descripción <span className="text-purple">(Requerido)</span></label>
                    <textarea 
                        rows="4" 
                        className="form-control" 
                        cols="50" 
                        onChange={(e) => handleVideoTextChange(e)}
                        defaultValue={portletToEdit.content}
                    ></textarea>
                </div>
                <div style={{width:'100%'}}>
                    <label htmlFor="link">URL de Vimeo o Youtube <span className="text-purple">(Requerido)</span></label>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon3">https://</span>
                        </div>
                        <input 
                            type="text" 
                            className="form-control" 
                            onChange={(e) => handleVideoLinkChange(e)}
                            defaultValue={portletToEdit.url}
                        />
                    </div>
                    
                </div>                                                    
                
                <div className="spacer20"></div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={savePortlet}>
                    Guardar
                </Button>
                </Modal.Footer>
            </Modal>

            {/*Modal para imagen*/}
            <Modal show={showModalImage} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>IMAGEN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>
                    Introduce un Título y selecciona la imágen que quieres agregar. 
                    Una vez agregada la nueva sección puedes arrastrarla para establecer el orden 
                    en el que quieres que aparezca.
                </p>
                <div style={{width:'100%'}}>
                    <label htmlFor="title">Título <span className="text-purple">(Requerido)</span></label>
                    <input 
                        type="text" 
                        className="form-control" 
                        onChange={(e) => handleImageTitleChange(e)}
                        defaultValue={portletToEdit.title}
                    />
                </div>
                <div className="spacer20"></div>
                <div style={{width:'100%'}}>
                    <label htmlFor="content">Descripción <span className="text-purple">(Requerido)</span></label>
                    <textarea 
                        rows="4" 
                        className="form-control" 
                        cols="50" 
                        onChange={(e) => handleImageTextChange(e)}
                        defaultValue={portletToEdit.content}
                    ></textarea>
                </div>
                <div style={{width:'100%'}}>

                    <input type="file" className="custom-file-input" id="inputGroupFile01"
                    aria-describedby="inputGroupFileAddon01" onChange={handleFileChange} style={{width:'100%'}}/>
                    <label className="custom-file-label" htmlFor="inputGroupFile01">Imagen<span className="text-purple">(Requerido)</span></label>

                    
                </div>                                                    
                
                <div className="spacer20"></div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleFileUpload}>
                    Guardar
                </Button>
                {isLoading && <Spinner animation="border" variant="primary" />}
                </Modal.Footer>
            </Modal>
        </div>
    )
    
}

export default ProjectBody;