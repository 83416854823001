import {React} from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/dist/photoswipe.css';

export default function CastGallery(props) {

    const {person} = props;

    console.log(person);


  const lightbox = new PhotoSwipeLightbox({
    gallery: "#my-gallery",
    children: "a",
    
    pswpModule: () => import("photoswipe")
  });
  
  lightbox.init();

  if (!person || !person.gallery) {
    return <div>Cargando...</div>;
  }

  return (    
    <>
    {person && person.gallery && person.gallery.length > 0 &&
    <>
    <div className="spacer40"></div>
    <div className="row">
        <div className="col ">
            <strong className="uppercase">Galería de book</strong>
            <hr/>
        </div>
    </div>
    <div className="spacer20"></div>
    </>
    }
    <div className="pswp-gallery" id="my-gallery">

      {person.gallery.map((image, index) => (

        <a
          href={image.url}
          data-pswp-width={image.width}
          data-pswp-height={image.height}
          target="_blank"
          rel='noreferrer'
          key={index}
        >
          <img
            src={image.url}
            alt=""
            className="gallery-separator thumbnail"
          />
        </a>
        ))}
        
      </div>
      
    </>
  );
}