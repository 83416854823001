import React, { useState, useContext } from 'react'
import axios from 'axios';
import { UserContext } from '../../context';
import {Link, useNavigate} from 'react-router-dom';

import Header from '../common/Header';
import Footer from '../common/Footer';

const Login = () => {
    const [state, setState] = useContext(UserContext);
    const navigate = useNavigate();
/*    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');*/


    const[data, setData] = useState({
        email: "",
        password: "",
    });

    const [error, setError] = useState("");

    const handleChange = ({currentTarget: input}) => { 
        setData({...data, [input.name]: input.value});
    };

    const handleSubmit = async (e) => {   
        let response;
        e.preventDefault();
        
        try {            
            const {data:loginData} = await axios.post(`${process.env.REACT_APP_API_LOCATION}/auth/login`, {
                email: data.email,
                password: data.password
            });
            response = loginData;


            if(response.errors.length){
                //setErrorMsg(response.errors[0].msg);
                return setError(response.errors[0].msg);
            }

            console.log(state,"Login");

            //This will trigger the fetchUser function in the context
            setState({
                data: {
                    _id: response.data.user._id,
                    email: response.data.user.email,
                    name: response.data.user.name,
                    lastname: response.data.user.lastname,
                    fullname: response.data.user.fullname,
                    photo: response.data.user.photo,
                    role: response.data.user.role,
                    isCrew: response.data.user.isCrew,
                    isCast: response.data.user.isCast,
                    stripeCustomerId: response.data.user.stripeCustomerId
                },
                error: null,
                loading: false
            });
    
            localStorage.setItem('token', response.data.token);
            axios.defaults.headers.common['authorization'] = `Bearer ${response.data.token}`;
            navigate('/');
        } 
        catch (error) {
            if(error.response && 
                error.response.status >= 400 &&
                error.response.status <= 500) {
                setError(error.response.data.message);
            }
        }
    };

    return(

        <div className="login">
            <Header/>
            <div className="m-height">
                <div className="container text-center">
                    <h1 className="h1-title">¡Te damos la bienvenida de nuevo!</h1>
                    <br/>
                    <p className="text-description">
                    Inicia sesión para conectar con tu red o <Link to="/register" className="join">Únete ahora</Link> si aún no tienes una cuenta
                    </p>
                </div>      

                <div className="col-md-4 mx-auto">
                    <div className="spacer20"></div>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="InputEmail">Correo:</label>
                                <input 
                                    type="email" 
                                    placeholder="Ingresa tu correo"
                                    name="email" 
                                    value={data.email}
                                    onChange={handleChange}
                                    required
                                    className="form-control" 
                                    aria-describedby="emailHelp" 
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="InputPassword">Contraseña:</label>
                                <input 
                                    type="password" 
                                    name="password" 
                                    placeholder="Ingresa tu contraseña"
                                    value={data.password}
                                    onChange={handleChange}
                                    required
                                    className="form-control" 
                                    aria-describedby="passwordHelp"
                                />
                            </div>
                            <div className="form-group mx-auto">
                                {error && <div className="alert alert-danger">{error}</div>}
                                <button type="submit" className="btn btn-purple">Iniciar sesión</button>
                            </div>
                        </form>
                    <p className="text-description">
                        <a href="/recover" className="recover">¿Has olvidado tu contraseña?</a>
                    </p>
                    
                </div>
                <div className="spacer60"></div>
            </div>
            <Footer/>
        </div>
        
    )
}

export default Login;