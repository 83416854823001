import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { UserContext } from '../context';

export const ProtectedRoute = () => {
    const [state] = useContext(UserContext);

    //console.log(state,"state in ProtectedRoute");

    if(state.loading){
        return <div>Cargando...</div>;
    }

    return state.data ? <Outlet/> : <Navigate to="/"/>
};