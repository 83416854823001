import React from "react";
import config from "../../config";
import axios from "axios";
import jwtDecode from "jwt-decode";

import { EditTextarea } from 'react-edit-text';
import {formatearURL} from "../common/FormatFunctions";


function IndustryAbout(props) {
  const industry = props.industry;
  
  let decoded = null;


  const hasEditPermission = () => {
    let result = false;
    if(localStorage.getItem("token")){
        decoded = jwtDecode(localStorage.getItem("token"));
        if (decoded._id === industry.creator) {
            result = true;
        } else {
            result = false;
        }
    }
    return result;
    
  }


  const handleSaveAbout = ({ name, value, previousValue }) => {
        //alert(name + ' saved as: ' + value + ' (prev: ' + previousValue + ')');
        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/industry/updateDescription`;
            axios.put(url, {
                companyId: industry._id,
                company_desc: value
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });
        } catch (error) {
            console.log(error);
        }            
    };

   

    let web = industry.company_web_page ?? '';

    if(web !== ''){
        web = formatearURL(web);
    }

  return (
    <>
    
    {industry.description &&
    <div>
        <div className="spacer40"></div>
        <div className="row">
            <div className="col">
                <div className="spacer40  d-lg-none"></div>
                <strong className="uppercase">Acerca de {industry.name}</strong>
                <hr/>
            </div>
        </div>
        <div className="spacer20"></div>
        <div className="row">
            <div className="col about">
            {hasEditPermission() &&
            <EditTextarea
                name="company_description"                 
                defaultValue={industry.description} 
                editButtonProps={{ style: { marginLeft: '5px', width: 16, fill: '#ED1567' } }}
                showEditButton
                onSave={handleSaveAbout}
            />
            }
            {!hasEditPermission() &&
                industry.description
            }
            </div>
        </div>
        <div className="spacer40"></div>
        <div className="row">
            <div className="col">
                <a href={web} target="_blank" rel="noreferrer" className="text-gray"><strong className="text-gray"><small>{industry.company_web_page}</small></strong></a>
            </div>
        </div>
    </div>
    }
    </>
  );
}

export default IndustryAbout;