import React from 'react'

function FormButton({submit, children, onClick, className, disabled}) {
  const buttonType = submit ? 'submit' : 'button';
  return (
      <button type={buttonType} onClick={onClick} className={className} disabled={disabled}>
          {children}
      </button>
  )
}

export default FormButton