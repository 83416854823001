import {React, useState, useEffect} from "react";
import { useErrorBoundary } from "react-error-boundary";
import axios from "axios";
import './styles.scss';


function ProjectCrew (props) {
    const { showBoundary } = useErrorBoundary();
    const [crew, setCrew] = useState([]);
    const projectId = props.projectId;

    /**
     * Paginación
     */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(9);

    // Calcula el número total de páginas
    const totalPages = Math.ceil(crew.length / itemsPerPage);

    // Calcula el índice del primer elemento en la página actual
    const startIndex = (currentPage - 1) * itemsPerPage;

    // Obtiene los elementos que se mostrarán en la página actual
    const currentItems = crew.slice(startIndex, startIndex + itemsPerPage);    

    // Maneja el cambio de página hacia atrás
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setItemsPerPage(9); //Esta línea sólo es para que no marque warning por no usar setItemsPerPage
        }
    };

    // Maneja el cambio de página hacia adelante
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            setItemsPerPage(9); //Esta línea sólo es para que no marque warning por no usar setItemsPerPage
        }
    };


    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if(projectId){
            
            axios.get(`${process.env.REACT_APP_API_LOCATION}/crew/getCrewOfProject/${projectId}`, {
                cancelToken: cancelToken.token 
            })
            .then((response) => {
                setCrew(response.data);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener el crew del proyecto:', error);
                }
            });
            

            return () => {
                cancelToken.cancel();
            }
            
        }
    }, [projectId, showBoundary]);


    return(
        <>        
        {crew && crew.length > 0 &&
        <div className="container">
            <div className="row">
                <div className="col">
                    <strong className="h1-title">CREW</strong>
                    <hr/>
                </div>
            </div>
            <div className="spacer20"></div>
            
            <div className="row mb-2">
                <div className="col">
                    <div className="row">
                    {currentItems.map((person, index) => {
                            let url = '';
                                url = '/crew/' + person.id;
                            
                            return(
                                <div className="col-lg-3 col-md-4 staffContainer" key={index}>                               
                                    <a href={url}>
                                        <img 
                                            src={person.photo} 
                                            className="staffImg" 
                                            data-toggle="tooltip" 
                                            data-placement="top" 
                                            title={person.fullname} 
                                            alt="Profile"
                                        />
                                        
                                        <span className="people-name-text-circle one-line text-center">{person.fullname}</span>
                                        <span className="people-role-text-circle uppercase one-line text-center">{person.role}</span>
                                        
                                    </a>
                                </div>
                           );
                        })}
                        
                    </div>
                    {totalPages > 1 &&
                    <div className="row">
                        <div className="col-6 text-center">
                            <button aria-label="Previous" className="btn text-black" onClick={handlePrevPage} disabled={currentPage === 1}>
                                <i className="mdi mdi-arrow-left-circle text-black"></i>
                                
                            </button>
                        </div>
                        <div className="col-6 text-center">
                            <button aria-label="Next" className="btn text-black" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                <i className="mdi mdi-arrow-right-circle text-black"></i>
                            </button>                            
                        </div>
                    </div>
                    }
                </div>
                
            </div>
            
        </div>
        }
        </>
    )
};

export default ProjectCrew;