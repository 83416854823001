import {React} from "react";

function IndustryCollaborators(props) {


    const industry = props.industry;

    return (
        <>
        {industry && industry.staff && industry.staff.length > 0 &&
        <div>
            <div className="spacer40"></div>
            <div className="row">
                <div className="col">
                    <strong className="uppercase">{industry.collaborator_section_title}</strong>
                    <hr/>
                </div>
            </div>
            <div className="spacer20"></div>
            
            <div className="row mb-2">
                
                <div className="col">
                    <div className="row">
                        {industry.staff.map((collaborator, index) => (
                        <div className="col-lg-3 col-md-4 staffContainer" key={index}> 
                                                     
                        <a href={'/crew/' + collaborator._id}>
                            <img 
                                src={collaborator.photo} 
                                className="staffImg" 
                                data-toggle="tooltip" 
                                data-placement="top" 
                                title={collaborator.name} 
                                alt="Profile"
                            />
                            
                            <span className="people-name-text-circle one-line text-center">{collaborator.name}</span>
                        </a>
                    </div>
                        ))}
                    </div>
                </div>
                
            </div>
            {/*allCrewIFollow.length > limitedCrewIFollow.length && 
            <div className="row">
                <div className="col text-right about"><a href="/" data-toggle="modal" data-target="#modalCrewIFollow">Ver todos</a></div>
            </div>
                        
            <ModalMoreCrewIFollow followers={allCrewIFollow}/>*/}
        </div>
        }
        </>
    )
}

export default IndustryCollaborators;