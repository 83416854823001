import { useCallback, useEffect, useState, useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Header from "../../common/Header";
import Footer from "../../common/Footer";
import ErrorHandler from "../../common/ErrorHandler";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import jwt_decode from "jwt-decode";
import axios from "axios";
import 'react-tabs/style/react-tabs.css';
import { UserContext } from "../../../context";

function IndustryList (){
    const [industries, setIndustries] = useState([]);
    const [state] = useContext(UserContext);
    const [PLAN, setPLAN] = useState("FREE");
    const [totalIndustries, setTotalIndustries] = useState(0);
    const membershipLimits = {
        FREE: 0,
        PRO: 2,
        PREMIUM: 100000
      };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const code = 200;

    const getUserIndustries = useCallback(async () => {
        let decoded = jwt_decode(localStorage.getItem("token"));
        const url = `${process.env.REACT_APP_API_LOCATION}/industry/getCompaniesFromUser/${decoded._id}`;
        await axios.get(url)
        .then(response => {
            console.log(response.data);
            setIndustries(response.data);
        }).catch(error => {
            console.log(error);
        });
    }, []);


    useEffect(() => {
        getUserIndustries();
        setPLAN(state.data.plan);
        setTotalIndustries(industries.length);
    }, [getUserIndustries, industries.length, state.data.plan]);

    //console.log(industries);

    return (

        <div>
        <Header/>
        {industries.length > 0 &&
            <>
                <div className="container-fluid text-center">
                    <div className="col-md-12">
                        <div className="spacer20"></div>

                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="spacer40"></div>
                                <div className="card h-250">
                                {totalIndustries >= membershipLimits[PLAN] &&
                                    <>
                                    <div onClick={handleShow} style={{cursor:"pointer"}}>
                                        <img className="card-img-top cover-top" src="/no-image-logo.png" alt="Cover"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Crear nueva empresa</h5>
                                            <div className="card-text text-center"><h1 className="text-purple"><span className="mdi mdi-plus"></span></h1></div>
                                        </div>
                                    </div>
                                    </>
                                }
                                {totalIndustries < membershipLimits[PLAN] &&
                                    <>
                                    <a href="/IndustryAdd" className="text-black a100"><img className="card-img-top cover-top" src="/no-image-logo.png" alt="Cover"/></a>
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="/IndustryAdd" className="text-black">Crear nueva empresa</a></h5>
                                        <div className="card-text text-center"><a href="/IndustryAdd" className="text-black"><h1 className="text-purple"><span className="mdi mdi-plus"></span></h1></a></div>
                                    </div>
                                    </>
                                }
                                </div>
                            </div>
                            <div className="col-md-9">
                                <main role="main">
                                    <div className="album bg-light">
                                        <div className="container-fluid">
                                            <Tabs align="left">
                                                <TabList>
                                                    <Tab>Empresas</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <div className="row">
                                                        {
                                                            industries.map((industry, index) => {
                                                                return (
                                                                    <div className="col-md-4 col-sm-6" key={index}>
                                                                        <div className="card w-100">
                                                                            <a href={"/industry/"+industry._id} className="a100"><img className="card-img-top cover-top" src={industry.logo} alt="background"/></a>
                                                                            <div className="card-body">
                                                                                <h5 className="card-title"><a href={"/industry/"+industry._id} className="text-black">{industry.name}</a></h5>
                                                                                {industry.company_year &&
                                                                                <p className="card-text">{industry.company_year}</p>
                                                                                }
                                                                                {!industry.company_year &&
                                                                                <p className="card-text">Año desconocido</p>
                                                                                }


                                                                            </div>
                                                                            <div className="card-footer">
                                                                                
                                                                                <div className="row">
                                                                                    <div className="col"><hr/></div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col card-text"><a href={"/industry/"+industry._id} className="text-black"><span className="mdi mdi-eye"></span></a></div>
                                                                                    <div className="col card-text text-center"><a href={"/industryForm/"+industry._id} className="text-black"><span className="mdi mdi-pencil"></span></a></div>
                                                                                    <div className="col card-text text-right"><a href="/" id="deleteIndustry" data-id="NpPRhQ7fDXgSafvJi" className="text-black"><span className="mdi mdi-trash-can" data-id="NpPRhQ7fDXgSafvJi"></span></a></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>

                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        {industries.length <= 0  &&
            <>
                <div className="container-fluid padding-0">
                    <nav>
                        <div className="pink">Mis empresas</div>
                    </nav>
                </div>
                <div className="container-fluid text-center">
                    <div className="col-md-12">
                        <div className="spacer20"></div>
                            <p> No tienes empresas, agrega tu primer empresa</p>
                            <div className="row">
                                <div className="col-md-3 col-sm-6">
                                    <div className="spacer40"></div>
                                    <div className="card h-250">
                                    <a href="/industryName" className="text-black a100"><img className="card-img-top cover-top" src="/no-image-logo.png" alt="Cover"/></a>
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="/" className="text-black createProject">Crear nueva empresa</a></h5>
                                        <div className="card-text text-center"><a href="/IndustryAdd" className="text-black"><h1 className="text-purple"><span className="mdi mdi-plus"></span></h1></a></div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="spacer20"></div>
                            <div className="spacer80"></div>
                    </div>
                </div>
                
            </>
            }
        {code!==200 &&
            <ErrorHandler code={code} title="Ruta o parámetro"/>
        }
        <Footer/>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Has alcanzado tu límite</Modal.Title>
            </Modal.Header>
            <Modal.Body>Tu membresía sólo te permite crear {membershipLimits[PLAN]} empresas. Puedes adquirir una <a href="/subscription">membresía superior</a> para eliminar este límite </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cerrar
            </Button>
            
            </Modal.Footer>
        </Modal>
    </div>
    )
}

export default IndustryList;