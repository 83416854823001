import React from 'react';

import CrewFilmography from './CrewFilmography';
import CrewFollowers from './CrewFollowers';
import CrewAbout from './CrewAbout';
import CrewFollowing from './CrewFollowing';
//import IndustryIFollow from './IndustryIFollow';

function CrewSidebar(props) {
    
    const {person} = props;
    let id = "";
    let name = "";

    if(!person && !JSON.stringify(person) === "{}"){
        
        return <div> Cargando ... </div>
    }
    id = person._id;
    name = person.fullname;

    //extrayendo el nombre sin apellidos
    if(name){
        name = name.split(" ")[0];
    }

    return (
        <div>
            <CrewAbout person={person}/>
            <div className="spacer20"></div>
            <CrewFilmography personId={id}/>            
            <div className="spacer20"></div>
            <CrewFollowers personId={id} name={name}/>
            <div className="spacer20"></div>
            <CrewFollowing personId={id} type="crew" name={name}/>
            <div className="spacer20"></div>
            <CrewFollowing personId={id} type="cast" name={name}/>
            {
            //<IndustryIFollow/>
        }
        </div>
    )
}

export default CrewSidebar;