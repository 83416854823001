import {React, useEffect, useState} from 'react'
import { useErrorBoundary } from "react-error-boundary";
import config from "../../../config";
import axios from "axios";

function FormSwitchList({data, toggleFunction, disabled, checkedValues}) {
    const { showBoundary } = useErrorBoundary();
    const [catalog, setCatalog] = useState();

    const handleInputChange = (event) => {
        const selectedValue = event.target.value;
        toggleFunction(selectedValue);
      };

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        axios.get(`${process.env.REACT_APP_API_LOCATION}/cast/catalog/${data}`,{
            cancelToken: cancelToken.token,
        })
        .then((response) => {
            setCatalog(response.data);
        })
        .catch((error) => {
            if(axios.isCancel(error)){
                console.log("Request cancelled");
            }
            else{
                console.error(`Error al obtener el catálogo ${data}`, error);
                showBoundary(error);
            }
        });

        return () => {
            console.log("cleanup");
        }

    }, [showBoundary,data])


    if(!catalog){
        return <div>Cargando...</div>
    }


  return (
    <>
    {catalog && catalog.map((item, index) => (
        <div key={index} className="custom-control custom-switch">
            <input 
                type="checkbox" 
                className="custom-control-input" 
                onChange={handleInputChange} 
                value={item.value} 
                disabled={disabled}
                /*checked={checkedValues ? checkedValues[item.value] : false}*/
                checked={checkedValues && checkedValues.length > 0 ? checkedValues.includes(item.value): false}
            />
            <label className="custom-control-label" htmlFor="customSwitches">{item.label}</label>
        </div>
    ))} 
    </>
  )
}

export default FormSwitchList;