import {React, useState, useEffect, useCallback, useRef}  from 'react';
import axios from 'axios';
import { 
    projectCategories, 
    projectGenres, 
    projectYears, 
    projectStatus,
    projectFamilies 
} from './ProjectCatalogs';


function ProjectFilters(props) {
    const [name, setName] = useState('');
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    
    const mode = useRef('P');
    
    const selectForm = useRef(null);    
    const queryObject = useRef({});
    const family = useRef('');
    const category = useRef('');
    const status = useRef('');
    const genre = useRef('');
    const year = useRef('');
    const ocupation = useRef('');
    

    /**Cada que se escribe en el texto de búsqueda se invoca esta función */
    function handleChange(e) {        
        setName(e.target.value);
    }

    /**Cuando se elige una familia se invoca esta función */
    function handleFamily(e) {
        family.current = e.target.value;
        mode.current = e.target.value;
        handleQuery();
    }

    /**Cuando se elige una categoría se invoca esta función */
    function handleCategory(e) {
        category.current = e.target.value;
        handleQuery();
    }

    /**Cuando se elige un estatus se invoca esta función */
    function handleStatus(e) {
        status.current = e.target.value;
        handleQuery();
    }

    /**Cuando se elige un género se invoca esta función */
    function handleGenre(e) {
        genre.current = e.target.value;
        handleQuery();
    }

    /**Cuando se elige un año se invoca esta función */
    function handleYear(e) {
        year.current = e.target.value;
        handleQuery();
    }

    /**Cuando se elige un rol se invoca esta función */
    function handleRole(e) {
        ocupation.current = e.target.value;
        handleQuery();
    }

    /**Cuando se hace un submit */
    const handleSubmit = (e) => {
        e.preventDefault();
        handleQuery();
    }

    function handleQuery(){
        
        if(name!==''){
            queryObject.current.text = name;
        }
        else{   
            delete queryObject.current.text;
        }

        if(family.current!=='' && family.current!=='cualquier'){
            queryObject.current.project_family = family.current;
        }
        else{
            delete queryObject.current.project_type;
        }

        if(category.current!=='' && category.current!=='cualquier'){
            queryObject.current.project_type = category.current;
        }
        else{
            delete queryObject.current.project_type;
        }

        if(status.current!=='' && status.current!=='cualquier'){
            queryObject.current.project_status = status.current;
        }
        else{
            delete queryObject.current.project_status;
        }

        if(genre.current!=='' && genre.current!=='cualquier'){
            queryObject.current.project_genre = genre.current;
        }
        else{
            delete queryObject.current.project_genre;
        }

        if(year.current!=='' && year.current!=='cualquier'){
            queryObject.current.project_year = year.current;
        }
        else{
            delete queryObject.current.project_year;
        }

        if(ocupation.current!=='' && ocupation.current!=='cualquier'){
            queryObject.current.role = ocupation.current;
        }
        else{
            delete queryObject.current.role;
        }

        
        
        props.onSubmitCallback(queryObject.current);
    }
    
    /**Cuando se elige un departamento se invoca esta función */
    async function updateRoles (event){
        if(event.target.value === 'cualquier'){
            ocupation.current = '';
            handleQuery();
        }
        
        /**No lleva else porque el back sabe cómo devolver los valores */
        const url = `${process.env.REACT_APP_API_LOCATION}/crew/getRolesFromDepartment`;
        const response = await axios.post(url,{
            "category": event.target.value
        });       
        setRoles(response.data.roles);
    };    

    const loadDepartments = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/crew/getDepartments`;
        const response = await axios.get(url);
        setDepartments(response.data);
    }, []);

    useEffect(() => {
        loadDepartments();
    }, [loadDepartments]);

    return (
    <div>
        <div className="sticky-top background-verylightgray rounded  p-3">
            <form ref={selectForm} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col"><span className="mdi mdi-tune"></span>&nbsp;Filtrar búsqueda<span className="mdi mdi-plus btn-fade" id="btn-fade"></span><span className="mdi mdi-minus btn-fade" id="btn-hide"></span></div>
            </div>
		    <div className="sec-fade" id="sec-fade">
                <div className="spacer10"></div>
                
                <div className="row">
                    <div className="col">
                        <label htmlFor="family" className="agreements"><strong>Tipo de trabajos</strong> </label>
                        <select 
                            name="family" 
                            id='family'
                            className="form-control agreements" 
                            onChange={(e) => handleFamily(e)}
                        >
                            
                            {projectFamilies.map((item, index) => (
                                <option key={index} value={item.value}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col agreements"><strong>Nombre</strong></div>
                </div>
                <div className="row">
                    <div className="col agreements">
                        <input 
                            type="text" 
                            className="form-control" 
                            name="text" 
                            placeholder="Título, descripción, palabras clave"
                            value={name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                {mode.current==='P' && 
                <>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="category" className="agreements"><strong>Tipo de producción</strong> </label>
                        <select 
                            name="category" 
                            id='category'
                            className="form-control agreements" 
                            onChange={(e) => handleCategory(e)}
                        >
                            <option value="cualquier"> Cualquiera </option>
                            {projectCategories.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="status" className="agreements"><strong>Estatus</strong> </label>
                        <select 
                            name="status" 
                            id='status'
                            className="form-control agreements" 
                            onChange={(e) => handleStatus(e)}
                        >
                            <option value="cualquier"> Cualquiera </option>
                            {projectStatus.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="gender" className="agreements"><strong>Género</strong> </label>
                        <select 
                            name="gender" 
                            id='gender'
                            className="form-control agreements" 
                            onChange={(e) => handleGenre(e)}
                        >
                            <option value="cualquier"> Cualquiera </option>
                            {projectGenres.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="year" className="agreements"><strong>Año</strong> </label>
                        <select 
                            name="year" 
                            id='year'
                            className="form-control agreements" 
                            onChange={(e) => handleYear(e)}
                        >
                            <option value="cualquier"> Cualquiera </option>
                            {projectYears.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>

                </>
                }

                {mode.current==='M' && 
                <>
                    <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="category" className="agreements"><strong>Departamento</strong> </label>
                        <select 
                            name="category" 
                            id='category'
                            className="form-control agreements" 
                            onChange={(e) => updateRoles(e)}
                        >
                            <option value="cualquier"> Cualquier departamento </option>
                            {departments.map((item, index) => (
                                <option key={index} value={item.category}>{item.category}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col">
                        <label className="agreements" htmlFor="role"><strong>Ocupación</strong> </label>
                        <select 
                            name="role" 
                            id='role'
                            className="form-control agreements"
                            onChange={(e) => handleRole(e)}
                        >
                            <option value="cualquier"> Cualquier ocupación </option>
                            {roles.map((item, index) => (
                                <option key={index} value={item.roleName}>{item.roleName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                </>
                }


                
            </div>
            </form>
          
        </div>
    </div>
  );
}

export default ProjectFilters;