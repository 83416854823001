import React from 'react';
import IndustryAbout from './IndustryAbout';
import IndustryCollaborators from './IndustryCollaborators';
//import MyFollowers from './MyFollowers';
/*
import CastAbout from './CastAbout';
import CastFilmography from './CastFilmography';
import CastPersonalInfo from './CastPersonalInfo';
import MyFollowers from './MyFollowers';
import CrewIFollow from './CrewIFollow';
import CastIFollow from './CastIFollow';
import IndustryIFollow from './IndustryIFollow';
*/
function IndustrySidebar(props) {
    const industry = props.industry;
  
    
    return (
        <div>
            <IndustryAbout industry={industry}/>
            <IndustryCollaborators industry={industry}/>
            {/*<MyFollowers/>
            
            <CastFilmography/>
            <CastPersonalInfo/>
            <MyFollowers/>
            <CrewIFollow/> 
            <CastIFollow/>
            <IndustryIFollow/>
    */}
        </div>
    )
}

export default IndustrySidebar;