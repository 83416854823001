import {React, useState} from 'react';
import { useErrorBoundary } from "react-error-boundary";
import { toast } from 'react-toastify';
import Header from '../common/Header';
import Footer from '../common/Footer';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

function ProjectAdd(){
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();

    const [projectFamily, setProjectFamily] = useState('P');
    const [disabled , setDisabled] = useState(true);
    const [showProjectInstructions, setShowProjectInstructions] = useState(false);
    const [showSampleInstructions, setShowSampleInstructions] = useState(false);
    const [data, setData] = useState([]);
   
    const handleProjectFamily = (e) => {
        setProjectFamily(e.target.value);
        setData({...data, [e.target.name]: e.target.value});
        if(e.target.value === 'P'){
            setShowProjectInstructions(true);
            setShowSampleInstructions(false);
        }
        else if(e.target.value === 'M'){
            setShowProjectInstructions(false);
            setShowSampleInstructions(true);
        }
        setDisabled(false);
    }

    const handleChange = ({currentTarget: input}) => { 
        setData({...data, [input.name]: input.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(data.project_title === undefined || data.project_title === ''){
            toast.error("Debes agregar un nombre de proyecto");
            setTimeout(() => {
            }, 500);
            return;
        }
        
        if (projectFamily === 'P'){

            if(window.confirm('Al crear un perfil de una producción reconoces que tú eres el/la titular y/o creador(a) original de la misma. ¿Certificas que cuentas con los derechos de autor/explotación de esta producción?')){
                let decoded = jwt_decode(localStorage.getItem("token"));        
                data.userId = decoded._id;
                try {            
                    const url = `${process.env.REACT_APP_API_LOCATION}/project/saveProject`;
                    const { data: res } = await axios.post(url, data);         
                    console.log(res);
                    navigate('/ProjectForm/'+res._id);
                } 
                catch (error) {
                    console.log(error);
                    showBoundary();
                }
            }
        }
        else if (projectFamily === 'M'){
            let decoded = jwt_decode(localStorage.getItem("token"));        
            data.userId = decoded._id;
            try {            
                const url = `${process.env.REACT_APP_API_LOCATION}/project/saveProject`;
                const { data: res } = await axios.post(url, data);         
                console.log(res);
                navigate('/SampleForm/'+res._id);
            } 
            catch (error) {
                console.log(error);
                showBoundary();
            }
        }

        
    }
    
    return(
        <>
        <Header/>
        <>
            <div className="container-fluid padding-0 ">
                <img src="/banner-peach.png" alt="Banner" className="responsive"/>
            </div>    
            <div className="container">
                <form onSubmit={handleSubmit}>
                <div className='spacer20'></div>
            
                <div className="row">
                    <div className="col-lg-12">
                    Cinekomuna cataloga los proyectos en <strong>Producciones audiovisuales</strong> y en <strong>Muestras de trabajo</strong>. Por favor especifica qué tipo de proyecto quieres publicar.
                    </div>
                </div>
                <div className="spacer20"></div>
            
                <div className="row">
                    <div className="col-lg-8 text-14">
                        <input 
                            type="radio" 
                            name="project_family" 
                            className="family"
                            value="P"
                            onChange={handleProjectFamily}
                        />&nbsp;Producción audiovisual&nbsp;
                        <input 
                            type="radio" 
                            name="project_family" 
                            className="family"
                            value="M"
                            onChange={handleProjectFamily}
                        />&nbsp;Muestra de trabajo
                    </div>
                </div>
                
                <div className="spacer20"></div>
                <div className="row">
                    <div className="col-lg-4">
                        <label htmlFor="project_title">Nombre del proyecto que deseas agregar <span className="text-purple">(Requerido)</span></label>
                    </div>
                    <div className="col-lg-8">
                        <input 
                            type="text" 
                            className="form-control" 
                            name='project_title'
                            placeholder="Nombre" 
                            maxLength="150" 
                            disabled = {(disabled)? "disabled" : ""}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                
                <div className="spacer20"></div>
                
                {showProjectInstructions &&                
                    <ProjectInstructions/>
                }
                {showSampleInstructions &&
                    <SampleInstructions/>
                }
                
                <div className="spacer20"></div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                    <button 
                        className="btn btn-purple" 
                        type="submit" 
                        disabled = {(disabled)? "disabled" : ""}
                    >
                        CONTINUAR
                    </button>   
                    </div>
                </div>
                <div className="row">
                    <div className="col spacer80"></div>
                </div>
                </form>
            </div>
            
            
            </>
        <Footer/>
    </>
    )
}

function ProjectInstructions(){
    return (
        <>
            <div className="row">
                <div className="col">
                ¿Eres el/la Director(a), productor(a), responsable directo de esta producción? (Largometraje, 
                cortometraje, videoclip, serie/programa, serie web, etc).
                <br/><br/>
                Si no cuentas con los derechos autorales y/o de explotación te recomendamos hacer una muestra de trabajo
                </div>
            </div>
        </>
    )
}

function SampleInstructions(){
    return (
        <>
            <div className="row">
                <div className="col">
                Una muestra de trabajo puede ser: un portafolio de tu trabajo y tu trayectoria en el medio. (Cualquier cosa que quieras compartirle a la comunidad Cinekomuna relacionado a tu experiencia en la industria.)
                </div>
            </div>
        </>
    )
}

export default ProjectAdd;
