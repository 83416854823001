import React from 'react'
import { useState, useEffect } from 'react';
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import styled from 'styled-components';
import { Container, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { UserContext } from '../context';

const CardsContainer = styled.div`
    /*display: flex;*/
    /*height: 75vh;*/
    align-items: center;
    justify-content: center;
`
const CardHeader = styled.div`
    /*height: 18rem;    */
    width: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px 20px 0 0;
`
const PriceCircle = styled.div`
    /*border: 0.5rem solid white;
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0.1rem 0.1rem 1rem rgba(19,20,19,0.342);*/
`
const PriceText = styled.p`
    color: white;
    font-size: 3rem;
    text-shadow: 0.1rem 0.1rem 1rem rgba(19,20,19,0.342);
`
const CardTitle = styled.h5`
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;

`
const CardText = styled.p`
    text-align: center;
    font-size: 1rem;
    font-weight: 400;    
    border-top  : 1px solid #ccc;
`

const Button = styled.button`
    background: #141e3c;
    border-radius: 20px;
    border-color: #141e3c;
    color: #fff;
    font-size: .9rem;
    font-weight: 600;
    margin: 5px auto;
    max-width: 200px;
    padding: 8px 0;
    text-align: center;
    width: 100%;
    &:hover{
        background-color: #172b4d;
    }
`

const ButtonDisabled = styled.button`
    background: #666;
    border-radius: 20px;
    border-color: #fff;
    color: #fff;
    font-size: .9rem;
    font-weight: 600;
    margin: 5px auto;
    max-width: 200px;
    padding: 8px 0;
    text-align: center;
    width: 100%;    
`

function Subscription() {

    const [prices, setPrices] = useState([]);
    const [state] = React.useContext(UserContext);

    const currentPlan = (value) =>{
        if(value === state.data.plan){
            return true;
        }
        else{
            return false;
        }
    }

    useEffect(() => {
        fetchPrices();
    }, []);

    const fetchPrices = async()  => {
        const {data:response} = await axios.get(`${process.env.REACT_APP_API_LOCATION}/subs/prices`);
        console.log(response);
        setPrices(response.data);
    };

    const createSession = async(priceId) => {
        const {data:response} = await axios.post(`${process.env.REACT_APP_API_LOCATION}/subs/session`,
        {
            priceId,
        }
        );

        window.location.href = response.url;
    };

    const backgroundColors = {
        FREE: "rgb(53, 53, 53, 0.524)",
        PRO: "#ed1567",
        PREMIUM: "#141e3c"
    }

    const features = {
        FREE: [
            "Creación de perfil (cast/crew)",
            "1 proyecto",
            "Hasta 3 fotos para perfil/portada",
            "Demo reel (cast/crew)",
        ],
        PRO: [
            "Todo lo de FREE +",
            "Hasta 3 proyectos",
            "Hasta 20 fotos",
            "Características físicas de cast",
            "Proyecto destacado",
            "Hasta 2 empresas",
            "Mensaje directo a cast/crew",
        ],
        PREMIUM: [
            "Todo lo de PRO +",
            "Proyectos ilimitados",
            "Hasta 50 fotos",
            "Empresas ilimitadas",
        ]
    }

  return (
    <>
    <Header/>
        <Container>
        <div className="spacer40"></div>
        <h1>Planes</h1>
        <div className="spacer20"></div>
            <CardsContainer>
            <Row xs={1} md={3} className="g-4">
            {prices.map((price, index) => {
                    return(
                        <Col key={index}>
                        <Card style={{width: "18rem", height:"70vh", marginRight:"2rem"}}>
                            <CardHeader style={{
                                backgroundColor: backgroundColors[price.nickname]                                
                            }}>
                                <PriceCircle>
                                    <PriceText>${price.unit_amount / 100}</PriceText>
                                </PriceCircle>
                            </CardHeader>
                            <Card.Body>
                                <CardTitle style={{fontSize:"3rems"}}>{price.nickname}</CardTitle>
                                {features[price.nickname].map((feature, index) => {
                                    return(
                                        <CardText key={index}>{feature}</CardText>
                                    )
                                })}
                            </Card.Body>
                            <Card.Footer>
                                {price.nickname !== "FREE" && !currentPlan(price.nickname) &&
                                <Button 
                                    className="mt-2" 
                                    onClick={() => createSession(price.id)} 
                                >
                                    Comprar
                                </Button>
                                }
                                {price.nickname !== "FREE" && currentPlan(price.nickname) &&
                                <ButtonDisabled 
                                    className="mt-2" 
                                    disabled
                                >
                                    Tu plan actual
                                </ButtonDisabled>
                                }

                            </Card.Footer>
                        </Card>
                        </Col>
                    )
                })}
                </Row>
            </CardsContainer>
        </Container>
        <div className="spacer40"></div>
    

    <Footer/>   
    </>

  )
}

export default Subscription