import { useCallback, useEffect, useState, useContext } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import ErrorHandler from "../../common/ErrorHandler";

import jwt_decode from "jwt-decode";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { UserContext } from "../../../context";

function ProjecList (){
    const [projects, setProjects] = useState([]);
    const [samples, setSamples] = useState([]);
    const [state] = useContext(UserContext);
    const [PLAN, setPLAN] = useState("FREE");
    const [totalProjects, setTotalProjects] = useState(0);
    
    const membershipLimits = {
        FREE: 1,
        PRO: 3,
        PREMIUM: 100000
      };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const code = 200;

    const getUserProjects = useCallback(async () => {
        let decoded = jwt_decode(localStorage.getItem("token"));
        const url = `${process.env.REACT_APP_API_LOCATION}/project/getUserProjectsForEdit/${decoded._id}/P`;
        await axios.get(url)
        .then(response => {
            console.log(response);
            setProjects(response.data);
        }).catch(error => {
            console.log(error);
        });
    }, []);

    const getUserSamples = useCallback(async () => {
        let decoded = jwt_decode(localStorage.getItem("token"));
        const url = `${process.env.REACT_APP_API_LOCATION}/project/getUserProjectsForEdit/${decoded._id}/M`;
        await axios.get(url)
        .then(response => {
            setSamples(response.data);
        }).catch(error => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        getUserProjects();
        getUserSamples();
        setPLAN(state.data.plan);

        const totalProjects = projects.length + samples.length;
        setTotalProjects(totalProjects);

    }, [getUserProjects, getUserSamples, state.data.plan, projects.length, samples.length]);

//    console.log(projects);

    //console.log(totalProjects,"totalProjects");
    //console.log(state.data);

    return (

        <div>
        <Header/>
        {projects.length > 0 &&
            <>
                <div className="container-fluid text-center">
                    <div className="col-md-12">
                        <div className="spacer20"></div>
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="spacer40"></div>
                                <div className="card h-250">
                                    {totalProjects >= membershipLimits[PLAN] &&
                                    <>
                                    <div onClick={handleShow} style={{cursor:"pointer"}}>
                                        <img className="card-img-top cover-top" src="/no-image-logo.png" alt="Cover"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Crear nuevo proyecto</h5>
                                            <div className="card-text text-center"><h1 className="text-purple"><span className="mdi mdi-plus"></span></h1></div>
                                        </div>
                                    </div>
                                    </>
                                    }
                                    {totalProjects < membershipLimits[PLAN] &&
                                    <>
                                    <a href="/projectAdd" className="text-black a100"><img className="card-img-top cover-top" src="/no-image-logo.png" alt="Cover"/></a>
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="/projectAdd" className="text-black createProject">Crear nuevo proyecto</a></h5>
                                        <div className="card-text text-center"><a href="/ProjectAdd" className="text-black"><h1 className="text-purple"><span className="mdi mdi-plus"></span></h1></a></div>
                                    </div>
                                    </>
                                    }

                                </div>
                            </div>
                            <div className="col-md-9">
                                <main role="main">
                                    <div className="album bg-light">
                                        <div className="container-fluid">
                                            <Tabs align="left">
                                                <TabList>
                                                    <Tab>Producciones</Tab>
                                                    <Tab>Muestras de trabajo</Tab>
                                                </TabList>

                                                <TabPanel>
                                                    <div className="row">
                                                        {
                                                            projects.map((project, index) => {
                                                                return (
                                                                    <div className="col-md-4 col-sm-6" key={index}>
                                                                        <div className="card w-100">
                                                                            <a href={"/project/"+project._id} className="a100"><img className="card-img-top cover-top" src={project.cover.url} alt="background"/></a>
                                                                            <div className="card-body">
                                                                                <h5 className="card-title"><a href={"/project/"+project._id} className="text-black">{project.project_title}</a></h5>
                                                                                {project.project_year &&
                                                                                <p className="card-text">{project.project_year}</p>
                                                                                }
                                                                                {!project.project_year &&
                                                                                <p className="card-text">Año desconocido</p>
                                                                                }


                                                                            </div>
                                                                            <div className="card-footer">
                                                                                
                                                                                
                                                                            {(PLAN==="PRO" || PLAN==="PREMIUM") &&
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                    <p className="card-text">Proyecto principal: <input type="checkbox" name="selectMain" id="proj_main" className="proj_main" value={project.id}/></p>    
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {(PLAN==="FREE") &&
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                    <p className="card-text">Proyecto principal: <input type="checkbox" name="selectMain" disabled={"disabled"} className="proj_main" /></p>    
                                                                                    <p className="agreements"><span className="mdi mdi-alert"></span> Se requiere <a href="/subscription">suscripción</a></p>
                                                                                    </div>
                                                                                </div>
                                                                            </>    
                                                                            }
                                                                                <div className="row">
                                                                                    <div className="col"><hr/></div>

                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col card-text"><a href={"/project/"+project._id} className="text-black"><span className="mdi mdi-eye"></span></a></div>
                                                                                    <div className="col card-text text-center"><a href={"/projectForm/"+project._id} className="text-black"><span className="mdi mdi-pencil"></span></a></div>
                                                                                    <div className="col card-text text-right"><a href="/" id="deleteProject" data-id="NpPRhQ7fDXgSafvJi" className="text-black"><span className="mdi mdi-trash-can" data-id="NpPRhQ7fDXgSafvJi"></span></a></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        
                                                        
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    
                                                    <div className="row">
                                                    {
                                                            samples.map((sample, index) => {
                                                                return (
                                                                    <div className="col-md-4 col-sm-6" key={index}>
                                                                        <div className="card w-100">
                                                                            <a href={"/project/"+sample._id} className="a100"><img className="card-img-top cover-top" src={sample.projectPictureID} alt="background"/></a>
                                                                            <div className="card-body">
                                                                                <h5 className="card-title"><a href={"/project/"+sample._id} className="text-black">{sample.project_title}</a></h5>
                                                                                {sample.project_year &&
                                                                                <p className="card-text">{sample.project_year}</p>
                                                                                }
                                                                                {!sample.project_year &&
                                                                                <p className="card-text">Año desconocido</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-footer">
                                                                            <div className="row">
                                                                                <div className="col card-text"><a href="/projectPage/NpPRhQ7fDXgSafvJi" className="text-black"><span className="mdi mdi-eye"></span></a></div>
                                                                                <div className="col card-text text-center"><a href="/editProject/NpPRhQ7fDXgSafvJi" className="text-black"><span className="mdi mdi-pencil"></span></a></div>
                                                                                <div className="col card-text text-right"><a href="/" id="deleteProject" data-id="NpPRhQ7fDXgSafvJi" className="text-black"><span className="mdi mdi-trash-can" data-id="NpPRhQ7fDXgSafvJi"></span></a></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        {projects.length <= 0  &&
            <>
                <div className="container-fluid padding-0">
                    <nav>
                        <div className="pink">Mis proyectos</div>
                    </nav>
                </div>
                <div className="container-fluid text-center">
                    <div className="col-md-12">
                        <div className="spacer20"></div>
                        <p> No tienes proyectos, agrega tu primer proyecto</p>
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="spacer40"></div>
                                <div className="card h-250">
                                    <a href="/projectAdd" className="text-black a100"><img className="card-img-top cover-top" src="/no-image-logo.png" alt="Cover"/></a>
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="/projectAdd" className="text-black createProject">Crear nuevo proyecto</a></h5>
                                        <div className="card-text text-center"><a href="/ProjectAdd" className="text-black"><h1 className="text-purple"><span className="mdi mdi-plus"></span></h1></a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                &nbsp;
                            </div>
                        </div>
                        <div className="spacer20"></div>
                        <div className="spacer80"></div>
                    </div>
                </div>
            </>
            }
        {code!==200 &&
            <ErrorHandler code={code} title="Ruta o parámetro"/>
        }
        <Footer/>
        <>
     

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Has alcanzado tu límite</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tu membresía sólo te permite crear {membershipLimits[PLAN]} proyectos. Puedes adquirir una <a href="/subscription">membresía superior</a> para eliminar este límite </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
    </div>
    
    )
}

export default ProjecList;