import React from "react";


function CastAbout(props) {
  const { person } = props

  let resume = "";
    if(person && person.cast && person.cast.resume){
        resume = person.cast.resume;
    }


    if(!person){
        return <div>Cargando ... </div>
    }

  return (
    <>
    {resume &&
    <div>
        <div className="row">
            <div className="col">
                <div className="spacer40  d-lg-none"></div>
                <strong className="uppercase">Acerca de {person.fullname}</strong>
                <hr/>
            </div>
        </div>
        <div className="spacer20"></div>
        <div className="row">
            <div className="col about">
                {resume}
            </div>
        </div>
        <div className="spacer40"></div>
        <div className="row">
            <div className="col">
                <a href={person.cast.webpage} target="_blank" rel="noreferrer" className="text-gray"><strong className="text-gray"><small>{person.cast.webpage}</small></strong></a>
            </div>
        </div>
    </div>
    }
    </>
  );
}

export default CastAbout;