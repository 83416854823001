import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Hand } from "../../static/images/hand.svg";
import { EditText } from 'react-edit-text';
import {formatearURL} from "../common/FormatFunctions";
import { UserContext } from '../../context';

import SelectRoleModal from "./modals/SelectRoleModal";
import SelectLocationModal from "./modals/SelectLocationModal";
import ModalForSocialMedia from "./modals/ModalForSocialMedia";

import jwtDecode from "jwt-decode";
import cn from "classnames";
import axios from "axios";

import "./styles.scss";
import 'react-edit-text/dist/index.css';


const particleList = Array.from(Array(10));

function IndustryMeta(props) {
    
    let industry = props.industry;

    const [user] = useContext(UserContext);    
    const [liked, setLiked] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [likes , setLikes] = useState(industry.likes);
    const [showFollow, setShowFollow] = useState(false);
    const [following, setFollowing] = useState(false);
    const [followersCount, setFollowersCount] = useState(industry.followersCount);
    const [editPermission] = useState(false);

    const [itsMine, setItsMine] = useState(false);

    console.log(itsMine);

    const navigate = useNavigate();
    //console.log(industry);
    console.log(showFollow);

    let decoded = null;
    const token = localStorage.getItem("token");
    
    let industryId = industry._id;

    
   
    async function handleLikes (action){
        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/industry/${action}Industry`;
            axios.put(url, {
                userId: user._id,
                industryId: industryId
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });

        } catch (error) {
            console.log(error); 
        }
    }    

    const hasEditPermission = () => {
        let result = false;
        if(industry){
            if(localStorage.getItem("token")){
                decoded = jwtDecode(localStorage.getItem("token"));
                if (decoded._id === industry.userId) {
                    result = true;
                    
                } else {
                    result = false;
                    
                }
            }
        }
        return result;
        
    }
    async function likeUnlike (e) {
        e.preventDefault();
        setClicked(true);
        if(liked){
            handleLikes("unlike");
            setLikes(likes-1);
        }
        else{
            handleLikes("like");
            setLikes(likes+1);
        }
        setLiked(!liked);
    }

    function gotoUploadWidget (){
        navigate('/uploadWidget', {
            state: {
              source: 'industryLogo',
              companyId: industryId
            }
        });
    }


    async function handleFollows (action){
        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/user/${action}Industry`;
            axios.put(url, {
                userId: user._id,
                industryId: industryId
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });

        } catch (error) {
            console.log(error); 
        }
    }   

    async function followUnfollow (e) {
        e.preventDefault();
        if(following){
            handleFollows("unfollow");
            setFollowersCount(followersCount-1);
        }
        else{
            handleFollows("follow");
            setFollowersCount(followersCount+1);
        }
        setFollowing(!following);
    }

    const handleSaveName = ({ name, value, previousValue }) => {
        //alert(name + ' saved as: ' + value + ' (prev: ' + previousValue + ')');
        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/industry/updateName`;
            axios.put(url, {
                companyId: industryId,
                company_name: value
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });
        } catch (error) {
            console.log(error);
        }            
    };

    const handleSaveYear = ({ name, value, previousValue }) => {
        if(value > 2025 || value < 1970){
            alert("El año debe estar entre 1970 y 2025");
            return;
        }

        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/industry/updateYear`;
            axios.put(url, {
                companyId: industryId,
                company_year: value
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });
        } catch (error) {
            console.log(error);
        } 
        

    };

    const followState = useCallback(async () => {
        if(!token){// si no hay sesión no muestro el boton de seguir
            setShowFollow(false);
            return;
        }
        
        if(editPermission){ //si es mi perfil no muestro el boton de seguir
            setShowFollow(false);
            return;
        }        
        setShowFollow(true);
        //setFollowersCount(industryData.followersCount);
                
    }, [token, editPermission]);

    /*
    const checkIfUserLikesIndustry = useCallback(async () => {
        try {
          const userId = jwtDecode(localStorage.getItem("token"))._id;
          const {data: user} = await axios.get(`${process.env.REACT_APP_API_LOCATION}/user/getUserMetadata/${userId}`);       
          
            setCurrentUser(user);
          if(user && user.likesCompany && user.likesCompany.includes(industryId)){
            setLiked(true);
          }
          else{
            setLiked(false);
          }

            if(user && user.followsCompany && user.followsCompany.includes(industryId)){
                setFollowing(true);
            }
            else{
                setFollowing(false);
            }
          
        } catch (error) {
            console.log(error);
        }
        }, [industryId]);*/

      useEffect(() => {
        
        /*Check if user is logged */
        if(user && user.data){
            //console.log("El usuario está firmado con id: "+user.data._id);
            /**Check if this document belongs to the logged user */
            if(user.data._id === industry.userId){  
                setItsMine(true);
            }
        }
        else{
            //console.log("El usuario no está firmado");
        }
        
        followState();
        /*if(localStorage.getItem("token")){
            checkIfUserLikesIndustry(industryId);
        }*/
      }, [followState, user, industry]);

      
      
   

    if (!industry) {
        return <div>Cargando...</div>;
    }

    // Obtener las propiedades del objeto usando el operador de encadenamiento opcional
    
    const logo = industry.logo ?? '';
    const name = industry.company_name ?? '';    
    const year = industry.company_year ?? '';
    const role = industry.role ?? '';
    const country = industry.country ?? '';
    const state = industry.state ?? '';
    const city = industry.city ?? '';
    const facebook = industry.company_facebook_page ?? '#';
    const instagram = industry.company_instagram_page ?? '#';
    const twitter = industry.company_twitter_page ?? '#';
    const youtube = industry.company_youtube_page ?? '#';
    const vimeo = industry.company_vimeo_page ?? '#';
    const imdb = industry.company_imdb_page ?? '#';

    let web = industry.company_web_page ?? '';

    if(web !== ''){
        web = formatearURL(web);
    }
    

    return (
        <>
        <div>
            <div className="container">
                <div className="spacer60"></div>
                <div className="row">
                    <div className="d-lg-none col-12">
                            {!logo &&
                            <img src="/no-image-logo.png" className="rounded-circle borde-img logo-over" alt="No logo"/>
                            }
                            {logo && logo.url &&
                            <img src={logo.url} border="0" className="borde-img logo-over" alt="Actual Logo"/>
                            }
                            {hasEditPermission() &&
                            <button onClick={gotoUploadWidget} className="text-white icon-over-logo no-border" id="openMediaGallery">
                                <span className="mdi mdi-camera"></span>
                            </button>
                            }
                    </div>
                    <div className="col-lg-8 col-md-8 col-12">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-12 d-none d-lg-flex">
                                {(!logo || !logo.url) &&
                                    <img src="/no-image-logo.png" className="rounded-circle borde-img logo-over" alt="No logo"/>
                                }
                                {logo && logo.url &&
                                    <img src={logo.url} border="0" className="rounded-circle borde-img logo-over" alt="Actual Logo"/>
                                }
                                {hasEditPermission() &&
                                <button onClick={gotoUploadWidget} className="text-white icon-over-logo no-border" id="openMediaGallery">
                                    <span className="mdi mdi-camera"></span>
                                </button>
                                }
                            </div>
                            <div className="col-lg-10 col-12">
                                <div className="row">
                                    <div className="col h3-title">
                                        {!hasEditPermission() && 
                                            <>
                                            {name}
                                            </>
                                        }
                                        {hasEditPermission() &&
                                            <>
                                            <EditText
                                                name='company_name'
                                                defaultValue={name}
                                                editButtonProps={{ style: { marginLeft: '5px', width: 16, fill: '#ED1567' } }}
                                                showEditButton
                                                onSave={handleSaveName}
                                            />
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {role}
                                        {hasEditPermission() &&
                                            <button 
                                                type="button"
                                                data-toggle="modal" data-target="#selectRoleModal"
                                                className="_NGZSv pencil"                                                
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"></path>
                                                    </svg>
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {!hasEditPermission() && 
                                            <>
                                            Año de fundación: {year}
                                            </>
                                        }
                                        {hasEditPermission() &&
                                            <>
                                            Año de fundación: <EditText 
                                                name="company_year" 
                                                type="number" 
                                                style={{width: '100px'}} 
                                                defaultValue={year} 
                                                editButtonProps={{ style: { marginLeft: '5px', width: 16, fill: '#ED1567' } }}
                                                showEditButton
                                                onSave={handleSaveYear}
                                            />
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <span className="mdi mdi-map-marker"></span> <small>{country}, {state}, {city}</small>
                                        {hasEditPermission() &&
                                            <button 
                                                type="button"
                                                data-toggle="modal" data-target="#selectLocationModal"
                                                className="_NGZSv pencil"                                                
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"></path>
                                                    </svg>
                                            </button>
                                        }
                                    </div>
                                </div>
                                
                                {user.data && user.data._id &&
                                
                                <div className="row">
                                    <div className="col">
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            {user.data &&
                                            <>
                                            {following &&
                                            <button className="btn mr-2 button-unfollow" onClick={followUnfollow}> <span className="mdi mdi-account-cancel"></span> DEJAR DE SEGUIR</button>
                                            }
                                            {!following &&
                                            <button className="btn mr-2 button-follow" onClick={followUnfollow}> <span className="mdi mdi-plus-circle"></span> SEGUIR</button>
                                            }
                                            </>
                                            }
                                            {/**Inicia componente Botón */}
                                            <div>
                                                <button
                                                    type="button"
                                                    onClick={likeUnlike}
                                                    onAnimationEnd={() => setClicked(false)}
                                                    className={cn("like-button-wrapper", {
                                                    liked,
                                                    clicked,
                                                    })}      
                                                >
                                                    
                                                    {liked && (
                                                    <div className="particles">
                                                        {particleList.map((_, index) => (
                                                        <div
                                                            key={index}
                                                            className="particle-rotate"
                                                            style={{
                                                            transform: `rotate(${
                                                                (360 / particleList.length) * index + 1
                                                            }deg)`,
                                                            }}
                                                        >
                                                            <div className="particle-tick" />
                                                        </div>
                                                        ))}
                                                    </div>
                                                    )}
                                                    
                                                    <div className="like-button">
                                                    <Hand />
                                                    <span>ME GUSTA</span>
                                                    <span className={cn("suffix", { liked })}>BA</span>
                                                    </div>
                                                </button>
                                            </div>
                                            {/**Termina componente Botón */}
                                        </div>
                                    </div>
                                </div>
                                }
                                {/**Fin de validación */}
                            
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 border-left">
                        <div className="row">
                            <div className="col-md-12"><span className="mdi mdi-eye"></span>&nbsp;<span className="agreements">Vistas {industry.views}</span></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12"><span className="mdi mdi-thumb-up"></span>&nbsp;<span className="agreements">Valoraciones {industry.likes}</span></div>        
                        </div>
                        <div className="row">
                            <div className="col-md-12"><span className="mdi mdi-account-arrow-left"></span>&nbsp;<span className="agreements">Seguidores {industry.followersCount}</span></div>        
                        </div>
                    </div>
                    <div className="col-lg-2 col-12 border-left">                        
                        <div className="spacer30 d-lg-none"></div>                        
                        <div className="row">                            
                            <div className="col-lg-4 col-2">
                            {facebook && 
                                <a href={industry.facebook} target="_blank" rel="noopener noreferrer"> <span className="mdi mdi-facebook text-black"></span></a>
                            }
                            {!facebook &&
                                <span className="mdi mdi-facebook text-light"></span>
                            }                            
                            </div>
                            <div className="col-lg-4 col-2">
                            {instagram &&
                                <a href={industry.instagram} target="_blank" rel="noopener noreferrer"> <span className="mdi mdi-instagram text-black"></span></a>
                            }
                            {!instagram &&
                                <span className="mdi mdi-instagram text-light"></span>
                            }
                            </div>
                            <div className="col-lg-4 col-2">
                            {twitter &&
                                <a href={twitter} target="_blank" rel="noopener noreferrer"> <span className="mdi mdi-twitter text-black"></span></a>
                            }
                            {!twitter &&
                                <span className="mdi mdi-twitter text-light"></span>
                            }
                            </div>
                    
                            <div className="col-lg-4 col-2">
                            {youtube &&
                                <a href={youtube} target="_blank" rel="noopener noreferrer"> <span className="mdi mdi-youtube text-black"></span></a>
                            }
                            {!youtube &&
                                <span className="mdi mdi-youtube text-light"></span>
                            }
                            </div>
                            <div className="col-lg-4 col-2">
                            {vimeo &&
                                <a href={vimeo} target="_blank" rel="noopener noreferrer"> <span className="mdi mdi-vimeo text-black"></span></a>
                            }
                            {!vimeo &&
                                <span className="mdi mdi-vimeo text-light"></span>
                            }
                            </div>
                            <div className="col-lg-4 col-2">
                            {imdb &&
                                <a href={imdb} target="_blank" rel="noopener noreferrer"> <img src="/imdb.png" className="icon-size" alt="imdb icon"/> </a>
                            }
                            {!imdb &&
                                <img src="/imdb-gray.png" className="icon-size" alt="imdb icon"/>
                            }
                            </div>
                            
                            
                        </div>
                        <div className="spacer10"></div>
                        <div className="row">
                            <div className="col-md-9 col-sm-12 text-center">
                                <a href={web} target="_blank" rel="noopener noreferrer" className="text-black"><strong className="text-black"><small>{industry.company_web_page}</small></strong></a>                                
                                <br/>
                                {hasEditPermission() &&
                                    <button 
                                        type="button"
                                        data-toggle="modal" data-target="#modalForSocialMedia"
                                        className="_NGZSv pencil"                                                
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"></path>
                                            </svg>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer40"></div>
                
    
            <SelectRoleModal companyId={industry._id}/>
            <SelectLocationModal companyId={industry._id}/>
            <ModalForSocialMedia companyId={industry._id}/>
        </div>
        </>
    )
}

export default IndustryMeta;