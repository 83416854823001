import React from "react";

function SearchButtons(props){

    const {activeButton} = props;
    let searchType = "";

    activeButton ? searchType = activeButton : searchType = "";

    return(
        <div>
            <div className="container">
                <div className='spacer40'></div>
                <div className="row m-0">
                    <div className="col-lg-3 col-md-3 col-6">
                        {searchType === "project" ? <a className="nav-link btn-options m-auto btn-active" href="/searchProject">Proyectos</a> : <a className="nav-link btn-options m-auto" href="/searchProject">Proyectos</a>}
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        {searchType === "crew" ? <a className="nav-link btn-options m-auto btn-active" href="/searchCrew">Crew</a>:<a className="nav-link btn-options m-auto" href="/searchCrew">Crew</a>}
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        {searchType === "cast" ? <a className="nav-link btn-options m-auto btn-active" href="/searchCast">Cast</a> : <a className="nav-link btn-options m-auto" href="/searchCast">Cast</a>}
                    </div>
                    <div className="col-lg-3 col-md-3 col-6 pad2">
                        {searchType === "industry" ? <a className="nav-link btn-options m-auto btn-active" href="/searchIndustry">Empresa/ Organización</a> : <a className="nav-link btn-options m-auto" href="/searchIndustry">Empresa/ Organización</a>}
                    </div>
                </div>       
            </div>     
        </div>
    )
}

export default SearchButtons;