import {React, useEffect, useCallback, useState} from 'react';
//import {useAuth} from '../../../context/UserContext';
import axios from 'axios';

import '../styles.scss';

function CastResults(props) {

    const NUM_OF_RESULTS_PER_PAGE = process.env.REACT_APP_PAGINATION_LIMIT;

    //const {currentUser} = useAuth();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(NUM_OF_RESULTS_PER_PAGE);
    const [blockSize, setBlockSize] = useState(NUM_OF_RESULTS_PER_PAGE);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(NUM_OF_RESULTS_PER_PAGE);
    const [sortBy, setSortBy] = useState('profile.name');
    const [order, setOrder] = useState('asc');
    
    let {searchQuery} = props;

    function handleNextPage(){
        if(page===endPage){
            setStartPage(page+1);
            setEndPage(page+blockSize);
        }
        setPage(page+1);
    };

    function handleLastPage(){
        if(page===startPage){
            setStartPage(page-blockSize);
            setEndPage(page-1);
        }
        setPage(page-1);
    };

    function handleNumberOfPage(num){
        setPage(num);
    };

    const renderPages = () => {
        let pages = [];
        for (let i = startPage; i <= totalPages && i <= endPage; i++) {
            if(i !== page){
                pages.push(<li className="page-item" key={i}><button className="page-link" onClick={(event)=>handleNumberOfPage(i)}>{i}</button></li>);
            }
            else{
                pages.push(<li className="page-item active" key={i}><button className="page-link" onClick={(event)=>handleNumberOfPage(i)}>{i}</button></li>);
            }
        }
        return pages;
    };

    const changeSort = (e) => {
        setSortBy(e.target.value);
    }

    const changeOrder = (e) => {
        setOrder(e.target.value);
    }
    
    const getResults = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/cast/findCast`;
        const newQuery = {...searchQuery};

        newQuery.page = page;
        newQuery.limit = limit;
        newQuery.sort = sortBy;
        newQuery.order = order;

        const response = await axios.post(url,
            newQuery
            );
        setData(response.data);
        
        if(response.data && response.data.length > 0){
            let pages = response.data[0].total / limit;
            setTotalPages(pages);
            
        }
        
    }, [searchQuery, page, limit, order, sortBy]);

    useEffect(() => {
        getResults();
        setLimit(5);
        setBlockSize(10);
    }, [getResults]);

    return (
    
        <div>
            {data && data.length > 0 &&
            <div className="container-fluid">
			    <div className="spacer30 d-md-none"></div>
                <div className="row">
                    <div className="col agreements">Resultados: {data[0].total} </div>
                </div>
                <div className="row">
                    <div className="col-md-12"><hr/></div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4 col-sm-12 agreements">
                                Ordenar por:
                                <select 
                                    name="order" 
                                    className="form-control agreements" 
                                    onChange={(e) => changeSort(e)}
                                >
                                    <option value="profile.name">Nombre</option>
                                    <option value="profile.lastname">Apellido</option>
                                    <option value="state">Estado</option>
                                    <option value="city">Ciudad</option>
                                    <option value="views">Vistas</option>
                                    <option value="likes">Valoraciones</option>
                                </select>
                            </div>
                            <div className="col-md-4 col-sm-12 agreements">
                                Dirección:
                                <select 
                                    name="order" 
                                    className="form-control agreements" 
                                    onChange={(e) => changeOrder(e)}
                                >
                                    <option value="desc">Descendente</option>  
                                    <option value="asc">Ascendente</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="spacer20"></div>
                <div className="row">
                    <div className="col-md-12"><hr/></div>
                </div>
                <div className="spacer20"></div>

                {/**Aqui empieza el ciclo */}

                {data.map((item, index) => (
                <div key={index}>                    
                    <div className="row h-100">
                        <div className="col-lg-2 col-md-3 col-4 vertical-align-top">
                            <a href={"/Cast/"+item._id}>
                                <div className='image-container-50'>
                                    <img src={item.photo} className="image" alt="Profile"/>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-7 col-md-9 col-8">
                            <div className="row">
                                <div className="col-md-12 no-padding "><a href={"/Cast/"+item._id}><h5 className="h5-name">{item.fullname}</h5></a></div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 no-padding subtitle-underline"><span className="uppercase">{item.roleString}</span></div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12 no-padding  location-underline">
                                    <span className="mdi mdi-map-marker"></span> {item.city} - {item.state}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 no-padding numbers">
                                    <span className="mdi mdi-thumb-up"></span>
                                    {item.likes}
                                    &nbsp;/ &nbsp;
                                    <span className="mdi mdi-eye"></span>
                                    {item.views}
                                </div>
                            </div>
                            {/*currentUser && currentUser._id &&
                            <div className="row">
                                <div className="col-md-12 no-padding ">
                                    <button className="btn button-follow pushCollaborator" id=""> <span className="mdi mdi-plus"></span> COLABORADOR </button>
                                    <button className="btn button-follow sendMessage" id=""> <span className="mdi mdi-email"></span> ENVIAR MENSAJE </button>
                                </div>
                            </div>
                            */}
                        </div>
                        <div className="col-lg-3  d-none d-lg-block">
                            <img src={item.cover} className="media-thumb " alt='Cover'/>
                        </div>
                        
                    </div>
                    <div className="spacer20"></div>
                    <hr/>
                    <div className="spacer20"></div>
                </div>
                ))}
                {/**Aqui termina el ciclo */}

                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="Pagination">
                                <ul className="pagination">
                                    {page > 1 &&
                                    <li className="page-item ">
                                        <button className="page-link btn-pagination"  onClick={handleLastPage}>Anterior</button>
                                    </li>
                                    }
                                    {page === 1 &&
                                    <li className="page-item disabled">
                                        <button className="page-link btn-pagination">Anterior</button>
                                    </li>
                                    }

                                    {renderPages()}
                                        
                                    {page < totalPages && totalPages > 10 &&
                                    <li className="page-item">
                                        <button className="page-link btn-pagination"  onClick={handleNextPage}>Siguiente</button>
                                    </li>
                                    }
                                    {(page >= totalPages || totalPages < 10) &&
                                    <li className="page-item disabled">
                                        <button className="page-link btn-pagination">Siguiente</button>
                                    </li>
                                    }
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>
            }
            {(!data || data.length === 0) &&
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="h5-name">No se encontraron resultados</h5>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default CastResults;