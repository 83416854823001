import { React } from "react";
import Footer from "../common/Footer";

function ErrorBoundaryComponent({ error, resetErrorBoundary }){

    const goHome = () => {
        window.location.href = "/";
    }

    return(
        <>
        <div className="error-page min-h-screen flex items-center justify-center mt-4">
            <div className="text-center">
                <h2><strong>Bueno, esto es embarazoso, ha ocurrido un error</strong></h2>
                <img src="/desert.jpg" alt="Desierto" className="img-fluid"/>
                <p>&nbsp;</p>
                <h2><strong>{error.message}</strong></h2>
                <p>&nbsp;</p>
                <p>Intenta recargar la página, si el error persiste contacta al administrador</p>
                <button className="mt-4" onClick={resetErrorBoundary}>Recargar</button>
                &nbsp;<button className="mt-4" onClick={goHome}>Ir a la página principal</button>
                <p>&nbsp;</p>
            </div>
        </div>
        <Footer/>
        </>
    );

}

export default ErrorBoundaryComponent;