import { React } from "react";

function IndustryDemo(props) {
    const industry = props.industry;

    return (
        <>
        
        {industry && industry.demo &&
        <>
        <div className="spacer40"></div>
        <div className="row">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col ">
                        <strong className="uppercase">Video de muestra</strong>                        
                        <hr/>
                    </div>
                </div>
                <div className="spacer20"></div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe src={industry.demo} title={industry._id} className="embed-responsive-item"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        }
        </>
    )
}

export default IndustryDemo;