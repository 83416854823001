import {React, useState, useEffect, useCallback, useRef} from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {typeCat, yearCat} from "./selectCatalog";
import './styles.scss';

function AddIndustry(){
    const navigate = useNavigate();

    const [typeValue, setTypeValue] = useState("");
    const [company_year, setCompany_year] = useState("");
    const [selectedToRemove, setSelectedToRemove] = useState([]);
    const [data, setData] = useState([]);
    const [company, setCompany] = useState([]);
    //const [error, setError] = useState('');
    
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const selectedTypes = useRef([]);
    const country = useRef('');
    const state = useRef('');
    const city = useRef('');

    const handleChange = ({currentTarget: input}) => { 
        setData({...data, [input.name]: input.value});
    };

    function updateType(e){
        setTypeValue(e.target.value);
    }

    function updateYear(e){
        setCompany_year(e.target.value);
        setData({...data, "company_year": e.target.value});
    }

    function handleRightArrow(e){
        e.preventDefault();
        if(typeValue !== '' && typeValue !== 'cualquier'){
            let obj = selectedTypes.current.find(o => o === typeValue);
            //if(selectedTypes.length > 0){
                if(obj === undefined){
                    selectedTypes.current.push(typeValue);
                    setData({...data, "company_type": selectedTypes.current});
                }
                else{
                    alert("Ya agregaste este tipo");
                }
            /*}
            else{
                setSelectedTypes([...selectedTypes, typeValue]);
                setData({...data, "company_type": selectedTypes});
            } */
            
        }
        setTypeValue("cualquier");
        
        console.log(selectedTypes.current);
    }   

    function handleLeftArrow(e){
        e.preventDefault();
        let newArray = selectedTypes.current;
        if(selectedToRemove.length > 0){
            
            for(let i = 0; i < selectedToRemove.length; i++){
                let obj = newArray.find(o => o === selectedToRemove[i]);

                if(obj !== undefined){
                    newArray = newArray.filter(item => item !== selectedToRemove[i]);
                }
            }
        }

        console.log(newArray);
        selectedTypes.current = newArray;
        setSelectedToRemove([]);
    }
    function handleRemoveTypes(e) {
        let options = document.getElementById('selectedTypes').selectedOptions;
        let values = Array.from(options).map(({ value }) => value);
        setSelectedToRemove(values);
        
    }

    const handleSubmit = async (e) => {   
        e.preventDefault();        
        
        if(data.company_name === undefined || data.company_name === ''){
            alert("Debes agregar un nombre de empresa");
            return;
        }

        if(data.company_type.length === 0){
            alert("Debes agregar al menos un tipo de industria");
            return;
        }

        if(data.company_desc === undefined || data.company_desc === ''){
            alert("Debes agregar una descripción");
            return;
        }

        if(data.company_year === '' || company_year === 'cualquier'){
            alert("Debes agregar un año de fundación");
            return;
        }

        if(!data.country){
            alert("El país no puede estar vacío");
            return;
        }

        if(!data.state){
            alert("El estado no puede estar vacío");
            return;
        }

        if(!data.city){
            alert("La ciudad no puede estar vacía");
            return;
        }

        if(data.company_demo !== ""){
            if(data.company_demo && data.company_demo.indexOf("youtube.com")<=0 && data.company_demo.indexOf("vimeo.com")<=0){
                alert("El demo reel debe estar en youtube o vimeo");
                return;
            }
            else if(data.company_demo && data.company_demo.indexOf("youtube.com") > 0 && data.company_demo.indexOf("youtube.com/watch") <= 0){
                alert("Para el demo reel uiliza el link directo a un video, no uses enlaces de canales, listas o " +
                      "video embebido de youtube. \n\n Este es un ejemplo de link válido:"+
                      " \n https://www.youtube.com/watch?v=Hv10ODI8AMs");
                return;
            }
            else if(data.company_demo && data.company_demo.indexOf("vimeo.com") > 0 && data.company_demo.indexOf("user") > 0){
                alert("Para el demo reel utiliza el link directo a un video, no uses enlaces de canales o " +
                      "video embebido de vimeo. \n\n Este es un ejemplo de link válido:"+
                      " \n https://vimeo.com/777755005");
                return;
            }
            if(data.company_demo && data.company_demo.indexOf("youtube")>0){
                data.youtube = data.company_demo;
            }
            else if(data.company_demo && data.company_demo.indexOf("vimeo")>0){
                data.vimeo = data.company_demo;
            }
        }

        let decoded = jwt_decode(localStorage.getItem("token"));        
        data.creator = decoded._id;

        data.status = true;

        console.log(data);

        try {            
            const url = `${process.env.REACT_APP_API_LOCATION}/industry/saveIndustry`;
            const { data: res } = await axios.post(url, data);         
            console.log(res);
            navigate('/industry/'+res._id);
        } 
        catch (error) {
            console.log(error);
            if(error.response && 
                error.response.status >= 400 &&
                error.response.status <= 500) {
                //setError(error.response.data.message);
            }
        }

        
    }

    async function updateStates (event){
        if(event.target.value === 'cualquier'){
            country.current = '';
            state.current = '';
            city.current = '';
        }
        else{
            country.current = event.target.value;
            data.country = event.target.value;
            state.current = '';
            city.current = '';
        }        
        
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getStates`;
        const response = await axios.post(url,{
            "country": event.target.value
        });       
        setStates(response.data);
        setCities([]);        
    };

    async function handleState(e) {
        if(e.target.value === 'cualquier'){            
            state.current = '';
            city.current = '';
        }
        else{
            state.current = e.target.value;
            data.state = e.target.value;
            city.current = '';
        }
                
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCities`;
        const response = await axios.post(url,{
            "state": e.target.value
        });
        setCities(response.data);
    }

    async function handleCity(e) {
        if(e.target.value === 'cualquier'){
            city.current = '';
        }
        else{
            city.current = e.target.value;
            data.city = e.target.value;
        }
    }


    const loadCountries = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCountries`;
        const response = await axios.post(url);
        setCountries(response.data);
    }, []);

    const getInitialData = useCallback(() => {
        setTypeValue("cualquier");
        setCompany({});
    }, []);

    useEffect(() => {
        getInitialData();
        loadCountries();
    }, [getInitialData, loadCountries]);

    return(
        <>
        <Header/>
        <nav>
            <div className="pink">Agregar empresa u organización</div>
        </nav>
        <div className="container">
            
            <div className="row">
                
                <div className="col-lg-12">
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col">
                            <h2>Datos generales</h2>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="company_name">Nombre comercial de la empresa / organización: <span className="text-purple">(Requerido)</span></label>
                            <input 
                                type="text" 
                                name="company_name" 
                                className="form-control" 
                                placeholder="Empresa"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col">
                            Elige los rubros creativos y/o profesionales que realiza tu empresa/ organización. (Puedes elegir varias opciones).
                        </div>
                    </div>
                    <div className="spacer20"></div>

                    <div className="row">                                
                        <div className="col-5 border">
                            <div className="row">
                                <div className="col-12">
                                    <label className="agreements" htmlFor="ocupation"><strong>Tipo de empresa / organización: </strong> </label>
                                    <select 
                                        name="company_type" 
                                        className="form-control agreements" 
                                        value= {typeValue}
                                        onChange={(e) => updateType(e)}>
                                        <option value="cualquier">Seleccionar</option>
                                        {typeCat.map((item, index) => (
                                            <option key={index} value={item.value} disabled={item.disabled}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-center">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="mdi mdi-arrow-right-bold big-size" onClick={handleRightArrow}></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="mdi mdi-arrow-left-bold big-size" onClick={handleLeftArrow}></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                        <div className="col-5 border">
                        <label htmlFor="oc" className="agreements"><strong>Rubros que has seleccionado</strong> <span className="text-purple">(Requerido)</span></label>
                            <select 
                                name="selectedTypes" 
                                className="form-control agreements"                                
                                multiple
                                onClick={(e) => handleRemoveTypes(e)}
                            >                                
                                {selectedTypes.current.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                            <p className="agreements"><strong>Rubros actuales:</strong></p>
                            {company && company.company_type && company.company_type.map((item, index) => (
                                <p className="agreements" key={index}>{item.text}</p>
                            ))}
                            
                        </div>
                    </div>

                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-12">
                        <label htmlFor="company_desc">Descripción de la empresa/ organización: <span className="text-purple">(Requerido)</span></label>
                            <textarea 
                                className="form-control" 
                                rows="5" 
                                placeholder="Descripción de la empresa (1000 caracteres máx)." 
                                maxLength="1000" 
                                name="company_desc"                                        
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-4">
                        <label htmlFor="company_year">Año de fundación: <span className="text-purple">(Requerido)</span></label>
                        <select 
                            name="company_year" 
                            className="form-control agreements"                             
                            onChange={(e) => updateYear(e)}>
                            <option value="cualquier">Seleccionar</option>
                            {yearCat.map((item, index) => (
                                <option key={index} value={item.value} disabled={item.disabled}>{item.label}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col"><h2>Ubicación</h2></div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col">
                            <strong>Actual: {data.country} / {data.state} / {data.city}</strong>
                        </div>
                    </div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="country">País <span className="text-purple">(Requerido)</span></label>
                            <select 
                                name="country" 
                                className="form-control agreements" 
                                onChange={(e) => updateStates(e)}
                            >
                                <option value="cualquier"> Cualquier país </option>
                                {countries.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="state">Estado <span className="text-purple">(Requerido)</span></label>
                            <select 
                                name="state" 
                                className="form-control agreements"
                                onChange={(e) => handleState(e)}
                            >
                                <option value="cualquier"> Cualquier estado </option>
                                {states.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="city">Ciudad <span className="text-purple">(Requerido)</span></label>
                            <select 
                                name="city" 
                                className="form-control agreements"
                                onChange={(e) => handleCity(e)}
                            >
                                <option value="cualquier"> Cualquier ciudad </option>
                                {cities.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col"><h2>En la red</h2></div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col"><label htmlFor="web">Página Web de la empresa/ organización:</label></div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-6">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><span className="mdi mdi-web"></span></div>
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Ej: www.cinekomuna.com" 
                                    maxLength="120" 
                                    name="company_web_page"
                                    onChange={handleChange}
                                />
                
                            </div>
                        </div>
                    </div>
                    <div className="spacer20"></div>
            
                    <div className="row">
                        <div className="col-6">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><span className="mdi mdi-facebook"></span></div>
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Ej: www.cinekomuna.com" 
                                    maxLength="120" 
                                    name="company_facebook_page"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-6">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><span className="mdi mdi-twitter"></span></div>
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Ej: www.cinekomuna.com" 
                                    maxLength="120" 
                                    name="company_twitter_page"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-6">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><span className="mdi mdi-vimeo"></span></div>
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Ej: www.cinekomuna.com" 
                                    maxLength="120" 
                                    name="company_vimeo_page"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-6">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><span className="mdi mdi-youtube"></span></div>
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Ej: www.cinekomuna.com" 
                                    maxLength="120" 
                                    name="company_youtube_page"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col-6">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><span className="mdi mdi-instagram"></span></div>
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Ej: www.cinekomuna.com" 
                                    maxLength="120" 
                                    name="company_instagram_page"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label htmlFor="nombre">Demo reel empresa/ organización </label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><span className="mdi mdi-play"></span></div>
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Ej: www.cinekomuna.com" 
                                    maxLength="120" 
                                    name="company_demo"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div className="row">
                        <div className="col">
                            <h2>Galería</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12"><a href="/" className="text-purple" data-toggle="modal" data-target="#modal3"><br/>Agregar <span className="mdi mdi-plus"></span></a></div>
                    </div>
                    <div className="row">
                        <div className="col agreements">
                            Aún no agregas imágenes a tu galería
                        </div>
                    </div>
                    <div className="spacer80"></div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-purple save">&nbsp;Guardar</button>
                        </div>
                    </div>
                    <div className="spacer80"></div>
                    </form>
                        
                </div>
            </div>
        </div>
        <Footer/>
    </>
    )
}

export default AddIndustry;
