import React from 'react'
import Header from "../common/Header";
import Footer from "../common/Footer";
import IndustryHighlighted from './IndustryHighlighted';
import ProjectHighlighted from "./ProjectHighlighted";
import UserHighlighted from './UserHighlighted';
//import SearchButtons from '../common/SearchButtons';
import Banners from '../common/Banners';
import ScrollToTopButton from '../common/ScrollToTopButton';
//import axios from 'axios';

function Home(props) {

  /*
    const enviarCorreo = async () => {
        try {
          const response = await axios.post('/sendEmail', {
            email: 'ljimenez@lymon.com.mx',
            subject: 'Bienvenido a CK',
            template: 'template1', // Reemplaza con el nombre de tu plantilla
            data: { name: 'Luis', message: 'Este es el mensaje' }
          });
    
          console.log('Correo enviado:', response.data);
        } catch (error) {
          console.error('Error al enviar el correo:', error);
        }
      };*/

    return(
        <div>
            <Header/>
            <Banners/>            
            <ProjectHighlighted/>
            <UserHighlighted type='crew'/>
            <UserHighlighted type='cast'/>
            <IndustryHighlighted/>
            <ScrollToTopButton/>
            <Footer/>
        </div>
    )
}   

export default Home;