import {React, useState, useRef, useCallback, useEffect} from "react";
import axios from "axios";

function SelectLocationModal(props){    

    const {companyId} = props;

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);    
    const country = useRef('');
    const state = useRef('');
    const city = useRef('');

    function updateLocation(e){
        
        try {
            
            const url = `${process.env.REACT_APP_API_LOCATION}/industry/updateLocation`;
            axios.put(url, {
                companyId: companyId,
                country: country.current,
                state: state.current,
                city: city.current
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });

            window.location.reload();

        } catch (error) {
            console.log(error);
        }   
    }

    async function updateStates (event){
        if(event.target.value === 'cualquier'){
            country.current = '';
            state.current = '';
            city.current = '';
        }
        else{
            country.current = event.target.value;
            state.current = '';
            city.current = '';
        }        
        
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getStates`;
        const response = await axios.post(url,{
            "country": event.target.value
        });       
        setStates(response.data);
        setCities([]);        
    };

    async function handleState(e) {
        if(e.target.value === 'cualquier'){            
            state.current = '';
            city.current = '';
        }
        else{
            state.current = e.target.value;
            city.current = '';
        }
                
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCities`;
        const response = await axios.post(url,{
            "state": e.target.value
        });
        setCities(response.data);
    }

    async function handleCity(e) {
        if(e.target.value === 'cualquier'){
            city.current = '';
        }
        else{
            city.current = e.target.value;
        }
    }

    const loadCountries = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCountries`;
        const response = await axios.post(url);
        setCountries(response.data);
    }, []);

    useEffect(() => {
        loadCountries();
    }, [loadCountries]);

    return(
        <div className="modal fade" id="selectLocationModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col"><h2>Ubicación</h2></div>
                            </div>                            
                            <div className="spacer10"></div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="country">País <span className="text-purple">(Requerido)</span></label>
                                    <select 
                                        name="country" 
                                        className="form-control agreements" 
                                        onChange={(e) => updateStates(e)}
                                    >
                                        <option value="cualquier"> Cualquier país </option>
                                        {countries.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="state">Estado <span className="text-purple">(Requerido)</span></label>
                                    <select 
                                        name="state" 
                                        className="form-control agreements"
                                        onChange={(e) => handleState(e)}
                                    >
                                        <option value="cualquier"> Cualquier estado </option>
                                        {states.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="city">Ciudad <span className="text-purple">(Requerido)</span></label>
                                    <select 
                                        name="city" 
                                        className="form-control agreements"
                                        onChange={(e) => handleCity(e)}
                                    >
                                        <option value="cualquier"> Cualquier ciudad </option>
                                        {cities.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="row">
                            <div className="col-6">
                                <div className="col-lg-4"><button type="button" className="btn btn-purple btn-rounded" data-dismiss="modal">Cerrar</button></div>
                            </div>
                            <div className="col-6">
                                <div className="col-lg-4"><button type="button" className="btn btn-purple btn-rounded" data-dismiss="modal" onClick={updateLocation}>Guardar</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectLocationModal;