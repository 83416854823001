import React, {useContext } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import styles from './styles.header.scss';
import { UserContext } from '../../context';


const Header = () => {

    const [state, setState] = useContext(UserContext);    
    const navigate = useNavigate();

    const handleLogout = () => {
        setState({data:null, error:null, loading:false});
        //Remove token from local storage
        localStorage.removeItem('token');
        navigate('/');
    }

    function gotoMedia (){
        navigate('/media', {
            state: {
              source: 'none',
              id: state.data._id,
              currentGallery: []
            }
        });
    }

    return(
        <header className={styles.header}>
            <nav className="navbar navbar-expand-md navbar-dark bg-dark d-none d-md-flex">
                <a className="navbar-brand" href="/">
                    <img src="/logo.png" alt="logo" className="header-logo rounded-circle d-inline-block align-text-middle"/>
                    <strong>&nbsp;Cinekomuna</strong>
                </a>
                
                {/**Inicia bloque de usuarios no firmados */}
                {!state.data && 
                <div className="collapse navbar-collapse justify-content-end w-100 order-3">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link mt3 text-white" href="/searchCrew"> <span className="mdi mdi-magnify"></span>&nbsp; Búsqueda</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mt3 text-white" href="https://blog.cinekomuna.com"> <span className="mdi mdi-post"></span>&nbsp; Blog</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mt3 text-white" href="/register"> <span className="mdi mdi-pencil"></span>&nbsp; Registrarse</a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link mt3 text-white" to="/login"> <span className="mdi mdi-login"></span>&nbsp; Iniciar sesión</Link>
                        </li>
                    </ul>
                </div>
                }
                {/**Termina bloque de usuarios no firmados */}

                {/**Inicia bloque de usuarios firmados */}
                {state.data && 
                <div className="collapse navbar-collapse justify-content-end w-100 order-3">
                    
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link mt3 text-white" href="/searchCrew"> <span className="mdi mdi-magnify"></span>&nbsp; Búsqueda</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mt3 text-white" href="https://blog.cinekomuna.com"> <span className="mdi mdi-post"></span>&nbsp; Blog</a>
                        </li>
                        <nav className="navbar navbar-default">
                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            {!true &&
                                <ul className="nav navbar-nav navbar-right">
                                <li className="dropdown">
                                    <a href="/" className="dropdown-toggle" id="goToMessages"><span className="mdi mdi-email text-white"></span>
                                    <span className="badge badge-danger">0</span>
                                    </a>
                                </li>
                                </ul>
}
                            </div>

                        </nav>
                        <nav className="navbar navbar-default">
                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul className="nav navbar-nav navbar-right">
                                <li className="dropdown">
                                {!true &&                                
                                    <a href="/" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span className="mdi mdi-bell text-white">
                                    <span className="badge badge-danger">0</span>
                                    </span></a>
                                }
                                    <ul className="dropdown-menu dropdown-menu-wide notify-drop dropdown-menu-right">
                                    <div className="notify-drop-title">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-1 agreements">
                                            <div className="title-noti"> Notificaciones<br/>
                                                <span>Nuevas</span>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="drop-content">
                                        
                                        <li>
                                            <div className="col-md-12 col-sm-12 agreements notificationRead">
                                                <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                    <div className="col">No tienes notificaciones</div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="notify-drop-footer text-center">
                                        <a href="/notificationList" className="agreements">Ver todas</a>
                                    </div>
                                    </ul>
                                    
                                </li>
                                </ul>
                            
                            </div>
                        </nav>   
                    </ul>
                    
                    <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="image-container-50">
                                <img src={state.data.photo} className='image' alt="Profile"/>                                
                            </div>                            
                        </a>
                        
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <div className="row m-0">
                            
                            <div className="col-lg-10">
                                <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col">
                                            <strong>{state.data.fullname}</strong>    
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            {state.data.role}
                                        </div>
                                    </div>
                                    
                                </div>
                                </div>
                            </div>
                            </div>
                            
                            <div className="row">
                            <div className="col"><div className="spacer10"></div></div>
                            </div>
                            <div className="dropdown-divider"></div>
                            <h4 className="dropdown-header">PERFIL</h4>
                            {!state.data.isCrew && !state.data.isCast &&
                            <a className="dropdown-item text-black" href={`/crew/${state.data._id}`}><span className="mdi mdi-movie"></span>&nbsp;Ver perfil</a>
                            }
                            {state.data.isCrew &&
                            <a className="dropdown-item text-black" href={`/crew/${state.data._id}`}><span className="mdi mdi-movie"></span>&nbsp;Ver perfil de crew</a>
                            }
                            {state.data.isCast &&
                            <a className="dropdown-item text-black" href={`/cast/${state.data._id}`}><span className="mdi mdi-movie"></span>&nbsp;Ver perfil de cast</a>
                            }
                            
                            <div className="dropdown-divider"></div>
                            <h4 className="dropdown-header">CUENTA</h4>
                            <a className="dropdown-item text-black" href="/editProfile"><span className="mdi mdi-account-edit"></span>&nbsp;Editar perfil</a>
                            <a className="dropdown-item text-black" href="/subscription"><span className="mdi mdi-wallet-membership"></span>&nbsp;Membresía</a>
                            
                            <div className="dropdown-divider"></div>
                            
                            <h4 className="dropdown-header">ADMINISTRAR</h4>
                            <a className="dropdown-item text-black" href="/ProjectList"><span className="mdi mdi-briefcase"></span>&nbsp;Mis producciones / muestras</a>
                            <a className="dropdown-item text-black" href="/IndustyList"><span className="mdi mdi-domain"></span>&nbsp;Mis empresas</a>
                            <div className="dropdown-item text-black pointer" onClick={gotoMedia}><span className="mdi mdi-image pointer"></span>&nbsp;Mis imágenes</div>
                            
                            {/*
                            <a className="dropdown-item text-black" href="/collaborations"><span className="mdi mdi-handshake-outline"></span>&nbsp;Mis colaboraciones</a>
                            
                            <div className="">
                            <a className="dropdown-item text-black" href="/cms"><span className="mdi mdi-key"></span>&nbsp;Plataforma</a>
                            </div>
                            */}
                            <h4 className="dropdown-header">HERRAMIENTAS</h4>
                            
                            <a className="dropdown-item text-black" href="https://blog.cinekomuna.com"><span className="mdi mdi-post-outline"></span>&nbsp;Blog</a> 
                            <div className="dropdown-divider"></div>
                            <form onSubmit={handleLogout}>                                                            
                                <button type="submit" className="dropdown-item text-black"><span className="mdi mdi-logout"></span>&nbsp;Cerrar sesión</button>
                            </form>
                            
                            
                        </div>
                        </li>   
                    </ul>   
                </div>
                }
                {/**Termina bloque de usuarios firmados */}
            </nav>
            
            {/*Inicia menú para dispositivos de pantalla pequeña*/}
            <nav className="navbar navbar-dark bg-dark navbar-expand d-md-none">
                <button className="navbar-toggler order-2" type="button" data-toggle="collapse" data-target="#navbar-list-9" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand" href="/">
                    <img src="/logo.png" alt="logo" className="header-logo rounded-circle d-inline-block align-text-middle"/>
                    <strong>&nbsp;Cinekomuna</strong>
                </a>
                <div className="container-fluid d-md-none">
                    {/**Inicia bloque de usuarios no firmados en celular */}
                    <div className="col-12 ">
                        <div className="row">
                            <div className="col-4 p-2 ">
                                <a className="nav-link mt3 text-white" href="cinekomuna.com"> <span className="mdi mdi-pencil"></span></a>
                            </div>
                            <div className="col-4 p-2 ">
                                <a className="nav-link mt3 text-white" href="cinekomuna.com"> <span className="mdi mdi-login"></span></a>
                            </div>
                            <div className="col-4 p-2 ">
                                <a className="nav-link mt3 text-white" href="cinekomuna.com"> <span className="mdi mdi-magnify"></span></a>
                            </div>
                        </div>
                    </div>
                    {/**Termina bloque de usuarios no firmados en celular */}
                </div>
            </nav>
            {/*Termina menú para dispositivos de pantalla pequeña*/}
            
        </header>
    )

} 

export default Header;