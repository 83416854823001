import {React, useState, useRef} from "react";
import {typeCat} from "../../edition/industry/selectCatalog";
import axios from "axios";
import config from "../../../config";

function SelectRoleModal(props){    

    const {companyId} = props;

    const [typeValue, setTypeValue] = useState("");
    const selectedTypes = useRef([]);    
    const [selectedToRemove, setSelectedToRemove] = useState([]);
    function updateType(e){
        console.log(e.target.value);
        setTypeValue(e.target.value);
    }

    function handleRightArrow(e){
        e.preventDefault();
        if(typeValue !== '' && typeValue !== 'cualquier'){
            let obj = selectedTypes.current.find(o => o === typeValue);
            if(obj === undefined){
                selectedTypes.current.push(typeValue);
            }
            else{
                alert("Ya agregaste este tipo");
            }                        
        }
        setTypeValue("cualquier");
    }   

    function handleLeftArrow(e){
        e.preventDefault();
        let newArray = selectedTypes.current;
        if(selectedToRemove.length > 0){
            for(let i = 0; i < selectedToRemove.length; i++){
                let obj = newArray.find(o => o === selectedToRemove[i]);

                if(obj !== undefined){
                    newArray = newArray.filter(item => item !== selectedToRemove[i]);
                }
            }
        }
        selectedTypes.current = newArray;
        setSelectedToRemove([]);
    }
    function handleRemoveTypes(e) {
        let options = document.getElementById('selectedTypes').selectedOptions;
        let values = Array.from(options).map(({ value }) => value);
        setSelectedToRemove(values);
    }

    function updateTypes(e){
        try {
            const url = `${process.env.REACT_APP_API_LOCATION}/industry/updateTypes`;
            axios.put(url, {
                companyId: companyId,
                company_type: selectedTypes.current
            }, {
                headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`}
            });

            window.location.reload();

        } catch (error) {
            console.log(error);
        }   
    }

    return(
        <div className="modal fade" id="selectRoleModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    Elige los rubros creativos y/o profesionales que realiza tu empresa/ organización. (Puedes elegir varias opciones).
                                </div>
                            </div>
                            <div className="spacer20"></div>

                            <div className="row">                                
                                <div className="col-5 border">
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="agreements" htmlFor="ocupation"><strong>Tipo de empresa / organización: </strong> </label>
                                            <select 
                                                name="company_type" 
                                                className="form-control agreements" 
                                                value= {typeValue}
                                                onChange={(e) => updateType(e)}>
                                                <option value="cualquier">Seleccionar</option>
                                                {typeCat.map((item, index) => (
                                                    <option key={index} value={item.value} disabled={item.disabled}>{item.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className="mdi mdi-arrow-right-bold big-size" onClick={handleRightArrow}></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="mdi mdi-arrow-left-bold big-size" onClick={handleLeftArrow}></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                </div>
                                <div className="col-5 border">
                                <label htmlFor="oc" className="agreements"><strong>Rubros que has seleccionado</strong> <span className="text-purple">(Requerido)</span></label>
                                    <select 
                                        name="selectedTypes" 
                                        id="selectedTypes"
                                        className="form-control agreements"                                
                                        multiple
                                        onClick={(e) => handleRemoveTypes(e)}
                                    >                                
                                        {selectedTypes.current.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>                                    
                                    
                                </div>
                            </div>
                        </div>
                           
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="row">
                            <div className="col-6">
                                <div className="col-lg-4"><button type="button" className="btn btn-purple btn-rounded" data-dismiss="modal">Cerrar</button></div>
                            </div>
                            <div className="col-6">
                                <div className="col-lg-4"><button type="button" className="btn btn-purple btn-rounded" data-dismiss="modal" onClick={updateTypes}>Guardar</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectRoleModal;