import React, { useState } from "react";
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';

const Signup = () => {

    const[data, setData] = useState({
        name: "",
        lastname: "",
        email: "",
        password: "",
    });

    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleChange = ({currentTarget: input}) => { 
        setData({...data, [input.name]: input.value});
    };

    const handleSubmit = async (e) => {   
        e.preventDefault();
        console.log(data);
        try {            
            const url = `${process.env.REACT_APP_API_LOCATION}/auth/register`;
            const { data: res } = await axios.post(url, data);            
            navigate("/login");
            console.log(res.message);
        } 
        catch (error) {
            if(error.response && 
                error.response.status >= 400 &&
                error.response.status <= 500) {
                setError(error.response.data.message);
            }
        }
    };

    return(

        <div className="signup">
            <div className="signup__container">
                <h1>Welcome back</h1>
                <Link to="/login">
                    <button className="signup__loginButton">Login</button>
                </Link>
            </div>
            <div className='signup__form'>
                <form onSubmit={handleSubmit}>
                    <h1>Signup</h1>
                    <input 
                        type="text" 
                        placeholder="Name" 
                        name='name'
                        onChange={handleChange}
                        value={data.name}
                        required
                        className="form-data"
                    />
                    <input 
                        type="text" 
                        placeholder="Last name"
                        name='lastname'
                        onChange={handleChange}
                        value={data.lastname}
                        required
                        className="form-data"
                    />
                    <input 
                        type="email" 
                        placeholder="Email" 
                        name='email'
                        onChange={handleChange}
                        value={data.email}
                        required
                        className="form-data"
                    />
                    <input 
                        type="password" 
                        placeholder="Password" 
                        name='password'
                        onChange={handleChange}
                        value={data.password}
                        required
                        className="form-data"
                    />
                    
                    {error && <div className="alert alert-danger">{error}</div>}

                    <button className="btn btn-purple">Signup</button>
                </form>
            </div>
        </div>
        
    )
}

export default Signup;