import React, { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import axios from "axios";

function ProjectCompany (props) {
    const [company, setCompany] = useState([]);
    const { showBoundary } = useErrorBoundary();

    const projectId = props.projectId;

    /**
     * Paginación
     */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(9);

    // Calcula el número total de páginas
    const totalPages = Math.ceil(company.length / itemsPerPage);

    // Calcula el índice del primer elemento en la página actual
    //const startIndex = (currentPage - 1) * itemsPerPage;

    // Obtiene los elementos que se mostrarán en la página actual
    //const currentItems = company.slice(startIndex, startIndex + itemsPerPage);    

    // Maneja el cambio de página hacia atrás
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setItemsPerPage(9); //Esta línea sólo es para que no marque warning por no usar setItemsPerPage
        }
    };

    // Maneja el cambio de página hacia adelante
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            setItemsPerPage(9); //Esta línea sólo es para que no marque warning por no usar setItemsPerPage
        }
    };

    

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        axios.get(`${process.env.REACT_APP_API_LOCATION}/project/getCompaniesFromProject/${projectId}`, {
            cancelToken: cancelToken.token,
        })
        .then((response) => {
            setCompany(response.data);
            console.log(response.data);
        })
        .catch((error) => {
            if(axios.isCancel(error)){
                console.log("Request cancelled");
            }
            else{
                showBoundary(error);
                console.error('Error al obtener los datos de proyectos de la empresa:', error);
            }
        });

        return () => {
            cancelToken.cancel();
        }

    }, [projectId, showBoundary]);
   

    return(
        <>        
        {company && company.length > 0 &&
        <div className="container">
            <div className="spacer20"></div>
            <div className="row">
                <div className="col">
                    <strong className="h1-title">EMPRESAS</strong>
                    <hr/>
                </div>
            </div>
            <div className="spacer20"></div>
            
            <div className="row mb-2">
                <div className="col">
                    <div className="row">
                    {company.map((item, index) => {
                            
                            
                            return(
                                <div className="col-lg-3 col-md-4 staffContainer" key={index}>                               
                                    <a href={"/industry/"+item._id}>
                                        <img 
                                            src={item.logo} 
                                            className="staffImg" 
                                            data-toggle="tooltip" 
                                            data-placement="top" 
                                            title={item.name} 
                                            alt="Logo"
                                        />
                                        
                                        <span className="people-name-text-circle one-line text-center">{item.name}</span>
                                        
                                    </a>
                                </div>
                           );
                        })}
                        
                    </div>
                    {totalPages > 1 &&
                    <div className="row">
                        <div className="col-6 text-center">
                            <button aria-label="Previous" className="btn text-black" onClick={handlePrevPage} disabled={currentPage === 1}>
                                <i className="mdi mdi-arrow-left-circle text-black"></i>
                                
                            </button>
                        </div>
                        <div className="col-6 text-center">
                            <button aria-label="Next" className="btn text-black" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                <i className="mdi mdi-arrow-right-circle text-black"></i>
                            </button>                            
                        </div>
                    </div>
                    }
                </div>
                
            </div>
            
        </div>
        }
        </>
    )
};

export default ProjectCompany;