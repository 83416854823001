import React from "react";
//import ModalAddCollaborator from "./modals/ModalAddCollaborator";
//import AddText from "./modals/AddText";
require("./styles.scss");
// eslint-disable-next-line import/no-anonymous-default-export

 
const ProjectEditMenu = ({handleShow, handleShowQuote, handleShowLink, handleShowVideo, handleShowImage}) => {
    
    return(
        <div>                
            
            <>
            <div className="container-fluid background-blue mx-auto">
                    <div className="spacer5"></div>
                    <div className="row">
                        <div className="col-lg-2 col-md-4 text-center pm5">
                            <div className="dropdown show">
                                <a className="text-white dropdown-toggle" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="mdi mdi-plus-box"></span> <span className="white-link"> Agregar Sección</span>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a className="dropdown-item clear_portlet" href="#addText" onClick={handleShow}>Texto</a>
                                    <a className="dropdown-item clear_portlet" href="#addImage" onClick={handleShowImage}>Imagen</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item clear_portlet" href="#addVideo" onClick={handleShowVideo}>Video</a>
                                    <a className="dropdown-item clear_portlet" href="#addQuote" onClick={handleShowQuote}>Frase</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item clear_portlet" href="#addLink" onClick={handleShowLink}>Link</a>
                                </div>
                            </div>
                        </div>
                        {/*
                        <div className="col-lg-2 col-md-4  text-center bordes pm5"><a href="/" className="white-link" data-toggle="modal" data-target="#modalAddCollaborator"><span className="mdi mdi-account"></span> <span className="white-link"> Agregar Colaboradores</span> </a></div>
                        */}
                        <div className="col-lg-3 d-none d-lg-block">&nbsp;</div>
                    </div>
                    <div className="spacer5"></div>
                </div>
            
            </>
            
            {
            //<ModalAddCollaborator/>
            //<AddText projectId={project._id}/>
            }
        </div>
    )
}

export default ProjectEditMenu;