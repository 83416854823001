import {React, useEffect, useState, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context";
require("./styles.scss");

function ProjectCover (props) {
    const navigate = useNavigate();
    const [state] = useContext(UserContext);

    const {cover, projectId, owner} = props;
    const [coverArray, setCoverArray] = useState([]);
    const [canEdit, setCanEdit] = useState(false);
    

    function gotoUploadWidget (){
        console.log(projectId,"gotoUploadWidget");
        navigate('/media', {
            state: {
              source: 'projectCover',
              id: projectId,
              currentGallery: coverArray
            }
        });
    }

    useEffect(() => {
        let tmpArray = [];
        tmpArray.push(cover);
        setCoverArray(tmpArray);

        if(state && state.data && state.data._id && owner === state.data._id){
            setCanEdit(true);
        }

    }, [cover , state, owner]);


    if (!cover) {
        return <div>Cargando...</div>;
    }

    return(
        <div>
            {cover && cover.url &&
            <div 
                 className="container-fluid bg-overlay" 
                 style={{                    
                    background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${(cover.url)})`
                  }}>
                <div className="spacer280"></div>
                {canEdit &&
                <button onClick={gotoUploadWidget} className="text-white icon-over-cover no-border" id="openMediaGallery">
                    <span className="mdi mdi-camera"></span>
                </button>
                }
            </div>
            }
            {!cover.url && 
            <div className="container-fluid bg-overlay-no-img">
                <div className="spacer280"></div>
                {state.data._id === owner &&
                <button onClick={gotoUploadWidget} className="text-white icon-over-cover no-border" id="openMediaGallery">
                    <span className="mdi mdi-camera"></span>
                </button>
                }
            </div>
            }
        </div>
    )
}


export default ProjectCover;