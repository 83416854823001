import {React} from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/dist/photoswipe.css';

export default function ProjectGallery(props) {

  const {project} = props;
  const images = project.gallery;
  //console.log(images);

  const PREFIX = process.env.REACT_APP_PREFIX_FOR_IMAGES;

  const lightbox = new PhotoSwipeLightbox({
    gallery: "#my-gallery",
    children: "a",
    
    pswpModule: () => import("photoswipe")
  });
  
  lightbox.init();

  if (!images) {
    return <div>Cargando...</div>;
  }

  return (    
      
    <div className="pswp-gallery" id="my-gallery">      

      {images.map((image, index) => (
        <a
          href={PREFIX + project.userId + "/"+ image.url}
          data-pswp-width={image.width}
          data-pswp-height={image.height}
          target="_blank"
          rel='noreferrer'
          key={index}
        >
          <img
            src={PREFIX + project.userId + "/"+ image.url}
            alt=""
            className="gallery-separator thumbnail"
          />
        </a>
        ))}
        
      </div>
  );
}