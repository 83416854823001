export const IndustryCatalog = [
    "",
    "PRINCIPALES",
    "-----------",
    "Agencia de casting",
    "Agencia de publicidad ",
    "Agencia de representación/ management ",
    "Agencia y/o estudio doblaje/ locutores ",
    "Asociación/ Organización/ Redes ",
    "Bodega de arte",
    "Bodega de vestuario",
    "Cámaras empresariales cine y televisión",
    "Canal de televisión",
    "Casa post-productora (Audio)",
    "Casa post-productora (Imagen)",
    "Casa productora",
    "Catering",
    "Comisiones de filmación ",
    "Distribuidora",
    "Escuelas (Cine/ Medios audiovisuales)",
    "Estudio de animación",
    "Exhibición (Salas/ Cineclubs/ Espacios )",
    "Festivales / Muestras de cine",
    "Grupo Gaffer/ Staff/ Electrico ",
    "Institución de gobierno",
    "Locaciones",
    "Musicalización/ Musica original ",
    "Plataforma de streaming",
    "Renta de equipo",
    "Renta de equipo (audio)",
    "Renta de foro/ set",
    "Servicios de producción",
    "Sindicatos",
    "",
    "SECUNDARIAS",
    "-----------",
    "Agencia aduanal/ logistica cine",
    "Agencia de extras",
    "Agencia de investigación de mercado (Cine/ publicidad)",
    "Agencia de modelos",
    "Agencia de trailers",
    "Agencia financiera producciones audiovisuales",
    "Agencia marketing cine",
    "Animal wrangler",
    "Armas para producciones audiovisuales",
    "Aseguradora/ seguros producciones audiovisuales",
    "Book actores (Servicios foto y video)",
    "Colectivo/ Laboratorio cinematografico",
    "Compañía baile para producciones audiovisuales",
    "Compañía/ Asociación teatral",
    "Contabilidad para producciones audiovisuales",
    "Copywriter",
    "Derechos/ copyright musical",
    "Desarrollo de carpetas",
    "Despacho/ Asesoría legal",
    "Diseño gráfico (Posters, postales ,tipografia)",
    "Diseño web ",
    "Disfraces y pelucas",
    "Drone/ Fotografía aérea",
    "Dummies",
    "Efectos caracterización (maquillaje, prostéticos, botargas)",
    "Efectos SFX (Efectos especiales onset. Explosiones/ lluvia/ etc)  ",
    "Efectos VFX/ CGI/ Motion  ",
    "Escuela de actuación",
    "Escuela de animación",
    "Estudio creativo/ Art direction",
    "Estudio de fotografía",
    "Estudio de grabación (Música/ audio/ follys/ etc)",
    "Fondos/ Premios/ Residencias/ Talleres",
    "Helicópteros para producciones audiovisuales",
    "Jardinería cinematográfica (Greensman)",
    "Jingles",
    "Laboratorio/ Revelado fotográfico",
    "Limpieza/ Sanitización set",
    "Maquetas para producciones audiovisuales",
    "Provedores luces cinematograficas",
    "Proveedores equipo de audio",
    "Proveedores expendables",
    "Proveedores material cinematográfico (Celuloide)",
    "Proveedores material maquillaje y peinados",
    "Proveedores vídeo y fotografía",
    "Realidad virtual",
    "Renta de campers",
    "Renta de equipo especializado",
    "Renta de pipas agua",
    "Renta de transporte (Vans/ camionetas)",
    "Renta de video assist / DIT",
    "Renta de walkie talkie (radio comunicación)",
    "Renta picture cars",
    "Renta plantas de luz / generadores",
    "Renta sanitarios moviles",
    "Reproducciones",
    "Revista/ Blogs/ Podcasts de cine",
    "Seguridad/ Vigilancia set",
    "Servicio de subtitulaje/ traducción",
    "Servicios edicion",
    "Servicios escritura guión/ script doctor",
    "Servicios grabación sonido directo",
    "Servicios streaming/ transmisión",
    "Stock video y foto/ Archivo fílmico",
    "Storyboard",
    "Stunts",
    "Video 360",
    "Video mapping"
];

    export const IndustryYears = [
        "2025",
        "2024",
        "2023",
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
        "2013",
        "2012",
        "2011",
        "2010",
        "2009",
        "2008",
        "2007",
        "2006",
        "2005",
        "2004",
        "2003",
        "2002",
        "2001",
        "2000",
        "1999",
        "1998",
        "1997",
        "1996",
        "1995",
        "1994",
        "1993",
        "1992",
        "1991",
        "1990",
        "1989",
        "1988",
        "1987",
        "1986",
        "1985",
        "1984",
        "1983",
        "1982",
        "1981",
        "1980",
        "1979",
        "1978",
        "1977",
        "1976",
        "1975",
        "1974",
        "1973",
        "1972",
        "1971",    
      ];
    