import React, { useEffect, useCallback, useRef, useState, useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';
import axios from "axios";
import IndustryCover from "./IndustryCover";
import IndustryMeta from "./IndustryMeta";
import ScrollToTopButton from "../common/ScrollToTopButton";
import IndustryBody from "./IndustryBody";
import jwtDecode from "jwt-decode";
import { Button } from "react-bootstrap";
import { UserContext } from "../../context";


function IndustryPage(props) {
    
    const[industry, setIndustry] = useState({});
    const[showIndustry, setShowIndustry] = useState(false);
    const { showBoundary } = useErrorBoundary();
    const [state] = useContext(UserContext);

    const navigate = useNavigate();
     
    

    let industryId = null;
    const location = useLocation();
    if(location) {
        industryId = location.pathname.slice(location.pathname.lastIndexOf("/")+1 , location.pathname.length);
    }

    let decoded = useRef(null);

    const updateViews = useCallback(async () => {
        let update = true;
        if(localStorage.getItem("token")){
            decoded.current = jwtDecode(localStorage.getItem("token"));
            if (decoded.current._id === industry.creator) {
                update = false;
            } else {
                update = true;
            }
        }

        if (update) {
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/industry/addView`;
                await axios.put(url, {
                    industryId: industry._id
                });
            } catch (error) {
                console.log(error);
            }
        }

    }, [industry._id, industry.creator]);

   
    const editIndustry = () => {
        navigate(`/industryForm/${industry._id}`);
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        
        axios.get(`${process.env.REACT_APP_API_LOCATION}/industry/getIndustryMeta/${industryId}`, {
            cancelToken: cancelToken.token,
        })
        .then((response) => {
            if(!response.data.invisible){
                console.log("La empresa está visible")
                //console.log(response.data);
                setIndustry(response.data);
                updateViews();
                setShowIndustry(true);
            }
            else if(response.data.invisible){
                console.log("La empresa está invisible");
                console.log(state.data);
                if(state && state.data && state.data._id === response.data.creator){
                    console.log("El usuario es dueño de la empresa");
                    setIndustry(response.data);
                    setShowIndustry(true);
                }
                else{
                    console.log("El usuario no es dueño de la empresa");
                    //navigate('/notfound');
                    setShowIndustry(false);
                }
                
            }
            else{
                console.log("La empresa no existe o no está visible o el usuario no tiene permisos para verla");
                //navigate('/notfound');
                setShowIndustry(false);
            }
        })
        .catch((error) => {
            if(error.response.request.status===404){
                navigate('/notfound');
            }
        });        

        return () => {
            cancelToken.cancel();
        }

        
    }, [industryId, updateViews, showBoundary, navigate, state]);

    if(!industry){
        return <div>Cargando ...</div>;
    }

    if(!showIndustry){
        setTimeout(() => {
            <>
                <Header/>
                    <div className="container">
                        <div className="spacer120"></div>
                        <div className="row">
                            <div className="col-12">
                                <p>La empresa que estás buscando no existe.</p>
                                <p className="mb-0">Por favor, verifica que la URL sea correcta.</p>
                            </div>
                        </div>
                        <div className="spacer120"></div>
                    </div>
                <Footer/>   
                </>
        }, 5000);
    }


    return(
        
        <div>
            <Header/>
            {state && state.data && state.data._id && industry.creator === state.data._id &&
            <Button className="fixed-button btn-purple" onClick={editIndustry}>Editar empresa</Button>
            }
            <IndustryCover industry={industry}/> 
            <IndustryMeta industry={industry}/>
            <IndustryBody industry={industry}/>
            <ScrollToTopButton/>
            <Footer/>
        </div>
    )
}

export default IndustryPage;


