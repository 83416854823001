import {React, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from 'react-router-dom';
import Header from "../common/Header";
import Footer from "../common/Footer";
import CastCover from "./CastCover";
import CastMeta from "./CastMeta";
import CastDemo from "./CastDemo";
import CastGallery from "./CastGallery";
import CastSidebar from "./CastSidebar";
//import CastProjects from "./CastProjects";
//import ProgressDashboard from "./ProgressDashboard";
import { Button } from "react-bootstrap";
import ScrollToTopButton from "../common/ScrollToTopButton";
import axios from "axios";
import jwtDecode from "jwt-decode";
import './styles.scss';


function CastPage() {
    const { showBoundary } = useErrorBoundary();


    const navigate = useNavigate();
    let localToken = localStorage.getItem('token');
    let decoded = null;

    const [person, setPerson] = useState({});
    const [views , setViews] = useState(0);
    const [likes , setLikes] = useState(0);

    let personId = null;
    const location = useLocation();
    if(location) {
        personId = location.pathname.slice(location.pathname.lastIndexOf("/")+1 , location.pathname.length);
    }

    const editProfile = () => {
        navigate(`/editProfile/`);
    }
  
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if(personId){

            axios.get(`${process.env.REACT_APP_API_LOCATION}/cast/getUserMetadata/${personId}`, {
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                setPerson(response.data);
                setViews(response.data.views);
                setLikes(response.data.likes);
                axios.put(`${process.env.REACT_APP_API_LOCATION}/cast/addView`, {
                    personId: personId
                }, {
                    cancelToken: cancelToken.token,
                })
                .then((response) => {
                    console.log("Vistas: " + response.data.views);
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al actualizar las vistas:', error);
                });
            })
            .catch((error) => {
                if(error.response.request.status===404){
                    navigate('/notfound');
                }
                
            });

            

            /*if(localToken){

                axios.get(`${process.env.REACT_APP_API_LOCATION}/auth/validate`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    if(response.data.token){
                        setActiveSession(true);
                    }        
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al validar la sesión:', error);
                });
            }*/
        }

        return () => {
            cancelToken.cancel();
        }

    }, [personId, localToken, showBoundary, navigate]);

    const hasEditPermission = () => {
        let result = false;
        if(localStorage.getItem("token")){
            decoded = jwtDecode(localStorage.getItem("token"));
            if (decoded._id === person._id) {
                result = true;
            } else {
                result = false;
            }
        }
        return result;
        
    }

    let name = "";
    name = person.fullname;

    //extrayendo el nombre sin apellidos
    if(name){
        name = name.split(" ")[0];
    }   


    if(!person){
        return <div>Cargando...</div>;
    }


    if(person && person.cast && person.cast.invisible && !hasEditPermission()){
        return (

            setTimeout(() => {
               <>
                <Header/>
                    <div className="container">
                        <div className="spacer120"></div>
                        <div className="row">
                            <div className="col-12">
                                <p>El perfil que estás buscando no existe.</p>
                                <p className="mb-0">Por favor, verifica que la URL sea correcta.</p>
                            </div>
                        </div>
                        <div className="spacer120"></div>
                    </div>
                <Footer/>   
                </> 
            }, 1000)
            
        )
    }

  return (
    <div>
        <Header/>
        {hasEditPermission() &&
            <Button className="fixed-button btn-purple" onClick={editProfile}>Editar perfil</Button>
        }
            {/*hasEditPermission() &&
                <ProgressDashboard isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} person={person}/>
  */}

            <CastCover person={person}/>
            
            <CastMeta person={person} views={views} likes={likes} />
        
            <div className="spacer40"></div>
            <div className="container">
                <div className="row">
                   <div className="col-lg-8  col-sm-12">
                        <CastDemo person={person}/>
                        <CastGallery person={person}/>
                   </div>
                   <div className="col-lg-4 col-sm-12">
                        <CastSidebar person={person}/>
                   </div>
                </div>
                <div className="spacer40"></div>
                {
                //<CastProjects/>
            }
                <div className="spacer80"></div>
            </div>
            <ScrollToTopButton/>
        <Footer/>
    </div>
  );
  
}


export default CastPage;