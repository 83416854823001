import {React, useState, useEffect, useRef} from 'react'
import { useLocation } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import FormLabel from '../common/formComponents/FormLabel';
import FormSelect from '../common/formComponents/FormSelect';
import FormTextareaInput from '../common/formComponents/FormTextareaInput';
import FormTextInput from '../common/formComponents/FormTextInput';
import FormSwitch from '../common/formComponents/FormSwitch';
import FormButton from '../common/formComponents/FormButton';
import IndustryGallery from './IndustryGallery';
import FormValidationMessage from '../common/formComponents/FormValidationMessage';
import { verifyVideoURL, verifyValidSite, verifyValidURL } from "../common/formComponents/Validations";
import Header from '../common/Header';
import Footer from '../common/Footer';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import IndustryCover from './IndustryCover';


function IndustryForm() {
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate();

    const [years, setYears] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [toggleDangerZone, setToggleDangerZone] = useState(false);
    const [formError, setFormError] = useState(null);
    const [invisible, setInvisible] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [enable, setEnable] = useState(false);


    const country = useRef('');
    const state = useRef('');
    const city = useRef('');

    const initialState = {
        _id: '',
        creator: '',
        company_name: '',
        company_type: '',
        company_year: '',
        description: '',
        country: '',
        state: '',
        city: '',
        company_web_page: '',
        company_facebook_page: '',
        company_twitter_page: '',
        company_vimeo_page: '',
        company_youtube_page: '',
        company_instagram_page: '',
        company_imdb_page: '',
        company_demo: '',
        gallery: [],
        cover:{},
        logo:{}
    }

    const [formData, setFormData] = useState(initialState);

    let industryId = null;
    const location = useLocation();
    if(location) {
        industryId = location.pathname.slice(location.pathname.lastIndexOf("/")+1 , location.pathname.length);
    }

    const gotoIndustry = () => {
        navigate('/industry/'+industryId);
    };

    const gotoProfile = () => {
        navigate('/editProfile');
    };


    const handleChange = (e) => {
        setFormError(null);
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    function handleDangerZoneToggle () {
        setToggleDangerZone(!toggleDangerZone)
    }

    function gotoUploadGallery (){
        navigate('/media', {
            state: {
              source: 'industryGallery',
              id: industryId,
              currentGallery: formData.gallery
            }
        });
    }

    function gotoUploadWidget (){
        navigate('/media', {
            state: {
              source: 'industryLogo',
              id: industryId,
              currentGallery: formData.logo
            }
        });
    }

    

    async function updateStates (event){
        if(event.target.value === 'cualquier'){
            country.current = '';
            state.current = '';
            city.current = '';
        }
        else{
            country.current = event.target.value;
            state.current = '';
            city.current = '';
            setFormData({...formData, [event.target.name]: event.target.value});
        }        
        
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getStates`;
        const response = await axios.post(url,{
            "country": event.target.value
        });       
        setStates(response.data);
        setCities([]);        
    };

    async function handleState(e) {
        if(e.target.value === 'cualquier'){            
            state.current = '';
            city.current = '';
        }
        else{
            state.current = e.target.value;
            state.current = e.target.value;
            city.current = '';
            setFormData({...formData, [e.target.name]: e.target.value});
        }
                
        const url = `${process.env.REACT_APP_API_LOCATION}/location/getCities`;
        const response = await axios.post(url,{
            "state": e.target.value
        });
        setCities(response.data);
    }

    async function handleCity(e) {
        if(e.target.value === 'cualquier'){
            city.current = '';
        }
        else{
            city.current = e.target.value;
            setFormData({...formData, [e.target.name]: e.target.value});
        }
    }
    

    const switchInvisible = async (e) => {
        let text = "";
        if(invisible){
            text = "¿Deseas activar la empresa?";
        }
        else{
            text = "¿Deseas ocultar momentáneamente la empresa?";
        }
    
        if(window.confirm(text)){
            
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/industry/switchInvisible`;
                await axios.post(url, {
                    industryId: industryId,
                    invisible: !invisible
                })
                .then((response) => {
                    let msg = "";
                    if(invisible){
                        msg = "La empresa ha sido activada";
                    }
                    else{
                        msg = "La empresa ha sido desactivada";
                    }
                    setInvisible(!invisible);
                    setEnable(!enable);
                    toast.success(msg, { autoClose: 2000 });
                    /*
                    setTimeout(() => {
                        navigate('/crew/'+person._id);
                    }, 1000);*/
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al activar/desactivar la empresa:', error);
                });
            } 
            catch (error) {
                console.log(error);
                showBoundary(error);
            }
        }
    }

    const deleteIndustry = async (e) => {
        e.preventDefault();
        let text = "¿Deseas eliminar esta empresa? Esta acción no se puede deshacer";
    
        if(window.confirm(text)){
            
            try {
                const url = `${process.env.REACT_APP_API_LOCATION}/industry/deleteIndustry`;
                await axios.put(url, {
                    _id: industryId
                })
                .then((response) => {
                    let msg = "";
                    msg = "La empresa ha sido eliminada";
                    toast.success(msg, { autoClose: 2000 });
                    
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    showBoundary(error);
                    console.error('Error al eliminar la empresa:', error);
                });
            } 
            catch (error) {
                console.log(error);
                showBoundary(error);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const cancelToken = axios.CancelToken.source();

        console.log("Enviando los datos al servidor");

        let error = validateForm();
        
        console.log(error);
        if(error){
            setFormError(error);
            console.log("Form validation failed, therefore it did not submit");
            return;
        }

        console.log("Enviando los datos al servidor");
        setSubmitted(true); //descomentar esto para que no se pueda enviar el formulario más de una vez

        //data._id = industryId;

        console.log(formData);

        console.log("Form validation passed, therefore it will submit");
        setSubmitted(true);
        
        
        try {            
            const url = `${process.env.REACT_APP_API_LOCATION}/industry/updateIndustry`;
            await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                console.log(response.data.message);
                toast.success("Tus datos han sido actualizados correctamente", { autoClose: 2000 });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch((error) => {
                showBoundary(error);
                console.error('Error al actualizar la empresa:', error);
            });
        } 
        catch (error) {
            console.log(error);
            showBoundary(error);
        }

    } 

    const validateForm = () => {
        
        const { 
            company_name, 
            company_type, 
            company_year, 
            description, 
            country, 
            state, 
            city,
            company_web_page,
            company_facebook_page,
            company_twitter_page,
            company_vimeo_page,
            company_youtube_page,
            company_instagram_page,
            company_imdb_page,
            company_demo
        } = formData;

        let error = null;

        if(!company_name){
            toast.error("El nombre de la empresa es requerido", { autoClose: 2000 });
            return {field_name: 'company_name', message: 'El nombre de la empresa es requerido'};
        }   
        if(company_type==='' || company_type===null || company_type===undefined || company_type==='Seleccionar'){
            toast.error("El tipo de empresa es requerido", { autoClose: 2000 });
            return {field_name: 'company_type', message: 'El tipo de empresa es requerido'};
        }
        console.log(company_year);
        if(company_year==='' || company_year===null || company_year===undefined || company_year==='Seleccionar'){
            toast.error("El año de la empresa es requerido", { autoClose: 2000 });
            return {field_name: 'company_year', message: 'El año de la empresa es requerido'};
        }
        if(!description){
            toast.error("La descripción de la empresa es requerida", { autoClose: 2000 });
            return {field_name: 'company_desc', message: 'La descripción de la empresa es requerida'};
        }
        if(country==='' || country===null || country===undefined || country==='Seleccionar'){
            toast.error("El país de la empresa es requerido", { autoClose: 2000 });
            return {field_name: 'country', message: 'El país de la empresa es requerido'};
        }
        if(state==='' || state===null || state===undefined || state==='Seleccionar'){
            toast.error("El estado de la empresa es requerido", { autoClose: 2000 });
            return {field_name: 'state', message: 'El estado de la empresa es requerido'};
        }
        if(city==='' || city===null || city===undefined || city==='Seleccionar'){
            toast.error("La ciudad de la empresa es requerida", { autoClose: 2000 });
            return {field_name: 'city', message: 'La ciudad de la empresa es requerida'};
        }
        if(company_web_page && company_web_page !== ''){
            const valid = verifyValidURL(company_web_page, "web");
            if(!valid){
                toast.error("La URL de la página web no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'company_web_page',
                    message: 'La URL de la página web no es válida'
                }        
                return error;
            }
        }
        if(company_facebook_page && company_facebook_page !== ''){
            const valid = verifyValidSite(company_facebook_page, "facebook");
            if(!valid){
                toast.error("La URL de facebook no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'company_facebook_page',
                    message: 'La URL de facebook no es válida'
                }        
                return error;
            }
        }
        if(company_twitter_page && company_twitter_page !== ''){
            const valid = verifyValidSite(company_twitter_page, "twitter");
            if(!valid){
                toast.error("La URL de twitter no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'company_twitter_page',
                    message: 'La URL de twitter no es válida'
                }        
                return error;
            }
        }
        if(company_vimeo_page && company_vimeo_page !== ''){
            const valid = verifyValidSite(company_vimeo_page, "vimeo");
            if(!valid){
                toast.error("La URL de vimeo no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'company_vimeo_page',
                    message: 'La URL de vimeo no es válida'
                }        
                return error;
            }
        }
        if(company_youtube_page && company_youtube_page !== ''){
            const valid = verifyValidSite(company_youtube_page, "youtube");
            if(!valid){
                toast.error("La URL de youtube no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'company_youtube_page',
                    message: 'La URL de youtube no es válida'
                }        
                return error;
            }
        }
        if(company_instagram_page && company_instagram_page !== ''){
            const valid = verifyValidSite(company_instagram_page, "instagram");
            if(!valid){
                toast.error("La URL de instagram no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'company_instagram_page',
                    message: 'La URL de instagram no es válida'
                }        
                return error;
            }
        }
        if(company_imdb_page && company_imdb_page !== ''){
            const valid = verifyValidSite(company_imdb_page, "imdb");
            if(!valid){
                toast.error("La URL de imdb no es válida", { autoClose: 2000 });
                error = {
                    field_name: 'company_imdb_page',
                    message: 'La URL de imdb no es válida'
                }        
                return error;
            }
        }
        if(company_demo && company_demo !== ''){
            const videoURL = verifyVideoURL(company_demo);
            if(!videoURL){
                toast.error("La URL del demo no es válida, revisa que sea una URL de youtube o vimeo", { autoClose: 2000 });
                error = {
                    field_name: 'company_demo',
                    message: 'La URL del demo no es válida, revisa que sea una URL de youtube o vimeo'
                }        
                toast.error("La URL del demo no es válida, revisa que sea una URL de youtube o vimeo", { autoClose: 2000 });
                return error;
            }
        }

        
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if(industryId){
            getYears();

            axios.get(`${process.env.REACT_APP_API_LOCATION}/industry/getIndustryMeta/`+industryId,{
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                console.log(response.data);
                
                setInvisible(response.data.invisible);
                setFormData({
                    _id: response.data._id,
                    creator: response.data.creator,
                    company_name: response.data.name,
                    company_type: response.data.company_type,
                    company_year: response.data.company_year,
                    description: response.data.description,
                    country: response.data.country,
                    state: response.data.state,
                    city: response.data.city,
                    company_web_page: response.data.company_web_page,
                    company_facebook_page: response.data.company_facebook_page,
                    company_twitter_page: response.data.company_twitter_page,
                    company_vimeo_page: response.data.company_vimeo_page,
                    company_youtube_page: response.data.company_youtube_page,
                    company_instagram_page: response.data.company_instagram_page,
                    company_imdb_page: response.data.company_imdb_page,
                    company_demo: response.data.company_demo,
                    gallery: response.data.gallery,
                    cover: response.data.cover,
                    logo: response.data.logo,
                });
                setInvisible(response.data.invisible);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener la empresa:', error);
                }
            });

            axios.post(`${process.env.REACT_APP_API_LOCATION}/location/getCountries`,{
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                setCountries(response.data);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los países:', error);
                }
            });

            function getYears(){
                let years = [];
                for(let i=2025; i>=1970; i--){
                    years.push(i);
                }
                setYears(years);
            }
        }

        return () => {
            cancelToken.cancel();
        }

        

    }, [showBoundary, industryId]);


  return (
    <>
    
    <Header/>
    <div className="container">
        <div className="row">
            <div className="col-lg-3 rounded background-verylightgray p-3">
                <div className="sticky-top">
                    <div className="spacer10"></div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className={"col menu-link linkable"} onClick={gotoIndustry}><span className="mdi mdi-eye"></span>&nbsp;Ver empresa</div>
                    </div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className={"col menu-link linkable"} onClick={gotoProfile}><span className="mdi mdi-account"></span>&nbsp;Editar perfil</div>
                    </div>
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col text-14">
                            <hr/>
                        </div>
                    </div>
                    <div className="spacer20"></div>
                    <div className="row">
                        <div className="col text-14">
                            <a href="/ProjectList" className="text-black" id=""><span className="mdi mdi-briefcase"></span> Producciones/muestras</a>
                        </div>
                    </div>       
                    <div className="spacer10"></div>
                    <div className="row">
                        <div className="col text-14">
                            <a href="/IndustyList" className="text-black" id=""><span className="mdi mdi-domain"></span> Empresas/industrias</a>
                        </div>
                    </div>
                    <div className="spacer40"></div>
                    <div><hr/></div>
                </div>
            </div>
            <div className="col-lg-9">
                <div className="container">
                <form onSubmit={handleSubmit}>

                <div className='row spaced'>
                    <div className="col-lg-3">
                        <FormButton submit={false} onClick={gotoIndustry} className="btn btn-options">Ver empresa</FormButton>
                    </div>
                </div>    
                
                <div className='row spaced'>
                    <div className="col-lg-12">
                        <IndustryCover industry={formData}/>
                    </div>
                </div>

                <div className="spacer40">
                    <div className="col-lg-2 col-md-4 col-12 d-none d-lg-flex">
                        {(!formData.logo || !formData.logo.url) &&
                            <img src="/no-image-logo.png" className="rounded-circle borde-img logo-over" alt="No logo"/>
                        }
                        {formData.logo && formData.logo.url &&
                            <img src={formData.logo.url} border="0" className="rounded-circle borde-img logo-over" alt="Actual Logo"/>
                        }
                        <button onClick={gotoUploadWidget} className="text-white icon-over-logo no-border" id="openMediaGallery">
                            <span className="mdi mdi-camera"></span>
                        </button>
                    </div>
                </div>
                <div className="spacer40"></div>
                <div className="spacer40"></div>
                <div className="row spaced">
                    <div className="col-lg-12 col-sm-12">
                        <h2 className="titles">Datos de la empresa</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-12 margin-10">
                    {formData && formData.invisible &&
                        <FormSwitch
                            onChange={switchInvisible}
                            disabled={submitted ? "disabled":"" }
                            label="Activar empresa"
                            status={enable}
                            isOn={enable}
                        />
                    }
                    {formData && !formData.invisible &&
                        <FormSwitch
                            onChange={switchInvisible}
                            disabled={submitted ? "disabled":"" }
                            label="Desactivar empresa"
                            status={!enable}
                            isOn={!enable}
                        /> 
                    }
                    </div>                        
                </div>
                <div className='spacer40'></div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 form-group">
                        <FormLabel htmlFor="title" required={true}>Nombre de la empresa <span className="text-purple">(Requerido)</span></FormLabel>
                        <FormTextInput
                            name="company_name"
                            onChange={handleChange}
                            className="form-control agreements"
                            disabled={submitted ? "disabled":"" }
                            value={formData && formData.company_name ? formData.company_name : ""}
                        />
                        {formError && formError.field_name === 'company_name' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12 form-group">
                        <FormLabel htmlFor="type" required={true}>Tipo</FormLabel>
                        <FormSelect
                            name="company_type"
                            data="industryType"
                            onChange={handleChange}
                            className="form-control agreements"
                            disabled={submitted ? "disabled":"" }
                            selected={formData && formData.company_type ? formData.company_type : ""}
                        />
                        {formError && formError.field_name === 'company_type' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 form-group">
                        <FormLabel htmlFor="year" required={true}>Año</FormLabel>
                        <select 
                            name='company_year' 
                            className="form-control agreements" 
                            onChange={handleChange} 
                            disabled={submitted ? "disabled":"" }
                            value={formData && formData.company_year ? formData.company_year : ""}
                        >
                            {years.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                        {formError && formError.field_name === 'company_year' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                    </div>
                    
                </div>
                <div className='row spaced'>    
                    <div className="col-lg-12">
                        <FormLabel htmlFor="title" required={true}>Descripción / Sinopsis: </FormLabel>
                        <FormTextareaInput
                            name="description"
                            onChange={handleChange} 
                            className="form-control agreements"
                            value={formData && formData.description ? formData.description:''}
                            disabled={submitted ? "disabled":"" }
                        />
                        {formError && formError.field_name === 'company_desc' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                    </div>
                </div>
                <div className="spacer10"></div>
                <div className="row spaced">
                    <div className="col">
                        <strong>Actual: {formData.country} / {formData.state} / {formData.city}</strong>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-md-4">
                        <FormLabel htmlFor="country" required={true}>País</FormLabel>                            
                        <select 
                            name="country" 
                            className="form-control agreements" 
                            onChange={(e) => updateStates(e)}
                            disabled={submitted ? "disabled":"" }
                        >
                            <option value="cualquier"> Cualquier país </option>
                            {countries.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                        {formError && formError.field_name === 'country' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}     
                    </div>
                    <div className="col-md-4">
                        <FormLabel htmlFor="state" required={true}>Estado</FormLabel>
                        
                        <select 
                            name="state" 
                            className="form-control agreements"
                            onChange={(e) => handleState(e)}
                            disabled={submitted ? "disabled":"" }
                        >
                            <option value="cualquier"> Cualquier estado </option>
                            {states.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                        {formError && formError.field_name === 'state' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                    </div>
                    <div className="col-md-4">
                        <FormLabel htmlFor="city" required={true}>Ciudad</FormLabel>
                        
                        <select 
                            name="city" 
                            className="form-control agreements"
                            onChange={(e) => handleCity(e)}
                            disabled={submitted ? "disabled":"" }
                        >
                            <option value="cualquier"> Cualquier ciudad </option>
                            {cities.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                        {formError && formError.field_name === 'city' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                    </div>
                </div>
                <div className="row spaced">
                    <div className="col">
                        <h2 className="titles">En internet</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 form-group">
                        <FormLabel htmlFor="web" required={false}>Página Web</FormLabel>
                        <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><span className="mdi mdi-web"></span></div>
                        </div>
                        <FormTextInput 
                            name="company_web_page"
                            onChange={handleChange}
                            placeholder="Ej: www.cinekomuna.com"
                            value={formData && formData.company_web_page ? formData.company_web_page : ""}
                            maxLength="120"
                            disabled={submitted ? "disabled":"" }
                        />
                        {formError && formError.field_name === 'company_web_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 form-group">
                        <FormLabel htmlFor="demo" required={false}>Demo reel</FormLabel>
                        <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><span className="mdi mdi-play"></span></div>
                        </div>
                        <FormTextInput
                            name="company_demo"
                            onChange={handleChange}
                            placeholder="URL de un video de youtube o vimeo"
                            value={formData && formData.company_demo ? formData.company_demo : ""}
                            maxLength="150"
                            disabled={submitted ? "disabled":"" }
                        />
                        {formError && formError.field_name === 'company_demo' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 form-group">
                        <FormLabel htmlFor="facebook" required={false}>Facebook</FormLabel>
                        <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><span className="mdi mdi-facebook"></span></div>
                        </div>
                        <FormTextInput 
                            name="company_facebook_page" 
                            onChange={handleChange} 
                            placeholder="Ej. www.facebook.com/cinekomuna" 
                            value={formData && formData.company_facebook_page ? formData.company_facebook_page : ""}
                            maxLength="120"
                            disabled={submitted ? "disabled":"" }
                        />
                        {formError && formError.field_name === 'company_facebook_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 form-group">
                        <FormLabel htmlFor="twitter" required={false}>Twitter</FormLabel>
                        <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><span className="mdi mdi-twitter"></span></div>
                        </div>
                        <FormTextInput
                            name="company_twitter_page"
                            onChange={handleChange}
                            placeholder="Ej. www.twitter.com/cinekomuna"
                            value={formData && formData.company_twitter_page ? formData.company_twitter_page : ""}
                            maxLength="120"
                            disabled={submitted ? "disabled":"" }
                        />
                        {formError && formError.field_name === 'company_twitter_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 form-group">
                        <FormLabel htmlFor="vimeo" required={false}>Vimeo</FormLabel>
                        <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><span className="mdi mdi-vimeo"></span></div>
                        </div>
                        <FormTextInput
                            name="company_vimeo_page"
                            onChange={handleChange}
                            placeholder="Ej. www.vimeo.com/cinekomuna"
                            value={formData && formData.company_vimeo_page ? formData.company_vimeo_page : ""}
                            maxLength="120"
                            disabled={submitted ? "disabled":"" }
                        />
                        {formError && formError.field_name === 'company_vimeo_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}                
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 form-group">
                        <FormLabel htmlFor="youtube" required={false}>Youtube</FormLabel>
                        <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><span className="mdi mdi-youtube"></span></div>
                        </div>
                        <FormTextInput
                            name="company_youtube_page"
                            onChange={handleChange}
                            placeholder="Ej. www.youtube.com/cinekomuna"
                            value={formData && formData.company_youtube_page ? formData.company_youtube_page : ""}
                            maxLength="120"
                            disabled={submitted ? "disabled":"" }
                        />
                        {formError && formError.field_name === 'company_youtube_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 form-group">
                        <FormLabel htmlFor="instagram" required={false}>Instagram</FormLabel>
                        <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><span className="mdi mdi-instagram"></span></div>
                        </div>
                        <FormTextInput
                            name="company_instagram_page"
                            onChange={handleChange}
                            placeholder="Ej. www.instagram.com/cinekomuna"
                            value={formData && formData.company_instagram_page ? formData.company_instagram_page : ""}
                            maxLength="120"
                            disabled={submitted ? "disabled":"" }
                        />
                        {formError && formError.field_name === 'company_instagram_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 form-group">
                        <FormLabel htmlFor="imdb" required={false}>IMDB</FormLabel>
                        <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><img src="/imdb.png" className="icon-size" alt="imdb icon"/></div>
                        </div>
                        <FormTextInput
                            name="company_imdb_page"
                            onChange={handleChange}
                            placeholder="Ej. www.imdb.com/cinekomuna"
                            value={formData && formData.company_imdb_page ? formData.company_imdb_page : ""}
                            maxLength="120"
                            disabled={submitted ? "disabled":"" }
                        />
                        {formError && formError.field_name === 'company_imdb_page' ? <FormValidationMessage>{formError.message}</FormValidationMessage> : null}
                        </div>
                    </div>
                </div>
                
                
                <div className="row spaced">
                    <div className="col-lg-12 mx-auto">
                        <h2>Galería de imágenes</h2>
                    </div>
                </div>
                <div className="spacer10"></div>
                
                <div className="spacer10"></div>
                <div className="row">
                    <div className="col-lg-12"> 
                        <IndustryGallery industry={formData}/>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-lg-12"> 
                        <FormButton submit={false} onClick={gotoUploadGallery} className="btn btn-purple" disabled={submitted ? "disabled":"" }>Administrar galería de proyecto <span className="mdi mdi-plus"></span></FormButton>
                    </div>
                </div>
                <div className="spacer40"></div>

                
                <div className="row">
                    <div className="col">
                        <FormButton submit={true} className="btn btn-purple" disabled={submitted ? "disabled":"" }>Guardar</FormButton>
                    </div>
                </div>            
                {false &&
                <>
                <div className="spacer40"></div>
                <div className="row">
                    <div className="col-12 danger-zone">                    
                        <span 
                            className={!toggleDangerZone ? 'mdi mdi-arrow-right cursor' : 'mdi mdi-arrow-down cursor'} 
                            data-toggle="collapse" 
                            data-target="#collapseExample" 
                            aria-expanded="false" 
                            aria-controls="collapseExample" 
                            onClick={handleDangerZoneToggle}
                        >&nbsp;Mostar zona de riesgo</span>
                        <div className="collapse uppercase" id="collapseExample">
                            <div className="spacer10"></div>
                            <p className="text-danger">¡Cuidado! Estas acciones no se pueden deshacer</p>
                            <div className="spacer10"></div>
                            <FormButton submit={false} onClick={deleteIndustry} className="btn btn-purple" disabled={submitted ? "disabled":"" }> 
                                Eliminar empresa
                            </FormButton>
                        </div>
                    </div>
                </div>
                </>
                }
                        



                <div className="spacer40"></div>
            
            </form>
            </div>
            </div>
        </div>
    </div>


    

    

    <Footer/>
    </>
  )
}

export default IndustryForm