import React from "react";

function ModalMoreRoles(props){

    const {person} = props;

    return(
        <div className="modal fade" id="more-roles" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="image-container-100">
                                        <img src={person.photo}  className="image" alt="Profile"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col"><h1>{person.fullname}</h1></div>
                            </div>
                            <div className="row">
                                <div className="col uppercase">{person.fullRole}</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="row">
                            <div className="col-lg-4"><button type="button" className="btn btn-purple btn-rounded" data-dismiss="modal">Cerrar</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalMoreRoles;