import {React, useEffect, useCallback, useState} from 'react';
import axios from 'axios';

function Banners (props){

    const [banners, setBanners] = useState([]);

    const getBanners = useCallback(async () => {
        const url = `${process.env.REACT_APP_API_LOCATION}/banner/getBanners`;    
        const response = await axios.get(url);
        setBanners(response.data);
    }, []);

    useEffect(() => {        
        getBanners();
    }, [getBanners]);

    return(
        <>        
        <div id="carouselExampleSlidesOnly" 
            className="carousel slide" 
            data-wrap="true" 
            data-pause="false" 
            data-ride="carousel" 
            data-interval="3000"
        >
            <div className="carousel-inner">
                {banners.map((banner, index) => (                    
                    <div className={"carousel-item "+banner.active} key={index}>
                        <a href={banner.link} target={banner.target}><img className="d-block w-100" src={banner.img} alt="Sponsored banner"/></a>
                    </div>
                ))}
            </div>
        </div>
        </>
    )
}

export default Banners;