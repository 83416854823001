import React from 'react';
import { useLocation } from "react-router-dom"
import ProjectHighlighted from './components/home/ProjectHighlighted';
import Header from './components/common/Header';

const ErrorPage = () =>{

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const code = params.get("code");
    const message = params.get("message");

    return(
        <div>
            <Header/>
            <div className="spacer40"></div>
            <div className="container text-center">
                
                <>
                
                <h1>Bueno, esto es embarazoso</h1>
                <h2>Ha ocurrido el siguiente problema</h2>
                <h3>{code} - {message}</h3>
                <img src="/desert.jpg" alt="Desierto" className="img-fluid"/>
                <div className="spacer20"></div>
                <h2>¿Qué tal si volvemos a la página principal?</h2>
                <h2>También puede interesarte visitar alguna de las siguientes publicaciones</h2>
                <div className="spacer40"></div>
                <ProjectHighlighted/>
                </>
                
                <div className="spacer40"></div>
            </div>
            
        </div>
    )
}

export default ErrorPage;