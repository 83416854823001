import React from "react";
import IndustryDemo from "./IndustryDemo";
import IndustryGallery from "./IndustryGallery";
import IndustrySidebar from "./IndustrySidebar";
//import IndustryProjects from "./IndustryProjects";
//import ProjectCrew from "./ProjectCrew";
//import ProjectCast from "./ProjectCast";
//import ProjectCompany from "./ProjectCompany";
//import Portlets from "./Portlets";


const IndustryBody = (props) => {

    const industry = props.industry;

    if(!industry){
        return <div>Cargando...</div>;
    }

    return(
        <div>
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <hr/>
                    </div>
                </div>
            </div>  
            <div className="spacer40"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-sm-12">
                        <IndustryDemo industry={industry}/>
                        <IndustryGallery industry={industry}/>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <IndustrySidebar industry={industry}/>
                    </div>
                </div>
                <div className="spacer40"></div>
                {/*<IndustryProjects/>*/}
                <div className="spacer80"></div>
            </div>

            
            {/*
            <ProjectCrew/>
            <ProjectCast/>
            <ProjectCompany/>
            <Portlets/>
            */}

        </div>
    )

}

export default IndustryBody;


