import {React, useState}  from 'react';

import Header from '../common/Header';
import Footer from '../common/Footer';
import EditPersonalInfo from '../user/EditPersonalInfo';
import EditCrew from '../crew/edition/EditCrew';
import EditCast from '../cast/edition/EditCast';
import SideBar from './SideBar';
import './styles.scss';

function ProfileEdit(props) {
    const [menu, setMenu] = useState(1);

    console.log("Menu: " + menu);

    function setMenuOption(value) {
        console.log("Menu option: " + value);        
        setMenu(value);
    }
            
    return (
        <div>
            <Header/>
                <>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 rounded background-verylightgray p-3">
                            <SideBar onSubmitCallback={setMenuOption}/>
                        </div>
                        <div className="col-lg-9">
                            {(menu===1) &&
                            <EditPersonalInfo/>
                            }
                            {(menu===2) &&
                            <EditCrew/>
                            }
                            {(menu===3) &&
                            <EditCast/>
                            }
                        </div>
                    </div>
                </div>
                </>
               
            <Footer/>
        </div>
    )
}

export default ProfileEdit;