import {React, useState}  from 'react';

import Header from '../common/Header';
import Footer from '../common/Footer';
import Banners from '../common/Banners';
import ErrorHandler from '../common/ErrorHandler';
import SearchButtons from '../common/SearchButtons';
import CrewFilters from './sections/CrewFilters';
import CrewResults from './sections/CrewResults';

function Crew(props) {
    const code = 200;
    const [query, setQuery] = useState({});

    function setFilters(value) {
        const newQuery = {...value}
        setQuery(newQuery);
    }

    return (
        <div>
            <Header/>
            <Banners/>
            <SearchButtons activeButton="crew"/>
            {code===200 &&
                <>
                <div className="container">
                    <div className="spacer40"></div>
                    <div className="row m-0">
                        <div className="col-lg-3 col-md-4 col-12 p-0">
                            <CrewFilters onSubmitCallback={setFilters}/>
                        </div>
                        <div className="col-lg-9 col-md-8 col-12 ">                            
                            <CrewResults searchQuery={query}/>
                        </div>
                    </div>
                </div>
                </>
                }
            {code!==200 &&
                <ErrorHandler code={code} title="Ruta o parámetro"/>
            }
            <div className="spacer80"></div>
            <Footer/>
        </div>
    )
}

export default Crew;