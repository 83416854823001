import {React, useState} from "react";
import './styles.scss';


function SideBar(props) {

    const [menu, setMenu] = useState(1);

    const menuOption = (param) => () => {
        console.log("En sidebar menú option: " + param);
        setMenu(param);
        props.onSubmitCallback(param);
    }
    
    return (
        <div className="sticky-top">
            <div className="spacer10"></div>
            <div className="row">
                <div className="col menu-link"><strong>Editar</strong></div>
            </div>
            <div className="spacer10"></div>
            <div className="row">
                <div className={menu===1?"col menu-link active":"col menu-link linkable"} onClick={menuOption(1)}><span className="mdi mdi-account"></span>&nbsp;Datos personales</div>
            </div>
            <div className="spacer10"></div>
            <div className="row">
                <div className={menu===2?"col menu-link active":"col menu-link linkable"} onClick={menuOption(2)}><span className="mdi mdi-movie-open-edit"></span>&nbsp;Pefil de Crew</div>
            </div>
            <div className="spacer10"></div>
            <div className="row">
                <div className={menu===3?"col menu-link active":"col menu-link linkable"} onClick={menuOption(3)}><span className="mdi mdi-drama-masks"></span>&nbsp;Pefil de Cast</div>
            </div>
            <div className="spacer10"></div>
            <div className="row">
                <div className="col text-14">
                    <hr/>
                </div>
            </div>
            <div className="spacer20"></div>
            <div className="row">
                <div className="col text-14">
                    <a href="/ProjectList" className="text-black" id=""><span className="mdi mdi-briefcase"></span> Producciones/muestras</a>
                </div>
            </div>       
            
            <div className="spacer10"></div>
            <div className="row">
                <div className="col text-14">
                    <a href="/IndustyList" className="text-black" id=""><span className="mdi mdi-domain"></span> Empresas/industrias</a>
                </div>
            </div>
            
            
            <div className="spacer40"></div>
            <div><hr/></div>
          
          
        </div>
    )
}

export default SideBar;