import {React } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/dist/photoswipe.css';

export default function ProjectGallery(props) {

  
  const industry = props.industry;

  const images = industry.gallery;
  
  const PREFIX = process.env.REACT_APP_PREFIX_FOR_IMAGES;


  const lightbox = new PhotoSwipeLightbox({
    gallery: "#my-gallery",
    children: "a",
    
    pswpModule: () => import("photoswipe")
  });
  
  lightbox.init();

  if (!images) {
    return <div>&nbsp;</div>;
  }

  

  return (    
    <>
    
    <div className="spacer40"></div>
    {images.length > 0 &&
    <div className="row">
      <div className="col ">
          <strong className="uppercase">Galería</strong>                        
          <hr/>
      </div>
  </div>
}
<div className="spacer20"></div>
    
    <div className="pswp-gallery" id="my-gallery">

      {images.map((image, index) => (
        <a
          href={PREFIX + industry.creator + "/"+ image.url}
          data-pswp-width={image.width}
          data-pswp-height={image.height}
          target="_blank"
          rel='noreferrer'
          key={index}
        >
          <img
            src={PREFIX + industry.creator + "/"+ image.url}
            alt=""
            className="gallery-separator thumbnail"
          />
        </a>
        ))}
        
      </div>
      </>
  );
}