import {React} from 'react';

function CastDemo(props) {

    const {person} = props;

    if(!person){
        return <div>Cargando ...</div>
    } 
    
    return (
        <div>

            {person.demo &&
            <>
                <div className="row">
                    <div className="col-md-12 text-left">
                        <strong className="uppercase">DEMO REEL</strong>
                        <hr className="hr-crew"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <hr/>
                    </div>
                </div>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src={person.demo} title={person._id} className="embed-responsive-item"></iframe>
                </div>
            </>
            }
        </div>
    )
}

export default CastDemo;