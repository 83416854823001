export const castProfessions = [
    "Actor / Actriz",
    "Locutor(a) / doblaje",
    "Extra",
    "Modelo",
    "Bailarín(a)",
    "Cantante"
  ];

  export const castAges = [
    "Recién nacido o que aparente pocos meses de nacido",
    "3 meses a 1 año",
    "1 a 3 años",
    "3 a 5 años",
    "5 a 10 años",
    "10 a 15 años",
    "15 a 20 años",
    "20 a 30 años",
    "30 a 40 años",
    "40 a 50 años",
    "50 a 60 años",
    "60 a 70 años",
    "Más de 70 años"
  ];


  export const castHeights = [
    "1.40 metros o menos",
    "1.40 metros – 1.44 metros",
    "1.45 metros – 1.49 metros",
    "1.50 metros – 1.54 metros",
    "1.55 metros – 1.59 metros",
    "1.60 metros – 1.64 metros",
    "1.65 metros – 1.69 metros",
    "1.70 metros – 1.74 metros",
    "1.75 metros – 1.79 metros",
    "1.80 metros – 1.84 metros",
    "1.85 metros – 1.89 metros",
    "1.90 metros o mas"
  ];

  export const castGender = [
    "Masculino",
    "Femenino",
    "Otrxs"
  ]

  export const castPhysical = [
    "Delgada",
    "Esbelta",
    "Estándar",
    "Deportiva/Muscular",
    "Corpulenta",
    "Robusta"
  ];
  
  
  export const castEthnics = [
    "Caucasica",
    "Latina",
    "Indigena",
    "Mulata",
    "Afro-descendiente",
    "Asiatica",
    "Arabe – Oriente Medio",
    "Sin definir"
  ];
  
  export const castEyes = [
    "Azules",
    "Verdes",
    "Marrones",
    "Negros",
    "Castaños",
    "Otro"
  ];
  
  export const castHair = [
    "Rubio",
    "Castaño",
    "Pelirojo",
    "Negro",
    "Gris/ canoso",
    "Blanco",
    "Otro"
  ];
  
  export const castHairType = [
    "Calvo",
    "Afro",
    "Rizado",
    "Lacio",
    "Ondulado",
    "Rastas"
  ];
  
  export const castLanguages = [
    "Lengua y/o dialecto indigena",
    "Inglés",
    "Francés",
    "Alemán",
    "Ruso",
    "Portugués",
    "Mandarín",
    "Árabe",
    "Otro"
  ];