import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import {InputGroup, FormControl, Button} from "react-bootstrap";
import Header from "../common/Header";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { UserContext } from '../../context';

const Register = () => {
    
    const [state, setState] = useContext(UserContext);
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');    
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        let response;
        e.preventDefault();
        //validate that both passwords are the same
        if(password !== confirmPassword){
            toast.error("Las contraseñas no coinciden", { autoClose: 2000 });
                setTimeout(() => {
            }, 1000);
            
            return;
        }

        //validate that the email is not empty
        if(email === ""){
            toast.error("El correo no puede estar vacío", { autoClose: 2000 });
                setTimeout(() => {
            }, 1000);
            return;
        }

        //validate that the name is not empty
        if(name === ""){
            toast.error("El nombre no puede estar vacío", { autoClose: 2000 });
                setTimeout(() => {
            }, 1000);
            return;
        }

        //validate that the lastname is not empty
        if(lastname === ""){
            toast.error("El apellido no puede estar vacío", { autoClose: 2000 });
                setTimeout(() => {
            }, 1000);
            return;
        }

        //validate that the password is not empty
        if(password === ""){
            toast.error("La contraseña no puede estar vacía", { autoClose: 2000 });
                setTimeout(() => {
            }, 1000);
            return;
        }

        //validate that the confirmation password is not empty
        if(confirmPassword === ""){
            toast.error("La confirmación de la contraseña no puede estar vacía", { autoClose: 2000 });
                setTimeout(() => {
            }, 1000);
            return;
        }

        //validate that the password is at least 6 characters long
        if(password.length < 6){
            toast.error("La contraseña debe tener al menos 6 caracteres", { autoClose: 2000 });
                setTimeout(() => {
            }, 1000);
            return;
        }

        //validate that the email is valid
        if(!email.includes("@") || !email.includes(".")){
            toast.error("El correo no es válido", { autoClose: 2000 });
                setTimeout(() => {
            }, 1000);
            return;
        }

        //send the data to the server
        const {data:signupData} = await axios.post(`${process.env.REACT_APP_API_LOCATION}/auth/signup`, {
            email,
            password,
            name,
            lastname            
        });
        response = signupData;
        console.log(response);
        if(response.errors.length){
            console.log(error);
            return setError(response.errors[0].msg);
        }
        console.log(state);
        //This will trigger the fetchUser function in the context
        setState({
            data: {
                _id: response.data.user._id,
                email: response.data.user.email,
                name: response.data.user.name,
                lastname: response.data.user.lastname,
                fullname: response.data.user.fullname,
                photo: response.data.user.photo,
                role: response.data.user.role,
                isCrew: response.data.user.isCrew,
                isCast: response.data.user.isCast,
                stripeCustomerId: response.data.user.stripeCustomerId
            },
            error: null,
            loading: false
        });

        localStorage.setItem('token', response.data.token);
        axios.defaults.headers.common['authorization'] = `Bearer ${response.data.token}`;
        navigate('/');

    };



    return (
        <div className="register">
            <Header/>
            <div className="m-height">
                <div className="container text-center">
                    <h1 className="h1-title">¡Te damos la bienvenida a Cinekomuna!</h1>
                    <br/>
                    <p className="text-description">
                    Si ya tienes una cuenta <Link to="/login" className="login">Inicia sesión</Link>
                    </p>
                </div>
                <div className="col-md-4 mx-auto">
                    <div className="spacer20"></div>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text style={{"backgroundColor":"#fff", "border":"0"}}>Correo:</InputGroup.Text>
                        <FormControl 
                            type="email" 
                            value={email} 
                            onChange={(e)=> setEmail(e.target.value) }
                        />
                    </InputGroup>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text style={{"backgroundColor":"#fff", "border":"0"}}>Nombre:</InputGroup.Text>
                        <FormControl 
                            type="text" 
                            value={name} 
                            onChange={(e)=> setName(e.target.value) }
                        />
                    </InputGroup>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text style={{"backgroundColor":"#fff", "border":"0"}}>Apellido:</InputGroup.Text>
                        <FormControl 
                            type="text" 
                            value={lastname} 
                            onChange={(e)=> setLastname(e.target.value) }
                        />
                    </InputGroup>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text style={{"backgroundColor":"#fff", "border":"0"}}>Contraseña:</InputGroup.Text>
                        <FormControl 
                            type="password" 
                            value={password} 
                            onChange={(e)=> setPassword(e.target.value) }
                        />
                    </InputGroup>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text style={{"backgroundColor":"#fff", "border":"0"}}>Confirmar contraseña:</InputGroup.Text>
                        <FormControl 
                            type="password" 
                            value={confirmPassword} 
                            onChange={(e)=> setConfirmPassword(e.target.value) }
                        />
                    </InputGroup>
                    <Button className="btn-purple" onClick={handleSubmit}>Registrar</Button>
                </div>
            </div>
        </div>
    )
};

export default Register;