import {React, useState, useEffect} from "react";
import { useErrorBoundary } from "react-error-boundary";
import axios from "axios";
import './styles.scss';

function CrewProjects(props) {
    const { showBoundary } = useErrorBoundary();
    const [projects, setProjects] = useState([]);
    const [samples, setSamples] = useState([]);
    const {personId} = props;

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if(personId){
            axios.get(`${process.env.REACT_APP_API_LOCATION}/project/getUserProjects/${personId}/P`, {
                cancelToken: cancelToken.token,
            })
            .then((response) => {            
                setProjects(response.data);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los datos del proyecto:', error);
                }
            });

            axios.get(`${process.env.REACT_APP_API_LOCATION}/project/getUserProjects/${personId}/M`, {
                cancelToken: cancelToken.token,
            })
            .then((response) => {            
                setSamples(response.data);
            })
            .catch((error) => {
                if(axios.isCancel(error)){
                    console.log("Request cancelled");
                }
                else{
                    showBoundary(error);
                    console.error('Error al obtener los datos de las muestras:', error);
                }
            });
        }
        
        return () => {
            cancelToken.cancel();
        }
    }, [personId, showBoundary]);

    if(!projects){
        return <div>Cargando...</div>;
    }

    return (
        <div>
            {projects && projects.length > 0 &&
            <>
            <div className="spacer40"></div>
            <div className="row">
                <div className="col ">
                    <strong className="uppercase">Proyectos</strong>
                    <hr/>
                </div>
            </div>
            <div className="spacer10"></div>
            <div className="row">
            {projects.map((project, index) => (
                <div className="col-lg-4 col-sm-6" key={index}>
                    <div className="card d-flex w-100 mt-2 bordered">
                        <a href={"/project/"+project._id} className="card-img-top"><img className="card-img-top w-100" src={project.cover.url} alt="Project"/></a>
                        
                        <div className="card-title title-style">
                            <a href={"/project/"+project._id} className="text-black">{project.project_title}</a><br/>
                            <p><strong>{project.year}</strong></p>                            
                        </div>
                                                
                        <div className="card-body body-style">                            
                            <p className="card-text"><strong>{project.role}</strong></p>
                            <p className="card-text">{project.description}</p>
                        </div>

                            
                        <div className="card-footer footer-style">
                            <div className="row">
                                <div className="col">
                                    <span className="mdi mdi-thumb-up"></span>
                                    <small>&nbsp;{project.likes}</small>
                                </div>
                                <div className="col text-right">
                                    <span className="mdi mdi-eye"></span>
                                    <small>&nbsp;{project.views}</small>
                                </div>
                            </div>
                        </div>                     
                    </div>      
                </div>
            ))}
            </div>
            <div className="spacer40"></div>
            </>
            }

            {samples && samples.length > 0 &&
            <>
            <div className="spacer40"></div>
            <div className="row">
                <div className="col ">
                    <strong className="uppercase">Muestras de trabajo</strong>
                    <hr/>
                </div>
            </div>
            <div className="row">
            {samples.map((sample, index) => (
                <div className="col-lg-4 col-sm-6" key={index}>
                <div className="card d-flex w-100 mt-2 bordered">
                    <a href={"/project/"+sample._id} className="card-img-top"><img className="card-img-top w-100" src={sample.cover.url} alt="Project"/></a>
                    
                    <div className="card-title title-style">
                        <a href={"/project/"+sample._id} className="text-black">{sample.project_title}</a><br/>
                        <p><strong>{sample.year}</strong></p>                            
                    </div>
                                            
                    <div className="card-body body-style">                            
                        <p className="card-text"><strong>{sample.role}</strong></p>
                        <p className="card-text">{sample.description}</p>
                    </div>

                        
                    <div className="card-footer footer-style">
                        <div className="row">
                            <div className="col">
                                <span className="mdi mdi-thumb-up"></span>
                                <small>&nbsp;{sample.likes}</small>
                            </div>
                            <div className="col text-right">
                                <span className="mdi mdi-eye"></span>
                                <small>&nbsp;{sample.views}</small>
                            </div>
                        </div>
                    </div>                     
                </div>      
            </div>
            ))}
            </div>
            <div className="spacer40"></div>
            </>
            }

        </div>

        
    )
}

export default CrewProjects;