import React from 'react'

function FormValidationMessage({children}) {
  return (
    <div className='error-message'>
        {children}
    </div>
  )
}

export default FormValidationMessage