export const projectFamilies = [
    {name:"Producciones",value:"P"},
    {name:"Muestras de trabajo",value:"M"},
];

export const projectCategories = [
    "Largometraje",
    "Cortometraje",
    "Comercial",
    "Fashion film",
    "Serie/programa televisión",
    "Videoclip"
  ];

  export const projectStatus = [
    "Desarrollo",
    "Pre-producción",
    "Producción",
    "Post-producción",
    "Distribución/exhibición",
    "Terminado"
  ];

  export const projectGenres = [
    "Animación",
    "Comedia",
    "Documental",
    "Drama",
    "Ensayo",
    "Experimental",
    "Fantástico",
    "Híbrido",
    "Horror/gore",
    "Infantil",
    "LGTB",
    "Musical",
    "Sci-fi",
    "Suspenso/ misterio"
  ]

  export const projectYears = [
    "2025",
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1991",
    "1990",
    "1989",
    "1988",
    "1987",
    "1986",
    "1985",
    "1984",
    "1983",
    "1982",
    "1981",
    "1980",
    "1979",
    "1978",
    "1977",
    "1976",
    "1975",
    "1974",
    "1973",
    "1972",
    "1971",    
  ];
