import {React, useState, useEffect} from 'react';

function ProjectDemo(props) {

    const {project} = props;
    const [demo, setDemo] = useState();

    useEffect(() => {
        let transformedDemo ="";
        if(project.project_demo){
            transformedDemo = project.project_demo;
            /*Transforma la url del demo para que se visualice en un iframe */
            if(transformedDemo.indexOf("youtube") > -1){
                transformedDemo = transformedDemo.replace("watch?v=", "embed/");
            }
            else if(transformedDemo.indexOf("vimeo") > -1){
                transformedDemo = transformedDemo.replace("//vimeo.com/","//player.vimeo.com/video/");
            } 

            /**Remover todo lo que esté después del primer & */
            if(transformedDemo.indexOf("&") > -1){
                transformedDemo = transformedDemo.substring(0, transformedDemo.indexOf("&"));
            }

            setDemo(transformedDemo);
        }
    }, [project]);

    if(!project){
        return <div>Cargando ...</div>
    } 
    
    return (
        <div>

            {demo &&
            <>
                <div className="row">
                    <div className="col-md-12 text-left">
                        <strong className="uppercase">DEMO REEL</strong>
                        <hr className="hr-crew"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <hr/>
                    </div>
                </div>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe src={demo} title={project._id} className="embed-responsive-item"></iframe>
                </div>
            </>
            }
        </div>
    )
}

export default ProjectDemo;